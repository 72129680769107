import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DraggableItem } from '../../../../../components/DragDrop/types'

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        color: 'black',
        padding: theme.spacing(1),
        borderRadius: 4,
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        position: 'absolute',
    },
    previewItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        fontSize: '0.9rem',
    },
    countBadge: {
        backgroundColor: '#ff0000',
        color: 'white',
        width: 20,
        height: 20,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.8rem',
        boxShadow: '0 0 0 2px white',
    },
}))

interface DragPreviewProps {
    items: Array<DraggableItem>
}

export const DragPreview: React.FC<DragPreviewProps> = ({ items }) => {
    const classes = useStyles()

    return (
        <div className={classes.previewContainer}>
            <div className={classes.countBadge}>{items.length}</div>
            {items.map((item) => (
                <div key={item.id} className={classes.previewItem}>
                    {item.dragType === 'unit' ? '📄' : '📁'} {item.name}
                </div>
            ))}
        </div>
    )
}
