import React from 'react'
import { Routes } from '../config/routes'

import type { PublicRouteConfig } from '../types'
import { Login } from '../../main/containers/auth/Login'

import { CheckEmailConfirmation } from '../../main/containers/auth/ForgotPassword/CheckEmailConfirmation'
import { RequestResetForm } from '../../main/containers/auth/ForgotPassword/RequestResetForm'
import { ResetPasswordForm } from '../../main/containers/auth/ForgotPassword/ResetPasswordForm'
import { NVLRoot } from '../../containers/NationalVendorList/NVLRoot'
import { RegisterRouter } from '../../main/containers/auth/Register'
import { PublicLayout } from '../layouts/PublicLayout'

export const publicRoutes: PublicRouteConfig[] = [
    {
        path: Routes.public.login,
        component: Login,
        layout: PublicLayout,
    },
    {
        path: Routes.public.requestPasswordReset,
        component: RequestResetForm,
        layout: PublicLayout,
    },
    {
        path: Routes.public.resetPassword,
        component: ResetPasswordForm,
        layout: PublicLayout,
    },
    {
        path: Routes.public.checkEmailConfirmation,
        component: CheckEmailConfirmation,
        layout: PublicLayout,
    },
    {
        path: Routes.public.register,
        component: RegisterRouter,
        layout: PublicLayout,
    },
]
