import React from 'react'
import { navigationConfig } from '../../config/navigationItems'

import NavItem from '../NavItem'
import { useTheme } from '@material-ui/core/styles'

export const ManageItems = () => {
    const theme = useTheme()

    return (
        <>
            {navigationConfig.manage.mainItems.map((item) => (
                <NavItem key={item.name} item={item} />
            ))}
            <div
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    borderTop: `1px solid ${theme.palette.grey[700]}`,
                }}
            />
            {navigationConfig.manage.footerItems.map((item) => (
                <NavItem key={item.name} item={item} />
            ))}
        </>
    )
}
