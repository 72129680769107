import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { NVLVendor } from '.'
import { VendorListRow } from './VendorListRow'
import { ApartmentVendorChannel } from '../../../containers/NationalPropertyList'
import { MessagingActionTypes } from '../../../store'
import { Container } from '../../../components'
import { Pagination } from '@material-ui/lab'

interface Props {
    vendors: NVLVendor[]
    apartmentVendorChannels: ApartmentVendorChannel[]
    openProfile: () => void
    setSelectedVendor: (vendor: NVLVendor) => void
    setApartmentVendorChannels: (channels: ApartmentVendorChannel[]) => void
    setChannel: (channelID: number) => MessagingActionTypes
    setMessageDrawerOpen: (open: boolean) => void
    setOpenShareModelDialog: (open: boolean) => void
    selectedVendor?: NVLVendor
}
const VendorList = (props: Props) => {
    const {
        vendors,
        openProfile,
        setSelectedVendor,
        apartmentVendorChannels,
        setApartmentVendorChannels,
        setChannel,
        setMessageDrawerOpen,
        setOpenShareModelDialog,
        selectedVendor,
    } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const theme = useTheme()

    return (
        <Container
            style={{
                height: 'calc(100vh - 420px)',
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    overflow: 'auto',
                    paddingRight: theme.spacing(2),
                    flexDirection: 'column',
                }}
            >
                {vendors
                    .map((nvlVendor) => {
                        return (
                            <VendorListRow
                                vendor={nvlVendor}
                                key={`NVL_VENDOR_ROW_${nvlVendor.id}`}
                                apartmentVendorChannels={
                                    apartmentVendorChannels
                                }
                                setApartmentVendorChannels={
                                    setApartmentVendorChannels
                                }
                                setMessageDrawerOpen={setMessageDrawerOpen}
                                setChannel={setChannel}
                                setSelectedVendor={setSelectedVendor}
                                setOpenShareModelDialog={
                                    setOpenShareModelDialog
                                }
                                openProfile={openProfile}
                                isSelected={nvlVendor.id === selectedVendor?.id}
                            />
                        )
                    })
                    .slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                    )}
            </Container>

            <Container style={{ alignItems: 'center' }}>
                <span
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.lightGrey.main,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '13px',
                        paddingTop: theme.spacing(1),
                    }}
                >
                    {vendors.length ?? 0} Rows
                </span>
                <Pagination
                    count={Math.ceil(vendors.length / rowsPerPage)}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                    variant="outlined"
                    shape="rounded"
                    style={{ margin: theme.spacing(1, 0, 0, 0) }}
                />
            </Container>
        </Container>
    )
}

export default VendorList
