import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import {
    WORKORDER_TYPE_ACTION_ITEM,
    WORKORDER_TYPE_SERVICE_ORDER,
    WORKORDER_TYPE_SERVICE_REQUEST,
    workorderContext,
} from '../../contexts'
import {
    DateFilters,
    ModelMap,
    Service,
    WorkorderStatusFilters,
    WorkorderType,
    convertMapToList,
    dateFilterOptions,
    filterOptions,
    getDateRange,
} from '../../models'
import { useMultiSelectStyles } from '../../styles'
import { ServiceFilter } from '../../components/ServiceFilter'
import SearchIcon from '@material-ui/icons/Search'

export const EZWorkWorkorderFilters = () => {
    const {
        workorderList,
        workorderFilterState,
        setWorkorderFilterState,
        getWorkorderList,
    } = workorderContext()

    const theme = useTheme()

    const classes = useMultiSelectStyles()

    const [dateValue, setDateValue] = useState<DateFilters>(
        dateFilterOptions[0],
    )
    const [statusFilter, setStatusFilter] = useState<WorkorderStatusFilters>(
        'ALL',
    )

    const actionItemLength = workorderList?.filter(
        (workorder) => workorder.type === WorkorderType.ACTION_ITEM,
    ).length
    const serviceOrderLength = workorderList?.filter(
        (workorder) => workorder.type === WorkorderType.SERVICE_ORDER,
    ).length
    const serviceRequestLength = workorderList?.filter(
        (workorder) => workorder.type === WorkorderType.SERVICE_REQUEST,
    ).length

    const workorderServicesMap: ModelMap<Service> = {}
    const workorderApartmentMap: ModelMap<{
        id: number
        name: string
    }> = {}

    workorderList?.forEach((workorder) => {
        if (
            workorder.service_id &&
            workorderServicesMap[workorder.service_id] === undefined
        ) {
            workorderServicesMap[workorder.service_id] = {
                id: workorder.service_id,
                name: workorder.service_name ?? '',
                color: workorder.service_color ?? theme.palette.noService.main,
                order: workorder.service_id,
            }
        }
        if (workorder.apartment_id && workorder.apartment_name) {
            workorderApartmentMap[workorder.apartment_id] = {
                id: workorder.apartment_id,
                name: workorder.apartment_name,
            }
        }
    })

    const apartmentList = convertMapToList(workorderApartmentMap)

    return (
        <Container
            direction="column"
            style={{ padding: theme.spacing(2), height: '205px' }}
        >
            <Container flexWrap="wrap">
                <FormControl className={classes.formControl} variant="outlined">
                    <Select
                        labelId="DATE_FILTER_LABEL"
                        value={dateValue}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setDateValue(event.target.value as DateFilters)
                            const dateRange = getDateRange(
                                event.target.value as DateFilters,
                            )
                            getWorkorderList({
                                params: {
                                    upper_bound_date: dateRange.lte_date,
                                    lower_bound_date: dateRange.gte_date,
                                    wo_status: statusFilter,
                                    all_jobsites: true,
                                },
                            })
                        }}
                        style={{
                            width: '200px',
                            height: '40px',
                            marginRight: theme.spacing(2),
                        }}
                    >
                        {dateFilterOptions.map((option) => {
                            return (
                                <MenuItem
                                    key={`DATE_FILTER_${option}`}
                                    value={option}
                                >
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="STATUS_FILTER_LABEL">Status</InputLabel>
                    <Select
                        labelId="STATUS_FILTER_LABEL"
                        id="status_filter_label"
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setStatusFilter(
                                event.target.value as WorkorderStatusFilters,
                            )
                            const dateRange = getDateRange(dateValue)
                            getWorkorderList({
                                params: {
                                    upper_bound_date: dateRange.lte_date,
                                    lower_bound_date: dateRange.gte_date,
                                    wo_status: event.target
                                        .value as WorkorderStatusFilters,
                                    all_jobsites: true,
                                },
                            })
                        }}
                        value={statusFilter}
                        label="Status"
                        style={{
                            width: '200px',
                            height: '40px',
                        }}
                    >
                        {filterOptions.map((option) => {
                            return (
                                <MenuItem
                                    key={`DATE_FILTER_${option.be}`}
                                    value={option.be}
                                >
                                    {option.d}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Container>
            <Container
                style={{
                    margin: theme.spacing(1, 0),
                    alignItems: 'center',
                }}
            >
                <TextField
                    variant="outlined"
                    label="Unit Search"
                    value={workorderFilterState.unitSearch}
                    onChange={(e) => {
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            unitSearch: e.target.value,
                        })
                    }}
                    size="small"
                    InputProps={{
                        endAdornment: <SearchIcon />,
                    }}
                    style={{
                        width: '200px',
                        height: '40px',
                        marginRight: theme.spacing(2),
                    }}
                />
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel id="APARTMENT_FILTER_LABEL">
                        Apartment
                    </InputLabel>
                    <Select
                        labelId="APARTMENT_FILTER_LABEL"
                        value={workorderFilterState.apartmentId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setWorkorderFilterState({
                                ...workorderFilterState,
                                apartmentId: event.target.value as number,
                            })
                        }}
                        style={{
                            width: '200px',
                            height: '40px',
                        }}
                        label="Apartment"
                    >
                        <MenuItem key={`APARTMENT_FILTER_${-1}`} value={-1}>
                            All
                        </MenuItem>
                        {apartmentList.map((apartment) => {
                            return (
                                <MenuItem
                                    key={`APARTMENT_FILTER_${apartment.id}`}
                                    value={apartment.id}
                                >
                                    {apartment.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Container>
            <Container>
                <Button
                    style={{
                        border: `1px  solid ${theme.palette.lightGrey.main}`,
                        borderRadius: '20px',
                        textTransform: 'none',
                        backgroundColor:
                            workorderFilterState.workorderTypeFilter ===
                            WorkorderType.SERVICE_ORDER
                                ? theme.palette.primary.dark
                                : '',
                        color:
                            workorderFilterState.workorderTypeFilter ===
                            WorkorderType.SERVICE_ORDER
                                ? 'white'
                                : '',
                    }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            workorderType: WORKORDER_TYPE_SERVICE_ORDER,
                        })
                    }}
                >
                    {`Service Orders (${serviceOrderLength ?? 0})`}
                </Button>
                <Button
                    style={{
                        margin: theme.spacing(0, 2, 0, 2),
                        border: `1px  solid ${theme.palette.lightGrey.main}`,
                        borderRadius: '20px',
                        textTransform: 'none',
                        backgroundColor:
                            workorderFilterState.workorderType ===
                            WORKORDER_TYPE_ACTION_ITEM
                                ? theme.palette.primary.dark
                                : '',
                        color:
                            workorderFilterState.workorderType ===
                            WORKORDER_TYPE_ACTION_ITEM
                                ? 'white'
                                : '',
                    }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            workorderType: WORKORDER_TYPE_ACTION_ITEM,
                        })
                    }}
                >
                    {`Action Items (${actionItemLength ?? 0})`}
                </Button>
                <Button
                    style={{
                        border: `1px  solid ${theme.palette.lightGrey.main}`,
                        borderRadius: '20px',
                        textTransform: 'none',
                        backgroundColor:
                            workorderFilterState.workorderType ===
                            WORKORDER_TYPE_SERVICE_REQUEST
                                ? theme.palette.primary.dark
                                : '',
                        color:
                            workorderFilterState.workorderType ===
                            WORKORDER_TYPE_SERVICE_REQUEST
                                ? 'white'
                                : '',
                    }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            workorderType: WORKORDER_TYPE_SERVICE_REQUEST,
                        })
                    }}
                >
                    {`Service Requests (${serviceRequestLength ?? 0})`}
                </Button>
            </Container>
            <Container style={{ marginTop: theme.spacing(1) }}>
                <ServiceFilter
                    serviceList={convertMapToList(workorderServicesMap)}
                    selectedServiceIds={workorderFilterState.serviceIds}
                    onSelectService={(serviceId) => {
                        if (
                            workorderFilterState.serviceIds.find(
                                (servId) => servId === serviceId,
                            )
                        ) {
                            setWorkorderFilterState({
                                ...workorderFilterState,
                                serviceIds: workorderFilterState.serviceIds.filter(
                                    (servId) => servId !== serviceId,
                                ),
                            })
                        } else {
                            const newServiceIdList = [
                                ...workorderFilterState.serviceIds,
                                serviceId,
                            ]
                            setWorkorderFilterState({
                                ...workorderFilterState,
                                serviceIds: newServiceIdList,
                            })
                        }
                    }}
                />
            </Container>
        </Container>
    )
}
