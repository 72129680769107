import { Routes } from '../config/routes'

import type { RouteConfig } from '../types'
import { OrganizationGuard } from '../guards'

import { LeaseHome, OrganizationHome } from '../../containers'

import { OrganizationJobBoardRoot } from '../../containers/JobBoard/OrganizationJobBoard/OrganizationJobBoardRoot'
import { OrganizationProjectRoot } from '../../containers/Organization/pages/OrganizationProjectPage/OrganizationProjectRoot'
import { OrganizationLayout } from '../layouts/OrganizationLayout/OrganizationLayout'
import { ManageLayout } from '../layouts/ManageLayout/ManageLayout'
import { ManageGuard } from '../guards/ManageGuard'
import { ManageHome } from '../../containers/Manage/pages/ManageHome'
import { LeaseAuditRoot } from '../../containers/LeaseAudit/LeaseAuditRoot'

export const manageRoutes: RouteConfig[] = [
    // {
    //     path: Routes.manage.root,
    //     component: ManageHome,
    //     layout: ManageLayout,
    //     guard: ManageGuard,
    // },
    {
        path: Routes.manage.lease.root,
        component: LeaseHome,
        layout: ManageLayout,
        guard: ManageGuard,
    },
    {
        path: Routes.manage.leaseAudit,
        component: LeaseAuditRoot,
        layout: ManageLayout,
        guard: ManageGuard,
    },
]