import React from 'react'
import {
    Card,
    CardContent,
    Typography,
    Theme,
    makeStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { useHistory } from 'react-router-dom'
import { ProjectResponse } from '../../../types'


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderLeft: '4px solid',
        borderBottom: '4px solid',
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    titles: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        justifyContent: 'flex-start',
    },
    icons: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
        justifyContent: 'flex-end',
    },
    projectName: {
        fontWeight: 700,
        fontSize: '1.1rem',
    },
    projectId: {
        color: theme.palette.text.secondary,
    },
    budget: {
        fontWeight: 700,
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        color: theme.palette.text.secondary,
        fontSize: '0.9rem',
    },
}))

interface Props {
    theme: Theme
    projectResponse: ProjectResponse
}

export const ProjectCard: React.FC<Props> = ({ theme, projectResponse }) => {
    const classes = useStyles(theme)
    const history = useHistory()

    if (!projectResponse) return null

    const formatBudget = (budget: string) => {
        if (!budget) {
            return '$0.00' // or handle this case as needed
        }
        const cleanBudget = budget.replace(/[^\d.-]/g, '')
        const numberValue = Number(cleanBudget)
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(numberValue)
    }

    const isValidColor = (color: string) => {
        const s = new Option().style
        s.color = color
        return s.color !== ''
    }

    const borderColor =
        projectResponse.projectColor && isValidColor(projectResponse.projectColor)
            ? projectResponse.projectColor
            : theme.palette.primary.main

    const navigateToScheduleDetail = (scheduleId: number) => {
        history.push(`/schedule/${scheduleId}`)
    }

    return (
        <Card className={classes.root} style={{ borderColor }}>
            <CardContent className={classes.content}>
                <div className={classes.header}>
                    <div className={classes.titles}>
                        <Typography className={classes.projectName}>
                            {projectResponse.projectName}
                        </Typography>
                        <Typography className={classes.projectId}>
                            - {projectResponse.projectId}
                        </Typography>
                    </div>

                    <div className={classes.icons}>
                        <Tooltip title={`Go to ${projectResponse.projectName}`}>
                            <IconButton
                                onClick={() => {
                                    if (projectResponse?.id) {
                                        navigateToScheduleDetail(
                                            projectResponse.id,
                                        )
                                    }
                                }}
                                size="small"
                            >
                                <ExitToAppIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.infoRow}>
                    <Typography className={classes.budget}>
                        {formatBudget(projectResponse.projectBudget)}
                    </Typography>
                    <Typography>
                        {projectResponse.projectStartDate} - {projectResponse.projectEndDate}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}
