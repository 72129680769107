import {
    Button,
    Chip,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import VendorList from './VendorList'

import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg'
import LoadingGIF from '../../../assets/Loading_Animation.gif'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
    Container,
    MessageDrawer,
    SideDrawerContainer,
} from '../../../components'
import FilterDrawer from './FilterDrawer'

import {
    useAppDispatch,
    useBlanket,
    useMessageDrawer,
    useService,
    useUser,
} from '../../../hooks'
import { axiosInstance, Routes, statesList } from '../../../helpers'
import ShareVendorDialogBox from '../../components/dialog-box/DialogBox'
import { ShareVendor } from './ShareVendor'
import { getServiceList } from '../../../store'
import { Address, ListChannel, PlanStatus, Service } from '../../../models'
import SearchIcon from '@material-ui/icons/Search'
import { VendorDetails, VendorWorkorderDetails } from './VendorDetails'
import { ArrowBack, BarChart as BarChartIcon } from '@material-ui/icons'
import {
    ApartmentVendor,
    ApartmentVendorChannel,
} from '../../../containers/NationalPropertyList'
import { HOME_PAGE } from '../../../containers/NationalVendorList/NVLHomePage'
import { useMultiSelectStyles, useStyles } from '../../../styles'
import { MarketAnalysisTool } from '../../../containers/MarketAnalysisTool/MarketAnalysisTool'
import { useHistory } from 'react-router-dom'
import { rfpApi } from '../../../contexts'

export interface NVLVendor {
    address: string
    city: string
    complete_count: number
    email: string
    go_back_count: number
    id: number
    name: string
    phone: string
    state: string
    user_count: number
    workorder_count: number
    zip_code: string
    is_trained: boolean
    will_travel: boolean
    plan: string | null
    url: string
    sponsor_image: string
    tagline: string
    featured_image: string
    description: string
    profile_picture: string
    primary_address: Address | null
    is_skillcat: boolean
}

export interface NVLData {
    badges: JSX.Element
    organization: string
    services: JSX.Element
    complete: number
    goBack: number
    nvlVendor: NVLVendor
    action: JSX.Element
    users: number
    featuredVendor: boolean
    messageVendor: JSX.Element
}

const customStyles = makeStyles(() =>
    createStyles({
        searchInput: {
            '& .MuiOutlinedInput-input': {
                padding: '9px 0 9px',
            },
        },
        filterChips: {
            background: '#F4F4F4',
            border: '1px solid #008C85',
            borderRadius: '29px',
            color: '#008C85',
            fontSize: '0.75rem',
            lineHeight: '14px',
            height: '24px',
            fontWeight: 600,
            marginRight: '5px',
            marginTop: '5px',
        },
        getQuoteBtn: {
            background: '#008C85',
            color: '#FFF',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
        },
        spanReceived: {
            background: '#FFE29B',
            borderRadius: '5px',
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#DFA804',
            width: '98px',
            height: '33px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        spanSent: {
            background: '#ACE5CE',
            borderRadius: '5px',
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#00B46A',
            width: '98px',
            height: '33px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        actionMenu: {
            '& .MuiList-root': {
                width: 100,
            },
            '& .MuiMenuItem-root': {
                fontSize: '0.75rem',
                fontWeight: 400,
                color: '#000',
            },
        },
        serviceMenu: {
            '&.MuiList-root': {
                width: 150,
                maxHeight: '200px !important',
                overflow: 'auto',
            },
            '& .MuiListItemText-primary ': {
                fontSize: '0.75rem',
                fontWeight: 400,
                color: '#000',
            },
        },
        paper: {
            background: '#FFFFFF',
            border: '1px solid #000000',
            borderRadius: '5px',
            boxShadow:
                '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }),
)

interface Props {
    location?: string
    radiusFilter?: number
    selectedService?: Service
    selectedLocations: string[]
    setTab: (tab: number) => void
    setSelectedLocations: (locations: string[]) => void
    setCreateRFP: (create: boolean) => void
}

const NationalVendorList = (props: Props) => {
    const {
        location,
        radiusFilter,
        selectedService,
        selectedLocations,
        setTab,
        setSelectedLocations,
        setCreateRFP,
    } = props

    const rfpState = rfpApi()

    const classes = useMultiSelectStyles()

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
    const [vendorProfileDrawerOpen, setVendorProfileDrawerOpen] = useState(
        false,
    )

    const history = useHistory()

    const dispatch = useAppDispatch()

    const [selectedVendor, setSelectedVendor] = useState<
        NVLVendor | undefined
    >()

    const [vendors, setVendors] = useState<NVLVendor[]>([])
    const theme = useTheme()

    const { workspaceUser } = useUser()

    const [searchText, setSearchText] = useState('')

    const [messageDrawerOpen, setMessageDrawerOpen] = useState(false)

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const { setChannel } = useMessageDrawer()

    const toggleFilterDrawer = () => {
        setFilterDrawerOpen(!filterDrawerOpen)
    }

    const toggleVendorProfileDrawerOpen = () => {
        setVendorProfileDrawerOpen(!vendorProfileDrawerOpen)
    }

    console.log('services are....', serviceList)

    const [openShareModelDialog, setOpenShareModelDialog] = useState(false)

    const [apartmentVendorChannels, setApartmentVendorChannels] = useState<
        ApartmentVendorChannel[]
    >([])
    const [loadingState, setLoadingState] = useState(true)

    const contentRef = useRef<HTMLDivElement>(null)
    const [openMarketAnalysis, setOpenMarketAnalysis] = useState(false)
    const analyticRect = useBlanket(openMarketAnalysis, contentRef)

    const handleToggleShareVendorDialog = () => {
        setOpenShareModelDialog(!openShareModelDialog)
    }

    const customClasses = customStyles()

    useEffect(() => {
        dispatch(getServiceList({}))

        if (workspaceUser) {
            const channelListPromise = axiosInstance.get('messaging/channel/', {
                params: {
                    channel_type: 'APARTMENT_VENDOR',
                },
            })
            const apartmentVendorPromise = axiosInstance.get(
                'company/apartment_vendors/',
            )

            Promise.all([channelListPromise, apartmentVendorPromise])
                .then((values) => {
                    const tempApartmentVendorChannels: ApartmentVendorChannel[] = []
                    values[0].data.map((channel: ListChannel) => {
                        tempApartmentVendorChannels.push({
                            channel: channel,
                        })
                    })
                    values[1].data.map((apartmentVendor: ApartmentVendor) => {
                        tempApartmentVendorChannels.map((aptVendorChannel) => {
                            if (
                                aptVendorChannel.channel.id ===
                                apartmentVendor.channel_id
                            ) {
                                aptVendorChannel.apartment =
                                    apartmentVendor.apartment
                                aptVendorChannel.vendor = apartmentVendor.vendor
                            }
                        })
                    })
                    setApartmentVendorChannels(tempApartmentVendorChannels)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [])

    /******************************* FILTER DATA [START] ************************************** */
    const [filterData, setFilterData]: any = useState({
        searchText: '',
        services: selectedService ? [selectedService] : [],
        radius: radiusFilter ? radiusFilter : 0,
        willingToTravel: 'all',
        trained: 'all',
        complete_count: {
            type: 'greater',
            value: '',
        },
        go_back_count: {
            type: 'greater',
            value: '',
        },
        location: location ? location : '',
    })

    const handleRadiusChange = (
        event: React.ChangeEvent<{}>,
        newValue: number | number[],
    ) => {
        console.log('event is.....', newValue)
        setFilterData({
            ...filterData,
            radius: newValue,
        })
    }

    const handleServiceChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        serviceName: string,
    ) => {
        let services: any = filterData.services

        if (event.target.checked) {
            services.push({
                id: parseInt(event.target.value),
                name: serviceName,
            })
        } else {
            services = services.filter(
                (value: any) => value.id !== parseInt(event.target.value),
            )
        }

        setFilterData({
            ...filterData,
            services: [...services],
        })
    }

    const handleSearchFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setFilterData({
            ...filterData,
            searchText: event.target.value,
        })
    }

    const handleCompleteCountChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: string,
    ) => {
        setFilterData({
            ...filterData,
            complete_count: { type: type, value: event.target.value },
        })
    }
    const handleGoBackCountChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: string,
    ) => {
        setFilterData({
            ...filterData,
            go_back_count: { type: type, value: event.target.value },
        })
    }

    const handleTrainedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setFilterData({
            ...filterData,
            trained: event.target.value,
        })
    }

    const handleWilToTravelChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setFilterData({
            ...filterData,
            willingToTravel: event.target.value,
        })
    }

    const getNationalVendorList = () => {
        let go_back_count = ''
        let complete_count = ''
        if (filterData.go_back_count.value) {
            if (filterData.go_back_count.type === 'greater') {
                go_back_count = filterData.go_back_count.value
            } else {
                go_back_count = `-${filterData.go_back_count.value}`
            }
        }

        if (filterData.complete_count.value) {
            if (filterData.complete_count.type === 'greater') {
                complete_count = filterData.complete_count.value
            } else {
                complete_count = `-${filterData.complete_count.value}`
            }
        }

        let will_travel = ''
        if (filterData.willingToTravel === 'yes') {
            will_travel = 'True'
        } else if (filterData.willingToTravel === 'no') {
            will_travel = 'False'
        }

        let is_trained = ''
        if (filterData.trained === 'yes') {
            is_trained = 'True'
        } else if (filterData.trained === 'no') {
            is_trained = 'False'
        }

        let services = ''
        if (filterData.services.length > 0) {
            filterData.services.forEach((el: any, index: number) => {
                if (index !== filterData.services.length - 1) {
                    services = services + `${el.id}, `
                } else {
                    services = services + `${el.id}`
                }
            })
        }

        let is_skillcat = ''
        if (filterData.is_skillcat === 'yes') {
            is_skillcat = 'True'
        } else if (filterData.is_skillcat === 'no') {
            is_skillcat = 'False'
        }

        const radius = filterData.radius > 0 ? filterData.radius : ''

        const params: {
            is_trained?: string
            will_travel?: string
            go_back_count?: string
            complete_count?: string
            radius?: string
            services?: string
            is_skillcat?: string
        } = {}
        if (is_trained !== '') {
            params.is_trained = is_trained
        }
        if (will_travel !== '') {
            params.will_travel = will_travel
        }
        if (go_back_count !== '') {
            params.go_back_count = go_back_count
        }
        if (complete_count !== '') {
            params.complete_count = complete_count
        }
        if (radius !== '') {
            params.radius = radius
        }
        if (services.length !== 0) {
            params.services = JSON.stringify(services)
        }
        if (is_skillcat.length !== 0) {
            params.is_skillcat = is_skillcat
        }

        axiosInstance
            .get(`company/national_vendor_list/`, { params: params })
            .then((res) => {
                const tempVendors: NVLVendor[] = res.data
                if (res && tempVendors) {
                    tempVendors.sort((vendorOne, vendorTwo) => {
                        if (
                            vendorOne.plan === PlanStatus.PREMIUM &&
                            vendorTwo.plan !== PlanStatus.PREMIUM
                        ) {
                            return -2
                        } else if (
                            vendorOne.plan !== PlanStatus.PREMIUM &&
                            vendorTwo.plan === PlanStatus.PREMIUM
                        ) {
                            return 2
                        } else {
                            return 0
                        }
                    })
                    const tempSponsoredVendors: NVLVendor[] = tempVendors.filter(
                        (vendor) => vendor.plan === PlanStatus.PREMIUM,
                    )
                    setVendors(tempVendors)
                    setSelectedVendor(
                        tempSponsoredVendors[
                            Math.floor(
                                Math.random() * tempSponsoredVendors.length,
                            )
                        ],
                    )
                    setVendorProfileDrawerOpen(true)
                }
                console.log('response...', res)
            })
            .finally(() => {
                setLoadingState(false)
            })
    }
    useEffect(() => {
        getNationalVendorList()
    }, [filterData])

    const resetFilter = () => {
        setFilterData({
            searchText: '',
            services: [],
            radius: 0,
            willingToTravel: 'all',
            trained: 'all',
            complete_count: {
                type: 'greater',
                value: 0,
            },
            go_back_count: {
                type: 'greater',
                value: 0,
            },
        })
    }
    console.log('filter services...', filterData.services)
    /******************************************** FILTER DATA [END] *********************************** */

    let filteredVendors = vendors
    if (selectedLocations.length !== 0) {
        filteredVendors = filteredVendors.filter((vendor) =>
            selectedLocations.includes(vendor.state),
        )
    }

    if (loadingState) {
        return (
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <img src={LoadingGIF} />
            </Container>
        )
    }

    return (
        <>
            <SideDrawerContainer open={filterDrawerOpen} width={330}>
                <Container
                    style={{
                        height: 'calc(100vh - 104px)',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Container direction="column">
                        <Container>
                            <IconButton
                                style={{
                                    cursor: 'pointer',
                                    color: theme.palette.darkGreen.main,
                                    fontSize: 13,
                                    justifyContent: 'center',
                                    alignSelf: 'flex-start',
                                }}
                                onClick={() => setTab(HOME_PAGE)}
                                size="small"
                            >
                                <ArrowBack
                                    style={{ marginRight: theme.spacing(0.5) }}
                                />{' '}
                                Back to Homepage
                            </IconButton>
                            <Container flex={1} />
                            <Tooltip
                                title="Market Rate Analysis"
                                style={{ marginRight: theme.spacing(2) }}
                            >
                                <IconButton
                                    onClick={() =>
                                        setOpenMarketAnalysis(
                                            !openMarketAnalysis,
                                        )
                                    }
                                    color={
                                        openMarketAnalysis
                                            ? 'primary'
                                            : 'default'
                                    }
                                    size="small"
                                >
                                    <BarChartIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                            {workspaceUser && (
                                <Button
                                    variant="contained"
                                    style={{
                                        margin: theme.spacing(0, 4, 1, 1),
                                        backgroundColor: '#008C85',
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        history.push(Routes.rfpDashboard)
                                    }
                                >
                                    RFP Dashboard
                                </Button>
                            )}
                        </Container>

                        <Divider
                            style={{
                                width: '100%',
                            }}
                        />
                    </Container>
                    <Container
                        ref={contentRef}
                        style={{
                            flexDirection: 'row',
                            width: '100%',
                            overflowX: 'auto',
                        }}
                    >
                        <Container
                            style={{
                                overflow: 'auto',
                                height: 'calc(100% - 20px)',
                                marginTop: theme.spacing(2),
                                flexDirection: 'column',
                                width: 'calc(100% - 300px)',
                                flex: 1,
                                overflowX: 'auto',
                            }}
                        >
                            <Container>
                                <Container direction="column">
                                    <h2
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: 700,
                                            fontSize: '40px',
                                            lineHeight: '37px',
                                            marginBottom: '15px',
                                            marginTop: '5px',
                                            color: '#000000',
                                        }}
                                    >
                                        National Vendors List
                                    </h2>
                                    <p>
                                        View all of your vendors across the
                                        country as well as the services they
                                        provide
                                    </p>
                                </Container>
                            </Container>
                            <Container style={{ marginTop: '15px' }}>
                                <Container>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{
                                            margin: theme.spacing(2, 2, 2, 0),
                                        }}
                                    />
                                </Container>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="property-location-label">
                                        Location
                                    </InputLabel>
                                    <Select
                                        labelId="property-location-label"
                                        id="mutiple-chip-property-location"
                                        fullWidth
                                        style={{
                                            minWidth: '100px',
                                            maxWidth: '250px',
                                        }}
                                        multiple
                                        value={selectedLocations}
                                        MenuProps={{
                                            getContentAnchorEl: () => {
                                                return (null as unknown) as Element
                                            },
                                        }}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            const selectedStates = event.target
                                                .value as string[]
                                            if (selectedStates.includes('')) {
                                                setSelectedLocations([])
                                            } else {
                                                setSelectedLocations(
                                                    selectedStates,
                                                )
                                            }
                                        }}
                                        input={
                                            <Input id="select-multiple-chip-property-location" />
                                        }
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {(selected as string[]).map(
                                                    (value) => {
                                                        const vnd = statesList.find(
                                                            (s) => s === value,
                                                        )
                                                        return (
                                                            <Chip
                                                                key={`LOCATION_CHIP_${
                                                                    vnd
                                                                        ? vnd
                                                                        : -1
                                                                }`}
                                                                label={
                                                                    vnd
                                                                        ? vnd
                                                                        : 'unknown'
                                                                }
                                                                className={
                                                                    classes.chip
                                                                }
                                                            />
                                                        )
                                                    },
                                                )}
                                            </div>
                                        )}
                                    >
                                        <MenuItem
                                            key={`LOCATION_MENU_ITEM_CLEAR`}
                                            value={''}
                                        >
                                            Clear Selection
                                        </MenuItem>
                                        {statesList.map((state) => {
                                            return (
                                                <MenuItem
                                                    key={`SLOCATION_MENU_ITEM_${state}`}
                                                    value={state}
                                                >
                                                    {state}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <Container style={{ textAlign: 'right' }}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<FilterIcon />}
                                        style={{
                                            color: '#8A8A8A',
                                            fontSize: '0.875rem',
                                            border: 'none',
                                            textTransform: 'inherit',
                                        }}
                                        onClick={() => toggleFilterDrawer()}
                                    >
                                        Filter
                                    </Button>
                                </Container>
                                <Container flex={1} />
                                <Container>
                                    {workspaceUser && (
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#008C85',
                                                color: 'white',
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                                margin: theme.spacing(
                                                    2,
                                                    0,
                                                    2,
                                                    1,
                                                ),
                                            }}
                                            onClick={() => {
                                                rfpState.setSelectedRFP(null)
                                                setCreateRFP(true)
                                            }}
                                        >
                                            Create RFP
                                        </Button>
                                    )}
                                </Container>
                            </Container>
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: '100%',
                                    padding: '12px 0',
                                }}
                            >
                                {filterData.services.length > 0 ? (
                                    filterData.services.map(
                                        (value: any, index: number) => (
                                            <Chip
                                                key={`serviceChip-${index}`}
                                                label={value.name}
                                                className={
                                                    customClasses.filterChips
                                                }
                                                onDelete={() => {
                                                    let services: any =
                                                        filterData.services

                                                    services = services.filter(
                                                        (v: any) =>
                                                            v.id !==
                                                            parseInt(value.id),
                                                    )

                                                    setFilterData({
                                                        ...filterData,
                                                        services: [...services],
                                                    })
                                                }}
                                            />
                                        ),
                                    )
                                ) : (
                                    <></>
                                )}

                                {filterData.radius > 0 ? (
                                    <Chip
                                        label={`Radius (${filterData.radius})`}
                                        className={customClasses.filterChips}
                                        onDelete={() => {
                                            setFilterData({
                                                ...filterData,
                                                radius: 0,
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {filterData.complete_count.value > 0 ? (
                                    <Chip
                                        label={`Complete (${
                                            filterData.complete_count.type ===
                                            'greater'
                                                ? `> ${filterData.complete_count.value}`
                                                : `< ${filterData.complete_count.value}`
                                        })`}
                                        className={customClasses.filterChips}
                                        onDelete={() => {
                                            setFilterData({
                                                ...filterData,
                                                complete_count: {
                                                    type: 'greater',
                                                    value: '0',
                                                },
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {filterData.go_back_count.value > 0 ? (
                                    <Chip
                                        label={`Go Back (${
                                            filterData.go_back_count.type ===
                                            'greater'
                                                ? `> ${filterData.go_back_count.value}`
                                                : `< ${filterData.go_back_count.value}`
                                        })`}
                                        className={customClasses.filterChips}
                                        onDelete={() => {
                                            setFilterData({
                                                ...filterData,
                                                go_back_count: {
                                                    type: 'greater',
                                                    value: '0',
                                                },
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {filterData.willingToTravel === 'yes' ? (
                                    <Chip
                                        label="Willing To Travel"
                                        className={customClasses.filterChips}
                                        onDelete={() => {
                                            setFilterData({
                                                ...filterData,
                                                willingToTravel: 'no',
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                {filterData.trained === 'yes' ? (
                                    <Chip
                                        label="Trained"
                                        className={customClasses.filterChips}
                                        onDelete={() => {
                                            setFilterData({
                                                ...filterData,
                                                trained: 'no',
                                            })
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                            <Container flex={1}>
                                <VendorList
                                    openProfile={() =>
                                        setVendorProfileDrawerOpen(true)
                                    }
                                    setSelectedVendor={setSelectedVendor}
                                    vendors={filteredVendors.filter((vendor) =>
                                        vendor.name
                                            .toLocaleLowerCase()
                                            .includes(
                                                searchText.toLocaleLowerCase(),
                                            ),
                                    )}
                                    apartmentVendorChannels={
                                        apartmentVendorChannels
                                    }
                                    setApartmentVendorChannels={
                                        setApartmentVendorChannels
                                    }
                                    setMessageDrawerOpen={setMessageDrawerOpen}
                                    setChannel={setChannel}
                                    setOpenShareModelDialog={
                                        setOpenShareModelDialog
                                    }
                                    selectedVendor={selectedVendor}
                                />
                            </Container>
                        </Container>
                        <Container
                            style={{
                                minWidth: 300,
                                width: 300,
                            }}
                        >
                            <Divider
                                orientation="vertical"
                                style={{ margin: theme.spacing(0, 2, 0, 2) }}
                            />
                            <Container
                                style={{
                                    minWidth: 250,
                                    width: 250,
                                    flex: 1,
                                    justifyContent: 'center',
                                }}
                            >
                                {selectedVendor && (
                                    <VendorDetails
                                        selectedVendor={selectedVendor}
                                    />
                                )}
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </SideDrawerContainer>

            <FilterDrawer
                open={filterDrawerOpen}
                handleClose={toggleFilterDrawer}
                width={380}
                serviceList={serviceList}
                filterData={filterData}
                onRadiusChange={handleRadiusChange}
                handleServiceChange={handleServiceChange}
                handleSearchFilterChange={handleSearchFilterChange}
                handleTrainedChange={handleTrainedChange}
                handleWilToTravelChange={handleWilToTravelChange}
                resetFilter={resetFilter}
                onCompleteCountChange={handleCompleteCountChange}
                onGoBackCountChange={handleGoBackCountChange}
                setFilterData={setFilterData}
            />

            <ShareVendorDialogBox
                open={openShareModelDialog}
                handleClose={handleToggleShareVendorDialog}
                dialogContent={
                    <ShareVendor handleClose={handleToggleShareVendorDialog} />
                }
                dialogHeader="Share"
                fullWidth={false}
            />

            <MessageDrawer
                open={messageDrawerOpen}
                onClose={() => setMessageDrawerOpen(false)}
                title={`${selectedVendor?.name}`}
            />

            <MarketAnalysisTool
                open={openMarketAnalysis}
                onClose={() => setOpenMarketAnalysis(false)}
                rect={analyticRect}
                serviceList={serviceList}
            />
        </>
    )
}

export default NationalVendorList
