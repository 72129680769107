import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { axiosInstance } from '../helpers/axios'
import { useUser } from './useUser'
import {
    ChatLoadingState,
    ProjectPlanResponse,
    HelpVideo,
    Conversation,
    ChatMessageContent,
} from '../navigation/layouts/Lori/types'
import { useHistory } from 'react-router-dom'
import { VENDOR } from '../helpers/permissions'

export const useLori = () => {
    const [loadingState, setLoadingState] = useState<ChatLoadingState>({
        videoLibrary: false,
        conversationHistory: false,
    })
    const [projectPlan, setProjectPlan] = useState<ProjectPlanResponse | null>(
        null,
    )
    const [projectPlanList, setProjectPlanList] = useState<
        ProjectPlanResponse[]
    >([])
    const [videoLibrary, setVideoLibrary] = useState<HelpVideo[]>([])
    const [categories, setCategories] = useState<string[]>([])
    const [conversationHistory, setConversationHistory] = useState<
        Conversation[]
    >([])
    const [pinnedConversations, setPinnedConversations] = useState<
        Conversation[]
    >([])
    const [
        activeConversation,
        setActiveConversation,
    ] = useState<Conversation | null>(null)
    const [thinking, setThinking] = useState<boolean>(false)
    const [walkThroughPanelOpen, setWalkThroughPanelOpen] = useState<boolean>(
        false,
    )
    const [socket, setSocket] = useState<WebSocket | null>(null)

    const { workspaceUser } = useUser()
    const history = useHistory()
    // const WEBSOCKET_BASE_URL = process.env.REACT_APP_WEBSOCKET_BASE_URL
    const WEBSOCKET_BASE_URL = 'wss://api.ezturn.co/ws/'

    // load video library for lori
    useEffect(() => {
        getVideoLibrary()
    }, [])

    useEffect(() => {
        createWebSocket()
        return () => {
            if (socket) {
                socket.close()
            }
        }
    }, [activeConversation?.id, workspaceUser?.active_workspace.id])

    // get updated data for user for different workspace
    useEffect(() => {
        console.log('workspace user changed')
        // close the socket so that the correct conversation id can be set in the socket.
        if (socket) {
            socket.close()
        }
        setActiveConversation(null)
        getConversationHistory(workspaceUser?.active_workspace.id)
        getProjectPlanList(true)
        setWalkThroughPanelOpen(false)
    }, [workspaceUser?.active_workspace.id])

    useEffect(() => {
        if (activeConversation === null) {
            updateConversationState(true)
        }
    }, [conversationHistory])

    const createConversation = async () => {
        await axiosInstance
            .post('/chat/conversation/', {})
            .then((res) => {
                setActiveConversation(res.data)
                setThinking(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getConversationHistory = async (apartmentId: number | undefined) => {
        if (apartmentId === undefined) return
        setLoadingState({ ...loadingState, conversationHistory: true })
        axiosInstance
            .get('/chat/conversation/', {
                params: {
                    apartment_id: apartmentId,
                },
            })
            .then((res) => {
                console.log('conversation history', res.data)
                if (res.data.length === 0) {
                    createConversation()
                    return
                }
                setConversationHistory(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoadingState({ ...loadingState, conversationHistory: false })
            })
    }

    const updateConversationState = async (setActive: boolean = false) => {
        if (conversationHistory.length === 0) return
        // Set pinned conversations
        const pinned = conversationHistory.filter(
            (conversation: Conversation) => conversation.pinned,
        )
        setPinnedConversations(pinned)

        // set the latest conversation as the active conversation
        if (setActive) {
            const mostRecentConversation = conversationHistory.reduce(
                (latest: Conversation, current: Conversation) => {
                    return new Date(current.created_date) >
                        new Date(latest.created_date)
                        ? current
                        : latest
                },
                conversationHistory[0],
            )
            setActiveConversation(mostRecentConversation)
        }
    }

    const pinConversation = async (conversationId: number | null) => {
        if (conversationId === null) return
        await axiosInstance
            .post(`/chat/conversation/${conversationId}/pin/`)
            .then((res) => {
                // setActiveConversation(res.data)
                setPinnedConversations([...pinnedConversations, res.data])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addVideo = async (url: string, category: string) => {
        await axiosInstance
            .post('/chat/help_video_library/', { url, category })
            .then((res) => {
                toast.success('Video added')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const removeVideo = async (id: number) => {
        await axiosInstance
            .delete(`/chat/help_video_library/${id}/`)
            .then((res) => {
                toast.success('Video removed')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const updateVideo = async (helpVideo: HelpVideo) => {
        await axiosInstance
            .put(`/chat/help_video_library/${helpVideo.id}/`, helpVideo)
            .then((res) => {
                setVideoLibrary((prevLibrary) =>
                    prevLibrary.map((video) =>
                        video.id === helpVideo.id ? helpVideo : video,
                    ),
                )
                toast.success('Video updated')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addVideoView = async (id: number) => {
        await axiosInstance
            .post(`/chat/help_video_library/${id}/add_video_view/`)
            .then((res) => {
                console.log('video view added', res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getVideoLibrary = async () => {
        setLoadingState({ ...loadingState, videoLibrary: true })
        await axiosInstance
            .get('/chat/help_video_library/')
            .then((res) => {
                setVideoLibrary(res.data)
                setLoadingState({ ...loadingState, videoLibrary: false })
            })
            .catch((err) => {
                console.log(err)
                setLoadingState({ ...loadingState, videoLibrary: false })
            })
    }

    const getCategories = async () => {
        await axiosInstance
            .get('/chat/help_video_library/categories/')
            .then((res) => {
                console.log('categories', res.data)
                const formattedCategories = res.data.map((category: string) => {
                    // Split by underscore, capitalize first letter of each word, then join with space
                    return category
                        .split('_')
                        .map(
                            (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(' ')
                })
                setCategories(formattedCategories)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addCategory = async (category: string) => {
        await axiosInstance
            .post('/chat/help_video_library/add_category/', { category })
            .then((res) => {
                setCategories([...categories, category])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getProjectPlanList = async (force_company: boolean = false) => {
        await axiosInstance
            .get('/chat/project_plan/', { params: { force_company } })
            .then((res) => {
                setProjectPlanList(res.data)
                const mostRecentProjectPlan = res.data.reduce(
                    (
                        latest: ProjectPlanResponse,
                        current: ProjectPlanResponse,
                    ) => {
                        return new Date(current.created_date) >
                            new Date(latest.created_date)
                            ? current
                            : latest
                    },
                    res.data[0],
                )
                setProjectPlan(mostRecentProjectPlan)
            })
    }

    const updateTask = async (taskId: number, completed: boolean) => {
        await axiosInstance
            .post('/chat/project_plan/update_task/', {
                task_id: taskId,
                completed,
            })
            .then((res) => {
                console.log('task updated', res.data)
            })
    }

    const createWebSocket = useCallback(() => {
        if (
            !activeConversation?.id ||
            !WEBSOCKET_BASE_URL ||
            !workspaceUser?.active_workspace.id ||
            workspaceUser?.active_workspace.company_type === VENDOR
        ) {
            return
        }

        // Add reconnection attempt counter
        const reconnectAttempt = (socket as any)?.reconnectAttempt || 0
        const maxReconnectDelay = 32000 // 32 seconds max delay
        const baseDelay = 1000 // 1 second initial delay

        const newSocket = new WebSocket(
            WEBSOCKET_BASE_URL +
                'chat/' +
                activeConversation.id +
                '/' +
                workspaceUser?.active_workspace.id +
                '/',
        )

        // Store reconnection attempt counter on socket instance
        ;(newSocket as any).reconnectAttempt = reconnectAttempt

        newSocket.onopen = function () {
            console.log('WebSocket connection opened')
            ;(newSocket as any).reconnectAttempt = 0
        }

        newSocket.onmessage = function (e) {
            const message: ChatMessageContent = JSON.parse(e.data)
            if (message.content_type === 'error') {
                setThinking(false)
                return
            }
            setThinking(false)
            setActiveConversation((prevConversation) => {
                if (!prevConversation) return null
                return {
                    ...prevConversation,
                    chat_messages: [
                        ...prevConversation.chat_messages,
                        {
                            role: message.role,
                            content_type: message.content_type,
                            function_call: message.function_call,
                            message_items: message.message_items,
                            project_response: message.project_response,
                            deadline_response: message.deadline_response,
                            how_to_video_response:
                                message.how_to_video_response,
                        },
                    ],
                }
            })

            if (message.function_call === 'navigate_url') {
                const url = message.properties?.url
                if (url) {
                    setTimeout(() => {
                        history.push(url)
                    }, 0)
                }
            } else if (message.function_call === 'show_project_plan') {
                getProjectPlanList()
                setWalkThroughPanelOpen(true)
            }
        }

        newSocket.onclose = function (e) {
            console.log('WebSocket closed:', e.reason)

            // Calculate delay with exponential backoff
            const delay = Math.min(
                Math.pow(2, (newSocket as any).reconnectAttempt) * baseDelay,
                maxReconnectDelay,
            )

            setTimeout(() => {
                // Increment reconnection attempt counter
                const ns = newSocket as any
                ns.reconnectAttempt++
                console.log(
                    `Attempting to reconnect... Attempt ${ns.reconnectAttempt}`,
                )
                createWebSocket()
            }, delay)
        }

        newSocket.onerror = function (error) {
            console.log('WebSocket error:', error)
        }

        setSocket(newSocket)
    }, [activeConversation?.id, workspaceUser?.active_workspace.id])

    return {
        loadingState,
        projectPlan,
        categories,
        conversationHistory,
        thinking,
        setThinking,
        addVideo,
        removeVideo,
        videoLibrary,
        getVideoLibrary,
        getCategories,
        addCategory,
        updateTask,
        activeConversation,
        setActiveConversation,
        setWalkThroughPanelOpen,
        walkThroughPanelOpen,
        createConversation,
        socket,
        getConversationHistory,
        pinConversation,
        pinnedConversations,
        getProjectPlanList,
        projectPlanList,
        updateVideo,
        addVideoView,
    }
}

export type LoriController = ReturnType<typeof useLori>
