import React, { useEffect, useState } from 'react'

import { CircularProgress, IconButton, Theme } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

import { LeaseAuditController } from '../../../hooks/documentAudit'
import { Container, Draggable, Droppable } from '../../../components'
import { ResidentTermDirectoryBaseDocument } from '../../../models/DocumentAudit/types'
import { SearchField } from '../../../components/SearchField'
import { toast } from 'react-toastify'
import { ResidentDirectoryModal } from '../ResidentDirectoryModal'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
}

export const UnpairedTab = (props: Props) => {
    const { theme, height, leaseAuditController } = props

    const [searchLeases, setSearchLeases] = useState('')
    const [searchRentRolls, setSearchRentRolls] = useState('')
    const [
        selectedResidentDirectoryId,
        setSelectedResidentDirectoryId,
    ] = useState(-1)

    useEffect(() => {
        if (leaseAuditController.leaseAudit) {
            leaseAuditController.fetchUnpairedResidentTermDirectories(
                leaseAuditController.leaseAudit.id,
            )
        }
    }, [])

    if (leaseAuditController.loading.fetchUnpairedResidentTermDirectories) {
        return (
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: height,
                }}
            >
                <CircularProgress />
            </Container>
        )
    }

    if (leaseAuditController.unpairedResidentTermDirectories === null) {
        return (
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: height,
                }}
            >
                <span>Nothing to display</span>
            </Container>
        )
    }

    const unpairedLeases =
        leaseAuditController.unpairedResidentTermDirectories.unpaired_leases
    const unpairedRentRolls =
        leaseAuditController.unpairedResidentTermDirectories.unpaired_rent_rolls

    const filteredUnpairedLeases = unpairedLeases.filter(
        (unpairedResidentTermDirectory) =>
            unpairedResidentTermDirectory.customer.name
                .toLowerCase()
                .includes(searchLeases.toLowerCase()),
    )
    const filteredUnpairedRentRolls = unpairedRentRolls.filter(
        (unpairedResidentTermDirectory) =>
            unpairedResidentTermDirectory.customer.name
                .toLowerCase()
                .includes(searchRentRolls.toLowerCase()),
    )

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    height: 80,
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <SearchField
                        placeholder="Search Leases"
                        onChange={(e) => setSearchLeases(e.target.value)}
                        value={searchLeases}
                    />
                    <div style={{ flex: 1 }} />
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Leases ({filteredUnpairedLeases.length})
                    </span>
                    <div style={{ flex: 1 }} />
                </Container>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                    }}
                >
                    <SearchField
                        placeholder="Search Rent Rolls"
                        onChange={(e) => setSearchRentRolls(e.target.value)}
                        value={searchRentRolls}
                    />
                    <div style={{ flex: 1 }} />
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Rent Rolls ({filteredUnpairedRentRolls.length})
                    </span>
                    <div style={{ flex: 1 }} />
                </Container>
            </Container>
            {/* Column 1, Leases */}

            {/* Body */}
            <Container style={{ height: height - 80 }}>
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        overflow: 'auto',
                    }}
                >
                    {filteredUnpairedLeases.map(
                        (unpairedResidentTermDirectory) => (
                            <UnpairedResidentTermDirectoryRow
                                key={`lease-${unpairedResidentTermDirectory.id}`}
                                unpairedResidentTermDirectory={
                                    unpairedResidentTermDirectory
                                }
                                theme={theme}
                                leaseAuditController={leaseAuditController}
                                onOpenResidentDirectoryModal={
                                    setSelectedResidentDirectoryId
                                }
                            />
                        ),
                    )}
                </Container>

                {/* Column 2, Rent Rolls */}
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        overflow: 'auto',
                    }}
                >
                    {filteredUnpairedRentRolls.map(
                        (unpairedResidentTermDirectory) => (
                            <UnpairedResidentTermDirectoryRow
                                key={`rent-roll-${unpairedResidentTermDirectory.id}`}
                                unpairedResidentTermDirectory={
                                    unpairedResidentTermDirectory
                                }
                                theme={theme}
                                leaseAuditController={leaseAuditController}
                                onOpenResidentDirectoryModal={
                                    setSelectedResidentDirectoryId
                                }
                            />
                        ),
                    )}
                </Container>
            </Container>
            <ResidentDirectoryModal
                open={selectedResidentDirectoryId !== -1}
                onClose={() => setSelectedResidentDirectoryId(-1)}
                theme={theme}
                residentDirectoryId={selectedResidentDirectoryId}
                leaseAuditController={leaseAuditController}
            />
        </Container>
    )
}

const UnpairedResidentTermDirectoryRow = (props: {
    unpairedResidentTermDirectory: ResidentTermDirectoryBaseDocument
    theme: Theme
    leaseAuditController: LeaseAuditController
    onOpenResidentDirectoryModal: (residentDirectoryId: number) => void
}) => {
    const {
        unpairedResidentTermDirectory,
        theme,
        leaseAuditController,
        onOpenResidentDirectoryModal,
    } = props

    return (
        <Draggable
            onDragStart={() => {}}
            style={{
                margin: theme.spacing(2),
            }}
            payload={{
                id: unpairedResidentTermDirectory.id.toString(),
                dragType: 'unpaired-resident-term-directory',
                name: unpairedResidentTermDirectory.customer.name,
            }}
        >
            <Droppable
                handleDrop={(e, items) => {
                    const removeId = Number(items[0].id)
                    if (isNaN(removeId)) {
                        toast.error('Invalid ID')
                        return
                    }

                    const keepId = unpairedResidentTermDirectory.id

                    leaseAuditController.mergeResidentTermDirectories(
                        keepId,
                        removeId,
                    )
                }}
                dragOverStyle={{ background: theme.palette.primary.light }}
                style={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(2),
                    background: theme.palette.background.default,
                }}
            >
                <Container
                    style={{
                        alignItems: 'center',
                        cursor: 'move',
                    }}
                >
                    <DragIndicatorIcon />
                    <Container
                        style={{
                            marginLeft: theme.spacing(2),
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.text.primary,
                            }}
                        >
                            {unpairedResidentTermDirectory.customer.name}
                        </span>
                        <Container>
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightRegular,
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {unpairedResidentTermDirectory.lease_start}
                            </span>
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                    color: theme.palette.grey[500],
                                    marginLeft: theme.spacing(1),
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                -
                            </span>
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightRegular,
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {unpairedResidentTermDirectory.lease_end}
                            </span>
                        </Container>
                    </Container>
                    <div style={{ flex: 1 }} />

                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightRegular,
                            color: theme.palette.text.secondary,
                        }}
                    >
                        ({unpairedResidentTermDirectory.documents.length})
                        Documents
                    </span>
                    <IconButton
                        onClick={() => {
                            onOpenResidentDirectoryModal(
                                unpairedResidentTermDirectory.id,
                            )
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Container>
            </Droppable>
        </Draggable>
    )
}
