import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface PublicLayoutProps {
    children: React.ReactNode
}
const DRAWER_WIDTH = 136

export const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                width: '100%',
            },

            content: {
                flexGrow: 1,
                width: `calc(100vw - ${DRAWER_WIDTH}px)`,
            },
        }),
    )
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <main className={classes.content}>{children}</main>
        </div>
    )
}

export default PublicLayout
