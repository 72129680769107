import { Routes } from '../config/routes'

import { AdminGuard } from '../guards'
import type { RouteConfig } from '../types'

import { AdminLayout } from '../layouts'
import { OrganizationList } from '../../containers/Admin/Organization'
import { OrganizationDetail } from '../../containers/Admin/Organization/detail'
import { PropertyList } from '../../containers/Admin/Property'
import { PropertyDetail } from '../../containers/Admin/Property/detail'
import { UserList } from '../../containers/Admin/User'
import { UserDetail } from '../../containers/Admin/User/detail'
import { InvoiceList } from '../../containers/Admin/Invoice'
import { InvoiceDetail } from '../../containers/Admin/Invoice/detail'
import { VendorList } from '../../containers/Admin/Vendor'
import { VendorDetail } from '../../containers/Admin/Vendor/detail'

export const adminRoutes: RouteConfig[] = [
    {
        path: Routes.admin.organization.detail.root,
        component: OrganizationDetail,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.organization.list,
        component: OrganizationList,
        layout: AdminLayout,
        guard: AdminGuard,
    },

    {
        path: Routes.admin.properties.detail,
        component: PropertyDetail,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.properties.root,
        component: PropertyList,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.users.detail,
        component: UserDetail,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.users.root,
        component: UserList,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.vendors.detail,
        component: VendorDetail,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.vendors.root,
        component: VendorList,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.invoices.detail,
        component: InvoiceDetail,
        layout: AdminLayout,
        guard: AdminGuard,
    },
    {
        path: Routes.admin.invoices.root,
        component: InvoiceList,
        layout: AdminLayout,
        guard: AdminGuard,
    },
]
