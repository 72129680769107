import React from 'react'

import clsx from 'clsx'
import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
} from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

import Logo from '../../../assets/ezos-logo.png'
import NavItem from '../../components/NavItem'
import { navigationConfig } from '../../config/navigationItems'

interface Props {
    drawerWidth: number
    open: boolean
}
const SideBar = (props: Props) => {
    const theme = useTheme()

    const useStyles = React.useMemo(
        () =>
            makeStyles((theme: Theme) =>
                createStyles({
                    menuButton: {
                        marginRight: 36,
                    },
                    hide: {
                        display: 'none',
                    },
                    drawer: {
                        width: props.drawerWidth,
                        flexShrink: 0,
                        whiteSpace: 'nowrap',
                        backgroundColor: '#000',
                        color: '#737378',
                        zIndex: 0,
                        [theme.breakpoints.down('xs')]: {
                            display: 'none',
                        },
                    },
                    drawerOpen: {
                        width: props.drawerWidth,
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        backgroundColor: '#000',
                        color: '#737378',
                    },
                    drawerClose: {
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        overflowX: 'hidden',
                        width: `${props.drawerWidth}px !important`,
                        // width: theme.spacing(7) + 1,
                        [theme.breakpoints.up('sm')]: {
                            width: `${props.drawerWidth}px !important`,
                        },
                        backgroundColor: '#000',
                        color: '#737378',
                    },
                    toolbar: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: theme.spacing(0, 1),
                        // necessary for content to be below app bar
                        ...theme.mixins.toolbar,
                    },
                    logo: {
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100px',
                        marginBottom: '20px',
                    },
                }),
            ),
        [],
    )
    const classes = useStyles()

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    }),
                }}
            >
                <div className={classes.toolbar}></div>
                <img
                    src={Logo}
                    alt="EZ Turn Operating System Logo"
                    className={classes.logo}
                />

                <div style={{}}>
                    {navigationConfig.admin.mainItems.map((item) => (
                        <NavItem key={item.name} item={item} />
                    ))}
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default SideBar
