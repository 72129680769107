import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core'

interface FolderUnitDialogProps {
    open: boolean
    onClose: () => void
    onSubmit: (newName: string) => void
    currentName: string
    itemType: 'folder' | 'unit'
    title: string
}

export const FolderUnitDialog: React.FC<FolderUnitDialogProps> = ({
    open,
    onClose,
    onSubmit,
    currentName,
    itemType,
    title,
}) => {
    const [name, setName] = useState(currentName)

    const handleClose = () => {
        setName('')
        onClose()
    }

    const handleSubmit = () => {
        onSubmit(name)
        handleClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={`${itemType === 'folder' ? 'Folder' : 'Unit'} Name`}
                    fullWidth
                    value={name}
                    inputProps={{ maxLength: 12 }}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit()
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!name.trim() || name === currentName}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
