import React from 'react'

import { VENDOR } from '../../../../helpers'
import { useUser } from '../../../../hooks'
import { VendorItems } from '../../../components/SideBarItems/VendorItems'
import { ApartmentItems } from '../../../components/SideBarItems/ApartmentItems'

const SideBarNavigationItems = () => {
    const { workspaceUser } = useUser()
    if (!workspaceUser) return null

    if (workspaceUser.active_workspace.company_type === VENDOR) {
        return <VendorItems />
    }

    return <ApartmentItems />
}

export default SideBarNavigationItems
