import React, { PropsWithChildren, useState } from 'react'
import { useDragContext } from './DragContext'
import ReactDOM from 'react-dom'
import { DraggableItem } from './types'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onDragStart: React.DragEventHandler<HTMLDivElement>
    dragHandle?: React.MutableRefObject<any>
    payload: DraggableItem
    DragPreview?: React.ComponentType<{
        items: Array<DraggableItem>
    }>
}

export const Draggable = (props: PropsWithChildren<Props>) => {
    const [dragEnabled, setDragEnabled] = useState(true)
    const {
        selectedMap,
        addSelectedItems,
        removeSelectedItems,
        clearSelectedItems,
        isDragging,
        setIsDragging,
    } = useDragContext()

    const payload = props.payload

    const DragPreview = props.DragPreview

    const isSelected = selectedMap.get(payload.id) ?? false

    const getDragEnabled = (
        dragHandle?: React.MutableRefObject<any>,
        mouseDownEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        // - Check to see if the mouse down event target matches the provided drag handle
        // - if the provided drag handle is undefined always allow dragging
        // - if the mouse down event target is an svg "path" use the parent "svg" element
        //   as the target instead

        if (dragHandle === undefined) {
            setDragEnabled(true)
        } else {
            const target = mouseDownEvent?.target as HTMLElement | undefined

            if (target) {
                if (target.tagName === 'svg') {
                    setDragEnabled(dragHandle.current === target)
                } else if (target.parentElement?.tagName === 'svg') {
                    const de = dragHandle.current === target.parentElement
                    setDragEnabled(de)
                    return
                }
            }

            setDragEnabled(dragHandle.current === mouseDownEvent?.target)
        }
    }

    const hideElement = isDragging && isSelected

    return (
        <div
            {...props}
            style={{
                ...props.style,
                visibility: hideElement ? 'hidden' : 'visible',
                backgroundColor: isSelected
                    ? 'rgba(38, 132, 255, 0.08)'
                    : 'transparent',
                transition: 'background-color 0.2s ease',
                cursor: dragEnabled ? 'grab' : 'default',
                userSelect: 'none',
            }}
            draggable={dragEnabled}
            onClick={(e) => {
                e.stopPropagation()

                // If multiple is true and  the user is pressing control / command, add to selection
                if (payload.multiple && (e.ctrlKey || e.metaKey)) {
                    // Remove any non multiple items from selection
                    const removeItems: DraggableItem[] = []
                    selectedMap.forEach((value, key) => {
                        if (key !== payload.id && !value.multiple) {
                            removeItems.push(value)
                        }
                    })
                    removeSelectedItems(removeItems)

                    if (isSelected) {
                        removeSelectedItems(payload)
                    } else {
                        addSelectedItems(payload)
                    }
                } else {
                    // Regular click clears selection and selects only this item
                    clearSelectedItems()
                    addSelectedItems(payload)
                }

                props.onClick?.(e)
            }}
            onMouseDown={(e) => {
                getDragEnabled(props.dragHandle, e)
            }}
            onDragStart={(e) => {
                e.stopPropagation()

                if (DragPreview) {
                    // Create preview container
                    const preview = document.createElement('div')
                    document.body.appendChild(preview)

                    // Render preview using ReactDOM.render
                    const selectedItems = Array.from(selectedMap.values())
                    // eslint-disable-next-line react/no-deprecated
                    ReactDOM.render(
                        <DragPreview items={selectedItems} />,
                        preview,
                    )

                    // Set as drag image
                    e.dataTransfer.setDragImage(preview, 0, 0)

                    // Clean up
                    requestAnimationFrame(() => {
                        // eslint-disable-next-line react/no-deprecated
                        ReactDOM.unmountComponentAtNode(preview)
                        document.body.removeChild(preview)
                        addSelectedItems(payload)
                        setIsDragging(true)
                    })
                }

                props.onDragStart(e)
            }}
            onDragEnd={() => {
                setIsDragging(false)
                clearSelectedItems()
            }}
        >
            {props.children}
        </div>
    )
}
