import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { axiosInstance } from '../../../../helpers'
import { CompanyRow, CompanyWithInvoiceCode } from '../types'
import { AxiosResponse } from 'axios'

export const usePropertyTable = () => {
    const { data: propertyList, isLoading, isError, error, refetch } = useQuery<
        AxiosResponse<CompanyWithInvoiceCode[]>,
        Error
    >({
        queryKey: ['admin-properties'],
        queryFn: () => axiosInstance.get('/account-manager/properties/'),
    })

    const getFilteredRows = useCallback(
        (searchText: string) => {
            if (!propertyList) return []

            return propertyList.data
                .filter((row) =>
                    row.name
                        .toLocaleLowerCase()
                        .includes(searchText.toLocaleLowerCase()),
                )
                .map(mapCompanyToRow)
        },
        [propertyList],
    )

    return {
        propertyList: propertyList?.data,
        getFilteredRows,
        isLoading,
        isError,
        error,
        refetch,
    }
}

const mapCompanyToRow = (company: CompanyWithInvoiceCode): CompanyRow => {
    const companyTypeStr =
        company.company_type === 'APARTMENT' ? 'Apartment' : 'Vendor'

    return {
        id: { value: company.id, sortValue: company.id },
        name: { value: company.name, sortValue: company.name },
        organization: {
            value: company.organization.name,
            sortValue: company.organization.name,
        },
        address: {
            value: company.primary_address,
            sortValue: company.primary_address?.state ?? '',
        },
        type: { value: companyTypeStr, sortValue: companyTypeStr ?? '' },
        invoice_code: {
            value: company.invoice_code,
            sortValue: company.invoice_code ?? '',
        },
        company: { value: company, sortValue: company.name },
    }
}
