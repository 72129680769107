import { SimplePortfolioSchedule } from '../../../../models'

export interface ProjectModalState {
    selectedSchedule: SimplePortfolioSchedule | null
    burnDownModalOpen: boolean
    summaryReportModalOpen: boolean
    detailModalOpen: boolean
}

export const initialProjectModalState: ProjectModalState = {
    selectedSchedule: null,
    burnDownModalOpen: false,
    summaryReportModalOpen: false,
    detailModalOpen: false,
}
