import React, { useEffect, useMemo, useState } from 'react'

import {
    IconButton,
    Paper,
    TableSortLabel,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core'
import {
    Description,
    ExpandLess,
    ExpandMore,
    ShowChart,
} from '@material-ui/icons'

import { Container } from '../../../../components'
import {
    ConcernLevel,
    PortfolioSchedule,
    SimplePortfolioSchedule,
} from '../../../../models'
import { useStyles } from '../../../../styles'
import { calculateDaysRemaining } from '../../../../helpers'
import { ProjectModalState } from './types'
import { workorderContext } from '../../../../contexts'
import { useUser } from '../../../../hooks'

interface Props {
    scheduleList: SimplePortfolioSchedule[]
    theme: Theme
    searchText: string
    modalState: ProjectModalState
    setModalState: (state: ProjectModalState) => void
    getPortfolioScheduleDetail: (
        id: number,
        params: { organization?: number },
    ) => Promise<PortfolioSchedule>
}

export const ProjectTable = (props: Props) => {
    const {
        scheduleList,
        theme,
        searchText,
        modalState,
        setModalState,
        getPortfolioScheduleDetail,
    } = props

    const { organization } = useUser()

    const [expandedSections, setExpandedSections] = useState({
        high: false,
        medium: false,
        low: false,
    })

    const [sortConfig, setSortConfig] = useState<{
        key: string
        direction: 'asc' | 'desc'
    } | null>(null)

    const { getWorkorderList } = workorderContext()

    const toggleSection = (section: 'high' | 'medium' | 'low') => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }))
    }

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        justifyContent: 'left',
        flex: 1,
    }

    const highConcernSchedules = useMemo(() => {
        return scheduleList.filter((schedule) => {
            const matchesConcernLevel =
                schedule.concern_level === ConcernLevel.HIGH
            const matchesSearchText =
                schedule.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                schedule.apartment_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            return matchesConcernLevel && matchesSearchText
        })
    }, [scheduleList, searchText])

    const mediumConcernSchedules = useMemo(() => {
        return scheduleList.filter((schedule) => {
            const matchesConcernLevel =
                schedule.concern_level === ConcernLevel.MEDIUM
            const matchesSearchText =
                schedule.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                schedule.apartment_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            return matchesConcernLevel && matchesSearchText
        })
    }, [scheduleList, searchText])

    const lowConcernSchedules = useMemo(() => {
        return scheduleList.filter((schedule) => {
            const matchesConcernLevel =
                schedule.concern_level === ConcernLevel.LOW
            const matchesSearchText =
                schedule.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                schedule.apartment_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            return matchesConcernLevel && matchesSearchText
        })
    }, [scheduleList, searchText])

    useEffect(() => {
        if (highConcernSchedules.length > 0) {
            setExpandedSections((prevState) => ({
                ...prevState,
                high: true, // Auto open if there are high concern schedules
            }))
        }
    }, [highConcernSchedules])

    const sortedSchedules = (schedules: SimplePortfolioSchedule[]) => {
        if (!sortConfig) return schedules
        return [...schedules].sort((a, b) => {
            const getValue = (obj: any, path: string) => {
                if (path === 'days_remaining') {
                    return calculateDaysRemaining(obj.end_date)
                }
                return path.split('.').reduce((o, p) => o?.[p], obj)
            }

            const aValue = getValue(a, sortConfig.key)
            const bValue = getValue(b, sortConfig.key)

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1
            }
            return 0
        })
    }

    const requestSort = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc'
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'asc'
        ) {
            direction = 'desc'
        }
        setSortConfig({ key, direction })
    }

    const renderSection = (
        title: string,
        schedules: SimplePortfolioSchedule[],
        sectionKey: 'high' | 'medium' | 'low',
    ) => (
        <Paper
            elevation={3}
            style={{
                marginBottom: theme.spacing(4),
                padding: theme.spacing(2),
            }}
        >
            <Container
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    variant="h6"
                    style={{ fontWeight: theme.typography.fontWeightBold }}
                >
                    {title} ({schedules.length})
                </Typography>
                <IconButton onClick={() => toggleSection(sectionKey)}>
                    {expandedSections[sectionKey] ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )}
                </IconButton>
            </Container>
            {expandedSections[sectionKey] && (
                <>
                    <Container
                        style={{
                            display: 'flex',
                            padding: theme.spacing(1),
                            backgroundColor: theme.palette.grey[200],
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        <Typography style={{ ...headCellStyle }}>
                            <TableSortLabel
                                active={sortConfig?.key === 'name'}
                                direction={sortConfig?.direction || 'asc'}
                                onClick={() => requestSort('name')}
                            >
                                Project
                            </TableSortLabel>
                        </Typography>
                        <Typography style={{ ...headCellStyle }}>
                            <TableSortLabel
                                active={sortConfig?.key === 'apartment.name'}
                                direction={sortConfig?.direction || 'asc'}
                                onClick={() => requestSort('apartment.name')}
                            >
                                Location
                            </TableSortLabel>
                        </Typography>
                        <Typography style={{ ...headCellStyle }}>
                            <TableSortLabel
                                active={sortConfig?.key === 'days_remaining'}
                                direction={sortConfig?.direction || 'asc'}
                                onClick={() => requestSort('days_remaining')}
                            >
                                Days Remaining
                            </TableSortLabel>
                        </Typography>
                        <Typography style={{ ...headCellStyle }}>
                            <TableSortLabel
                                active={sortConfig?.key === 'baseline_budget'}
                                direction={sortConfig?.direction || 'asc'}
                                onClick={() => requestSort('baseline_budget')}
                            >
                                Budget
                            </TableSortLabel>
                        </Typography>
                        <Typography style={{ ...headCellStyle }}>
                            <TableSortLabel
                                active={sortConfig?.key === 'behind_count'}
                                direction={sortConfig?.direction || 'asc'}
                                onClick={() => requestSort('behind_count')}
                            >
                                Behind Count
                            </TableSortLabel>
                        </Typography>
                        <Container
                            style={{
                                width: '35px',
                                marginRight: theme.spacing(1),
                            }}
                        />
                    </Container>
                    <Container
                        style={{
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 350px)',
                            marginTop: theme.spacing(1),
                        }}
                        direction="column"
                    >
                        {sortedSchedules(schedules).map((schedule, idx) => (
                            <ProjectRow
                                key={`PROJECT_${idx}`}
                                schedule={schedule}
                                theme={theme}
                                onClickBurnDown={() => {
                                    getWorkorderList({
                                        params: {
                                            schedule: schedule.id,
                                            portfolio: true,
                                        },
                                    })
                                    setModalState({
                                        ...modalState,
                                        burnDownModalOpen: true,
                                        selectedSchedule: schedule,
                                    })
                                }}
                                onClickSummaryReport={() => {
                                    setModalState({
                                        ...modalState,
                                        summaryReportModalOpen: true,
                                        selectedSchedule: schedule,
                                    })
                                }}
                                onClickDetail={() => {
                                    let params = {}
                                    if (organization) {
                                        params = {
                                            organization: organization,
                                        }
                                    }

                                    getPortfolioScheduleDetail(
                                        schedule.id,
                                        params,
                                    )
                                    getWorkorderList({
                                        params: {
                                            schedule: schedule.id,
                                            portfolio: true,
                                        },
                                    })
                                    setModalState({
                                        ...modalState,
                                        detailModalOpen: true,
                                        selectedSchedule: schedule,
                                    })
                                }}
                            />
                        ))}
                    </Container>
                </>
            )}
        </Paper>
    )

    return (
        <Container style={{ flexDirection: 'column' }}>
            {renderSection('High Concern', highConcernSchedules, 'high')}
            {renderSection('Medium Concern', mediumConcernSchedules, 'medium')}
            {renderSection('Low Concern', lowConcernSchedules, 'low')}
        </Container>
    )
}

interface ProjectRowProps {
    schedule: SimplePortfolioSchedule
    theme: Theme
    onClickBurnDown: () => void
    onClickSummaryReport: () => void
    onClickDetail: () => void
}

const ProjectRow = (props: ProjectRowProps) => {
    const {
        schedule,
        theme,
        onClickBurnDown,
        onClickSummaryReport,
        onClickDetail,
    } = props

    const classes = useStyles()

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 400,
        flex: 1,
        justifyContent: 'left',
    }

    return (
        <Container
            style={{
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '10px',
                flexDirection: 'row',
                padding: theme.spacing(2),
                marginTop: theme.spacing(1),
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                alignItems: 'center',
            }}
            className={classes.hoverGrey300}
            onClick={onClickDetail}
        >
            <Container style={{ ...cellStyle }}>{schedule.name}</Container>
            <Container style={{ ...cellStyle }}>
                {schedule.apartment_name}
            </Container>
            <Container style={{ ...cellStyle }}>
                {calculateDaysRemaining(schedule.end_date)}
            </Container>
            <Container style={{ ...cellStyle }}>
                ${schedule.baseline_budget}
            </Container>
            <Container style={{ ...cellStyle, alignItems: 'center' }}>
                <span style={{ width: '30px' }}>
                    {schedule.behind_count || 0}
                </span>
                <Tooltip title="Burn Down Chart">
                    <IconButton
                        size="small"
                        style={{ marginLeft: theme.spacing(1) }}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            onClickBurnDown()
                        }}
                    >
                        <ShowChart />
                    </IconButton>
                </Tooltip>
            </Container>
            <Container style={{ width: '35px' }}>
                {schedule.summary_report && (
                    <Tooltip title="Project Summary">
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                onClickSummaryReport()
                            }}
                        >
                            <Description />
                        </IconButton>
                    </Tooltip>
                )}
            </Container>
        </Container>
    )
}
