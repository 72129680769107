import { CircularProgress, Tab, Tabs, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, SideDrawerContainer } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    InspectionWithReportDate,
    Lease,
    Report,
    ReportTypes,
    Schedule,
} from '../../models'
import { InspectionReportTable } from './InspectionReportTable'
import { ReportsDrawer } from './ReportsDrawer'
import { WorkorderReportTable } from './WorkorderReportTable'
import { ReportTable } from './ReportTable'
import { useHistory, useLocation } from 'react-router-dom'

const reportTypes =
    'LEASE_SYNC_REPORT,BED_STATUS_SYNC_REPORT,DAMAGE_AND_TRANSACTION_REPORT,UNIT_NOTES_REPORT,LEASE_AUDIT_REPORT'

export const ReportsHome = () => {
    const theme = useTheme()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const inspectionPromise = axiosInstance.get('inspection/', {
            params: { has_report: true },
        })
        const workorderPromise = axiosInstance.get(`workorder/schedule/`, {
            params: { has_report: true },
        })
        const reportPromise = axiosInstance.get(`stats/reports/`, {
            params: { report_types: reportTypes },
        })

        Promise.all([inspectionPromise, workorderPromise, reportPromise])
            .then((values) => {
                setInspectionList(values[0].data)
                setScheduleList(values[1].data)

                const allReports: Report[] = values[2].data
                const tempUnitNotesReports: Report[] = []
                const tempDamageReports: Report[] = []
                const tempLeaseSyncReports: Report[] = []
                const tempBedStatusSyncReports: Report[] = []
                allReports.forEach((report) => {
                    if (report.report_type === ReportTypes.UNIT_NOTES_REPORT) {
                        tempUnitNotesReports.push(report)
                    } else if (
                        report.report_type ===
                        ReportTypes.DAMAGE_AND_TRANSACTION_REPORT
                    ) {
                        tempDamageReports.push(report)
                    } else if (
                        report.report_type === ReportTypes.LEASE_SYNC_REPORT
                    ) {
                        tempLeaseSyncReports.push(report)
                    } else if (
                        report.report_type ===
                        ReportTypes.BED_STATUS_SYNC_REPORT
                    ) {
                        tempBedStatusSyncReports.push(report)
                    }
                })
                setUnitNotesReports(tempUnitNotesReports)
                setLeaseSyncReports(tempLeaseSyncReports)
                setBedStatusSyncReports(tempBedStatusSyncReports)
                setDamageReports(tempDamageReports)
                setLeaseAuditReports(
                    allReports.filter(
                        (report) =>
                            report.report_type ===
                            ReportTypes.LEASE_AUDIT_REPORT,
                    ),
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const [scheduleList, setScheduleList] = useState<Schedule[]>([])
    const [inspectionList, setInspectionList] = useState<
        InspectionWithReportDate[]
    >([])

    const [leaseSyncReports, setLeaseSyncReports] = useState<Report[]>([])
    const [bedStatusSyncReports, setBedStatusSyncReports] = useState<Report[]>(
        [],
    )
    const [damageReports, setDamageReports] = useState<Report[]>([])

    const [unitNotesReports, setUnitNotesReports] = useState<Report[]>([])
    const [leaseAuditReports, setLeaseAuditReports] = useState<Report[]>([])
    const [loading, setLoading] = useState(true)

    const [selectedSchedule, setSelectedSchedule] = useState<Schedule>()
    const [
        selectedInspection,
        setSelectedInspection,
    ] = useState<InspectionWithReportDate>()

    const WORKORDER_TAB = 0
    const INSPECTION_TAB = 1
    const LEASE_SYNCING_TAB = 2
    const BED_STATUS_SYNCING_TAB = 3
    const DAMAGE_TAB = 4
    const UNIT_NOTES_TAB = 5
    const LEASE_AUDIT_TAB = 6

    const TabHashMap: { [key: number]: string } = {
        [WORKORDER_TAB]: 'workorders',
        [INSPECTION_TAB]: 'inspections',
        [LEASE_SYNCING_TAB]: 'lease-syncing',
        [BED_STATUS_SYNCING_TAB]: 'bed-status-syncing',
        [DAMAGE_TAB]: 'damages',
        [UNIT_NOTES_TAB]: 'unit-notes',
        [LEASE_AUDIT_TAB]: 'lease-audits',
    }

    const hashToTab = Object.fromEntries(
        Object.entries(TabHashMap).map(([key, value]) => [value, parseInt(key)]),
    )

    useEffect(() => {
        const hash = location.hash.replace('#', '')
        if (hash in hashToTab) {
            setSelectedTab(hashToTab[hash])
        }
    }, [location])

    const [selectedTab, setSelectedTab] = useState<number>(() => {
        const hash = location.hash.replace('#', '')
        return hash in hashToTab ? hashToTab[hash] : WORKORDER_TAB
    })

    const [drawerOpen, setDrawerOpen] = useState(false)

    const closeDrawer = () => {
        setDrawerOpen(false)
        setSelectedInspection(undefined)
        setSelectedSchedule(undefined)
    }

    const handleTabChange = (_: any, newValue: number) => {
        if (newValue !== selectedTab) {
            setSelectedTab(newValue)
            history.push(`/reports#${TabHashMap[newValue]}`)
            closeDrawer()
        }
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            {loading ? (
                <Container
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh',
                    }}
                >
                    <CircularProgress size={80} />
                </Container>
            ) : (
                <Container
                    flex={1}
                    direction="column"
                    style={{
                        height: 'calc(100vh - 104px)',
                        overflow: 'hidden', // Ensure the container doesn't overflow
                    }}
                >
                    {/* Header */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Reports
                        </span>
                    </Container>

                    {/* Table */}
                    <Container
                        style={{
                            overflowX: 'auto',
                            width: '100%',
                            flexDirection: 'column',
                            overflowY: 'auto',
                            flex: 1,
                        }}
                    >
                        <Tabs
                            style={{
                                width: '100%',
                                backgroundColor: theme.palette.grey[300],
                            }}
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label="Work Orders" />
                            <Tab label="Inspections" />
                            <Tab label="Lease Syncing" />
                            <Tab label="Bed Status Syncing" />
                            <Tab label="Damages" />
                            <Tab label="Unit Notes" />
                            <Tab label="Lease Audits" />
                        </Tabs>
                        <Container
                            style={{
                                flex: 1,
                                backgroundColor: theme.palette.grey[100],
                                overflowY: 'auto',
                            }}
                        >
                            {selectedTab === WORKORDER_TAB && (
                                <WorkorderReportTable
                                    scheduleList={scheduleList}
                                    onClick={(newSchedule) => {
                                        setSelectedSchedule(newSchedule)
                                        setDrawerOpen(true)
                                    }}
                                />
                            )}
                            {selectedTab === INSPECTION_TAB && (
                                <InspectionReportTable
                                    inspectionList={inspectionList}
                                    onClick={(inspection) => {
                                        setSelectedInspection(inspection)
                                        setDrawerOpen(true)
                                    }}
                                />
                            )}
                            {selectedTab === LEASE_SYNCING_TAB && (
                                <ReportTable reports={leaseSyncReports} />
                            )}
                            {selectedTab === BED_STATUS_SYNCING_TAB && (
                                <ReportTable reports={bedStatusSyncReports} />
                            )}
                            {selectedTab === DAMAGE_TAB && (
                                <ReportTable reports={damageReports} />
                            )}
                            {selectedTab === UNIT_NOTES_TAB && (
                                <ReportTable reports={unitNotesReports} />
                            )}
                            {selectedTab === LEASE_AUDIT_TAB && (
                                <ReportTable reports={leaseAuditReports} />
                            )}
                        </Container>
                    </Container>
                </Container>
            )}

            <ReportsDrawer
                open={drawerOpen}
                handleClose={closeDrawer}
                inspectionId={selectedInspection?.id}
                scheduleId={selectedSchedule?.id}
            />
        </SideDrawerContainer>
    )
}
