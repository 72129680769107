import React from 'react'

import './App.css'
import 'react-toastify/dist/ReactToastify.css'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { ThemeProvider } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ToastContainer, toast } from 'react-toastify'

import { defaultTheme } from './styles'
import { ErrorProvider } from './hoc'

import { Container } from './components'
import 'hover.css'
import { RootNavigator } from './navigation/RootNavigator'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
toast.configure()
const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Container id="App">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={defaultTheme}>
                        <CssBaseline />
                        <ToastContainer
                            containerId="ROOT_TOAST_CONTAINER"
                            position="bottom-center"
                            autoClose={1000}
                        />
                        <ErrorProvider />
                        <RootNavigator />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </Container>
        </QueryClientProvider>
    )
}

export default App

// const App = () => {
//     return (
//         <ThemeProvider theme={defaultTheme}>
//             <ThemedApp />
//         </ThemeProvider>
//     )
// }

// const ThemedApp = () => {
//     const theme = useTheme()
//     return (
//         <Container
//             style={{
//                 flex: 1,
//                 height: '100%',
//                 flexDirection: 'column',
//             }}
//         >
//             <TemplateScheduleGantt theme={theme} />
//         </Container>
//     )
// }

// export default App
