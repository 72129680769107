import React, { useEffect, useState } from 'react'
import {
    IconButton,
    Modal,
    Paper,
    Slide,
    Tab,
    Tabs,
    Theme,
    Tooltip,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import CloseIcon from '@material-ui/icons/Close'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { ClusterGroup, WorkSpaceUser, WorkorderCluster } from '../../../models'
import { Container, MessageForm, WorkorderRow } from '../../../components'
import { useWorkorderApi } from '../../../contexts'

import { ClusterTab } from './ClusterTab'

import { Section, SectionList } from '../../../components/SectionList'
import { WorkorderResponse } from '../../../store'
import { ClusterDetailFormState } from '../types'
import { insertOrUpdateIdentifiable } from '../../../hooks/services'

interface Props {
    formState: ClusterDetailFormState | null
    theme: Theme
    workorderClusters: WorkorderCluster[]
    workspaceUser?: WorkSpaceUser
    onClose: () => void
    navigateToScheduleDetail?: (scheduleId: number) => void
    incrementClusterIndex: (by: number) => void
}

export const ClusterGroupDetail = (props: Props) => {
    const {
        formState,
        onClose,
        theme,
        workorderClusters,
        navigateToScheduleDetail,
        workspaceUser,
    } = props

    const [openMessageChannel, setOpenMessageChannel] = useState(-1)
    const [openServiceRequestChannel, setOpenServiceRequestChannel] = useState(
        -1,
    )
    const [sortMode, setSortMode] = useState(VENDOR_SORT_MODE)

    const {
        getWorkorderList,
        transitionWorkorder,
        getServiceRequestChannel,
    } = useWorkorderApi()

    const [workorderList, setWorkorderList] = useState<
        WorkorderResponse[] | null
    >(null)

    // let clusterGroup: ClusterGroup | null = null

    let titleString = ''
    const selectedCluster: WorkorderCluster | undefined =
        workorderClusters[formState?.selectedClusterIndex ?? 0]

    if (selectedCluster) {
        const d = new Date(selectedCluster.start_date)
        // Cluster group dates only contain YYYY-MM-DD, timezone is missing
        // localize the date using the clients timezone
        const tzOffsetMinutes = d.getTimezoneOffset()
        d.setTime(d.getTime() + tzOffsetMinutes * 1000 * 60)

        titleString = d.toLocaleDateString('en-US', {
            month: 'long',
            weekday: 'long',
            day: 'numeric',
            year: 'numeric',
        })
    }

    // if (selectedGroupIndex !== null) {
    //     clusterGroup = clusterGroups[selectedGroupIndex]

    //     const d = new Date(clusterGroup.start_date)
    //     // Cluster group dates only contain YYYY-MM-DD, timezone is missing
    //     // localize the date using the clients timezone
    //     const tzOffsetMinutes = d.getTimezoneOffset()
    //     d.setTime(d.getTime() + tzOffsetMinutes * 1000 * 60)

    //     titleString = d.toLocaleDateString('en-US', {
    //         month: 'long',
    //         weekday: 'long',
    //         day: 'numeric',
    //         year: 'numeric',
    //     })
    // }

    useEffect(() => {
        if (formState) {
            loadWorkorders(formState)
        }
    }, [formState])

    const loadWorkorders = async (selection: SelectionState) => {
        if (selection.selectedClusterIndex !== null) {
            console.log(selection.selectedClusterIndex)
            const cluster = workorderClusters[selection.selectedClusterIndex]
            getWorkorderList({
                params: { id_list: cluster.workorder_ids },
            }).then((res) => {
                setWorkorderList(res)
            })
        } else {
            setWorkorderList(null)
        }
    }

    const open = formState !== null

    // Build the workorder sections
    const woSections: Section<WorkorderResponse>[] = []
    const workorders = workorderList ?? []

    const sortedWorkorders = workorders.sort((a, b) => {
        if (sortMode === VENDOR_SORT_MODE) {
            return (a.vendor_id || -1) - (b.vendor_id || -1)
        }
        return (a.schedule_id || -1) - (b.schedule_id || -1)
    })

    let sectionPtr = 0

    const getWorkorderSection = (wo: WorkorderResponse) => {
        if (sortMode === VENDOR_SORT_MODE) {
            return {
                identifier: wo.vendor_id ?? -1,
                title: wo?.vendor_name ?? 'No Vendor',
                data: [wo],
            }
        } else {
            return {
                identifier: wo.schedule_id ?? -1,
                title: wo?.schedule_name ?? 'No Schedule',
                data: [wo],
            }
        }
    }

    for (let i = 0; i < sortedWorkorders.length; i++) {
        const curWo = sortedWorkorders[i]
        const currentSection = woSections[sectionPtr]

        const section = getWorkorderSection(curWo)

        if (currentSection === undefined) {
            woSections[sectionPtr] = section
        } else {
            if (currentSection.identifier === section.identifier) {
                currentSection.data.push(curWo)
            } else {
                sectionPtr += 1
                woSections[sectionPtr] = section
            }
        }
    }

    const clickOpenServiceRequestChannel = (workorder: WorkorderResponse) => {
        setOpenMessageChannel(-1)
        if (workorder.service_request) {
            if (workorder.service_request.channel_id) {
                setOpenServiceRequestChannel(
                    workorder.service_request.channel_id,
                )
            } else {
                getServiceRequestChannel(workorder).then((channelId) => {
                    setOpenServiceRequestChannel(channelId)
                })
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: theme.shape.borderRadius,
                        minHeight: h,
                        maxHeight: h,
                        minWidth: w,
                        maxWidth: w,
                    }}
                >
                    {/* Content */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            overflowY: 'scroll',
                            justifyContent: 'center',
                        }}
                    >
                        {/* Header Info */}
                        <Container
                            style={{
                                minHeight: 100,
                                maxHeight: 100,
                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                alignItems: 'center',
                                padding: theme.spacing(1),
                            }}
                        >
                            <Container
                                style={{ flexDirection: 'column', flex: 1 }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight: 'bold',
                                        color: theme.palette.grey[700],
                                    }}
                                >
                                    Group Mode
                                </span>
                                <Tabs
                                    style={{
                                        flex: 1,
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                    }}
                                    value={sortMode}
                                    onChange={(_, v) => {
                                        setSortMode(v)
                                    }}
                                >
                                    <Tab
                                        value={VENDOR_SORT_MODE}
                                        label="Vendor"
                                        style={{
                                            textTransform: 'none',
                                            borderRight: `1px solid ${theme.palette.grey[400]}`,
                                        }}
                                    />
                                    <Tab
                                        value={SCHEDULE_SORT_MODE}
                                        label="Project"
                                        style={{ textTransform: 'none' }}
                                    />
                                </Tabs>
                            </Container>

                            {/* Title / date changer */}
                            <Container
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        props.incrementClusterIndex(-1)
                                    }}
                                >
                                    <KeyboardArrowLeftIcon />
                                </IconButton>

                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginLeft: theme.spacing(1),
                                        marginRight: theme.spacing(1),
                                        minWidth: 350,
                                        maxWidth: 350,
                                        textAlign: 'center',
                                    }}
                                >
                                    {titleString}
                                </span>

                                <IconButton
                                    onClick={() => {
                                        props.incrementClusterIndex(1)
                                    }}
                                >
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </Container>

                            <div style={{ flex: 1 }} />

                            <div>
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Container>

                        {/* Body */}
                        <Container style={{ flex: 1, overflow: 'hidden' }}>
                            {/* Rows */}
                            <Container style={{ flex: 1, overflowY: 'scroll' }}>
                                {/* Left Column Service Selection tabs */}

                                {/* Right Column, Workorder Contents */}
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <SectionList<WorkorderResponse>
                                        sections={woSections}
                                        theme={theme}
                                        renderItem={(wo) => {
                                            return (
                                                <WorkorderRow
                                                    key={`WORKORDER-${wo.id}`}
                                                    workorder={wo}
                                                    user={workspaceUser}
                                                    canSeeUnitNotes
                                                    isSelectedWorkorder={
                                                        wo.channel ===
                                                        openMessageChannel
                                                    }
                                                    openMessageDrawer={() => {
                                                        const cId =
                                                            openMessageChannel ===
                                                            wo.channel
                                                                ? -1
                                                                : wo.channel
                                                        setOpenMessageChannel(
                                                            cId,
                                                        )
                                                    }}
                                                    openUnitNotes={() => {}}
                                                    transitionWorkorder={(
                                                        wo,
                                                        status,
                                                    ) => {
                                                        return transitionWorkorder(
                                                            wo,
                                                            status,
                                                        )
                                                            .then((wo) => {
                                                                insertOrUpdateIdentifiable(
                                                                    wo,
                                                                    setWorkorderList,
                                                                )
                                                            })
                                                            .catch((e) => {
                                                                console.log(
                                                                    'error updating',
                                                                    e,
                                                                )
                                                            })
                                                    }}
                                                    onClickWorkorder={() => {}}
                                                    navigateToSchedule={
                                                        navigateToScheduleDetail
                                                    }
                                                    updateWorkorderState={() => {}}
                                                    style={{
                                                        width: '100%',
                                                        margin: theme.spacing(
                                                            1,
                                                        ),
                                                    }}
                                                    openServiceRequestChannel={() => {
                                                        clickOpenServiceRequestChannel(
                                                            wo,
                                                        )
                                                    }}
                                                />
                                            )
                                        }}
                                        renderSectionHeader={(section) => {
                                            let scheduleNavButton: JSX.Element | null = null
                                            if (
                                                sortMode ===
                                                    SCHEDULE_SORT_MODE &&
                                                navigateToScheduleDetail
                                            ) {
                                                scheduleNavButton = (
                                                    <div>
                                                        <Tooltip
                                                            title={`Go to ${section.title}`}
                                                        >
                                                            <IconButton
                                                                style={{
                                                                    border: `1px solid ${theme.palette.grey[300]}`,
                                                                    marginRight: theme.spacing(
                                                                        1,
                                                                    ),
                                                                }}
                                                                onClick={() => {
                                                                    navigateToScheduleDetail(
                                                                        Number(
                                                                            section.identifier,
                                                                        ),
                                                                    )
                                                                }}
                                                            >
                                                                <ExitToAppIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }

                                            return (
                                                <Container
                                                    style={{
                                                        width: '100%',
                                                        height: 100,
                                                        padding: theme.spacing(
                                                            1,
                                                        ),
                                                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                                                        backgroundColor:
                                                            theme.palette
                                                                .grey[200],

                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {scheduleNavButton}
                                                    <span
                                                        style={{
                                                            ...theme.typography
                                                                .subtitle1,
                                                            fontWeight:
                                                                theme.typography
                                                                    .fontWeightBold,
                                                        }}
                                                    >
                                                        {section.title} (
                                                        {section.data.length})
                                                    </span>
                                                </Container>
                                            )
                                        }}
                                    />
                                </Container>
                            </Container>

                            {openMessageChannel !== -1 && (
                                <Container
                                    style={{
                                        width: 400,
                                        borderLeft: `1px solid ${theme.palette.grey[700]}`,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Container
                                        style={{
                                            padding: theme.spacing(1),
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setOpenMessageChannel(-1)
                                                setOpenServiceRequestChannel(-1)
                                            }}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>

                                        <span
                                            style={{
                                                ...theme.typography.h5,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                marginLeft: theme.spacing(1),
                                            }}
                                        >
                                            Messages
                                        </span>
                                    </Container>

                                    <MessageForm
                                        theme={theme}
                                        channelId={openMessageChannel}
                                        afterCreateMessage={() => {
                                            const wo = workorderList?.find(
                                                (wo) =>
                                                    wo.channel ===
                                                    openMessageChannel,
                                            )
                                            if (wo) {
                                                insertOrUpdateIdentifiable(
                                                    {
                                                        ...wo,
                                                        message_count:
                                                            wo.message_count +
                                                            1,
                                                    },
                                                    setWorkorderList,
                                                )
                                            }
                                        }}
                                    />
                                </Container>
                            )}

                            {openMessageChannel === -1 &&
                                openServiceRequestChannel !== -1 && (
                                    <Container
                                        style={{
                                            width: 400,
                                            borderLeft: `1px solid ${theme.palette.grey[700]}`,
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Container
                                            style={{
                                                padding: theme.spacing(1),
                                                alignItems: 'center',
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setOpenMessageChannel(-1)
                                                    setOpenServiceRequestChannel(
                                                        -1,
                                                    )
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>

                                            <span
                                                style={{
                                                    ...theme.typography.h5,
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightBold,
                                                    marginLeft: theme.spacing(
                                                        1,
                                                    ),
                                                }}
                                            >
                                                Tenant Service Request Messages
                                            </span>
                                        </Container>

                                        <MessageForm
                                            theme={theme}
                                            channelId={
                                                openServiceRequestChannel
                                            }
                                            afterCreateMessage={() => {
                                                const wo = workorderList?.find(
                                                    (wo) =>
                                                        wo.service_request
                                                            ?.channel_id ===
                                                        openServiceRequestChannel,
                                                )
                                                if (wo?.service_request) {
                                                    const updatedWo: WorkorderResponse = {
                                                        ...wo,
                                                        service_request: {
                                                            ...wo.service_request,
                                                            message_count:
                                                                (wo
                                                                    .service_request
                                                                    .message_count ??
                                                                    0) + 1,
                                                            unread_count: 0,
                                                        },
                                                    }
                                                    console.log('BAR')
                                                    insertOrUpdateIdentifiable(
                                                        updatedWo,
                                                        setWorkorderList,
                                                    )
                                                }
                                            }}
                                        />
                                    </Container>
                                )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const DESIRED_SIZE_PERCENT = 0.8

const screenH = window.innerHeight
const screenW = window.innerWidth
const aspectRatio = screenH / screenW

const h = screenH * DESIRED_SIZE_PERCENT
const w = screenW * DESIRED_SIZE_PERCENT * aspectRatio

type SelectionState = {
    selectedClusterIndex: number | null
}

const VENDOR_SORT_MODE = 0
const SCHEDULE_SORT_MODE = 1
