import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import {
    adminRoutes,
    apartmentRoutes,
    organizationRoutes,
    publicRoutes,
    vendorRoutes,
    manageRoutes,
} from './routes'
import type { PublicRouteConfig, RouteConfig } from './types'
import { useUser } from '../hooks'
import { sharedRoutes } from './routes/sharedRoutes'

export const RootNavigator: React.FC = () => {
    const { rootUser, workspaceUser, isLoading, isError } = useUser({
        shouldAuthenticate: true,
        shouldSetup: true,
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Error loading user data</div>
    }

    const renderPublicRoutes = (routes: PublicRouteConfig[]) => {
        return routes.map((route) => {
            const Component = route.component
            const Layout = route.layout || React.Fragment
            const Guard = route.guard || React.Fragment

            return (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        <Guard
                            {...(route.guard
                                ? { workspaceUser, rootUser }
                                : {})}
                        >
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        </Guard>
                    )}
                />
            )
        })
    }

    const renderRoutes = (routes: RouteConfig[]) => {
        return routes.map((route) => {
            const Component = route.component
            const Layout = route.layout
            const Guard = route.guard || React.Fragment

            return (
                <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        <Guard
                            {...(route.guard
                                ? { workspaceUser, rootUser }
                                : {})}
                        >
                            <Layout user={rootUser!}>
                                <Component {...props} />
                            </Layout>
                        </Guard>
                    )}
                />
            )
        })
    }

    return (
        <BrowserRouter>
            <Switch>
                {/* Public routes */}
                {renderPublicRoutes(publicRoutes)}

                {/* Admin routes */}
                {renderRoutes(adminRoutes)}

                {/* Apartment routes */}
                {renderRoutes(apartmentRoutes)}

                {/* Organization routes */}
                {renderRoutes(organizationRoutes)}

                {/* Vendor routes */}
                {renderRoutes(vendorRoutes)}

                {/* Shared routes */}
                {renderRoutes(sharedRoutes)}

                {/* Organization routes */}
                {renderRoutes(organizationRoutes)}
                {/* Manage routes */}
                {renderRoutes(manageRoutes)}

                {/* Fallback route */}
                <Route path="*" render={() => <Redirect to="/login" />} />
            </Switch>
        </BrowserRouter>
    )
}
