import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { useEffect, useState } from 'react'

import { Carousel } from 'react-responsive-carousel'

import { Modal, Paper, Slide, Tab, Tabs, Theme } from '@material-ui/core'

import { CalendarEventType, LeaseModalFormState } from '../../types'
import { useLease } from '../../../../hooks'
import { useSelectionMap } from '../../../../hooks/useSelectionMap'
import { headerH, modalH, modalW } from './constants'
import { LeaseListPage } from './LeaseListPage'
import { CreateMoveInEventPage } from './CreateMoveInEventPage'
import { Container } from '../../../../components'
import { MoveInEventList } from './MoveInEventList'

import { useMoveInInspection } from '../../../../hooks/useMoveInInspection'
import { toast } from 'react-toastify'
import { MoveInEventDetail } from './MoveInEventDetail'
import { useMoveOutRules } from '../../../../hooks/useMoveOutRules'
import { ChooseMoveOutRule } from '../../../MoveOutRule/ChooseMoveOutRule'
import { InspectionType, ListVendor, Service } from '../../../../models'

interface Props {
    theme: Theme
    open: LeaseModalFormState | null
    vendorList: ListVendor[]
    serviceList: Service[]
    inspectionTypeList: InspectionType[]
    handleClose: () => void
    onReloadData: () => void
}

export const LeaseModal = (props: Props) => {
    const {
        theme,
        open,
        vendorList,
        serviceList,
        inspectionTypeList,
        handleClose,
        onReloadData,
    } = props

    const selectionController = useSelectionMap()
    const leaseController = useLease()

    const moveInController = useMoveInInspection()

    const moveOutRuleController = useMoveOutRules()

    const [selectedPage, setSelectedPage] = useState(0)

    let selectedDate = new Date()

    let moveInMode = true

    if (open) {
        if (open.leaseCluster) {
            const dateStr = open.leaseCluster.date
            selectedDate = new Date(dateStr)
            selectedDate.setTime(
                selectedDate.getTime() +
                    selectedDate.getTimezoneOffset() * 60 * 1000,
            )
        }

        if (open.type === CalendarEventType.MOVE_OUT) {
            moveInMode = false
        }
    }

    useEffect(() => {
        selectionController.clearSelections()
        if (!open) {
            leaseController.setLeaseList([])
        } else if (open.leaseCluster && open.leaseCluster.count > 0) {
            if (moveInMode) {
                leaseController.getLeaseList({
                    params: {
                        move_in_date: selectedDate.toISOString(),
                        is_child: false,
                        exclude_children: true,
                        home: true,
                    },
                })

                moveInController.getMoveInInspectionEvents({
                    date: selectedDate.toISOString(),
                })
            } else {
                leaseController.getLeaseList({
                    params: {
                        move_out_date: selectedDate.toISOString(),
                        is_child: false,
                        exclude_children: true,
                        home: true,
                    },
                })
                moveOutRuleController.getMoveOutRuleList()
            }
        } else {
            leaseController.getLeaseList({
                params: {
                    lte_start_date: selectedDate.toISOString(),
                    gte_end_date: selectedDate.toISOString(),
                    is_child: false,
                    exclude_children: true,
                    home: true,
                },
            })
            moveOutRuleController.getMoveOutRuleList()
        }
        setSelectedPage(0)
    }, [open])

    return (
        <Modal
            open={open !== null}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open !== null}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: theme.shape.borderRadius,
                        minHeight: modalH,
                        maxHeight: modalH,
                        minWidth: modalW,
                        maxWidth: modalW + 200,
                    }}
                >
                    <Container
                        style={{
                            minHeight: headerH,
                            maxHeight: headerH,
                        }}
                    >
                        <Tabs
                            style={{}}
                            value={selectedPage}
                            onChange={(_, v) => {
                                setSelectedPage(v)
                            }}
                        >
                            <Tab label="Leases" />
                            {moveInMode && (
                                <Tab label="Scheduled move in inspections" />
                            )}
                        </Tabs>
                    </Container>
                    {moveInMode ? (
                        <Carousel
                            swipeable={false}
                            showThumbs={false}
                            showArrows={false}
                            showIndicators={false}
                            showStatus={false}
                            selectedItem={selectedPage}
                        >
                            <LeaseListPage
                                theme={theme}
                                handleClose={handleClose}
                                leaseController={leaseController}
                                selectionController={selectionController}
                                selectedDate={selectedDate}
                                onClickScheduleMoveIn={() => {
                                    setSelectedPage(2)
                                }}
                                moveInMode={moveInMode}
                                onClickScheduleMoveOut={() => {
                                    setSelectedPage(4)
                                }}
                                moveInController={moveInController}
                            />

                            <MoveInEventList
                                theme={theme}
                                moveInController={moveInController}
                                selectedDate={selectedDate}
                                selectEvent={(moveInEvent) => {
                                    moveInController.setSelectedMoveInEventId(
                                        moveInEvent.id,
                                    )
                                    setSelectedPage(3)
                                }}
                            />

                            <CreateMoveInEventPage
                                theme={theme}
                                onConfirm={async (
                                    daysToComplete,
                                    selectedDate,
                                ) => {
                                    const selectedLeases = selectionController.getSelectedItems(
                                        leaseController.leaseList,
                                    )

                                    await moveInController.queueLeaseMoveIns(
                                        selectedLeases,
                                        daysToComplete,
                                        selectedDate,
                                    )
                                    selectionController.clearSelections()
                                    setSelectedPage(0)
                                    toast.success(
                                        'Move in inspection scheduled',
                                    )
                                }}
                                onClickBack={() => {
                                    setSelectedPage(0)
                                }}
                                disabled={
                                    selectionController.selectionMap.length ===
                                    0
                                }
                            />
                            <MoveInEventDetail
                                theme={theme}
                                onClickBack={() => setSelectedPage(1)}
                                moveInController={moveInController}
                            />
                        </Carousel>
                    ) : (
                        <Carousel
                            swipeable={false}
                            showThumbs={false}
                            showArrows={false}
                            showIndicators={false}
                            showStatus={false}
                            selectedItem={selectedPage}
                        >
                            <LeaseListPage
                                theme={theme}
                                handleClose={handleClose}
                                leaseController={leaseController}
                                selectionController={selectionController}
                                selectedDate={selectedDate}
                                onClickScheduleMoveIn={() => {
                                    setSelectedPage(2)
                                }}
                                moveInMode={moveInMode}
                                onClickScheduleMoveOut={() => {
                                    setSelectedPage(1)
                                }}
                                moveInController={moveInController}
                            />

                            <ChooseMoveOutRule
                                moveOutRuleList={
                                    moveOutRuleController.moveOutRuleList
                                }
                                vendorList={vendorList}
                                serviceList={serviceList}
                                inspectionTypeList={inspectionTypeList}
                                leaseController={leaseController}
                                selectionController={selectionController}
                                selectedDate={selectedDate}
                                onClickBack={() => {
                                    setSelectedPage(0)
                                }}
                                scheduleMoveOut={
                                    moveOutRuleController.scheduleMoveOut
                                }
                                onReloadData={props.onReloadData}
                                handleClose={handleClose}
                            />
                        </Carousel>
                    )}
                </Paper>
            </Slide>
        </Modal>
    )
}
