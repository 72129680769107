export const Routes = {
    // Public/Auth routes (accessible to all)
    public: {
        login: '/login',
        loginHome: '/login-home',
        register: '/register',
        userRegister: '/user-register',
        vendorRegister: '/vendor-register',
        skillcatRegister: '/skillcat-register',
        requestPasswordReset: '/request-password-reset',
        checkEmailConfirmation: '/check-email-confirmation',
        resetPassword: '/forgot-password',
        verify: '/verify',
        deleteAccount: '/delete-account',
        nationalPropertyList: '/national-property-list',
        nvl: {
            root: '/national-vendor-list',
        },
    },

    // Apartment-specific routes
    apartment: {
        dashboard: '/dashboard',
        schedule: {
            root: '/schedule',
            detail: '/schedule/:id',
            archived: '/schedule/:id/archive',
        },
        inspection: {
            root: '/inspect',
            detail: '/inspect/:id',
        },
        jobBoard: '/job-board',
        actionItems: '/action-item',
        lease: {
            root: '/leases',
            audit: '/lease-audit',
            auditDetail: '/lease-audit/:id',
        },
        config: {
            inventory: '/inventory-config',
            area: '/area-config',
            unit: '/unit-config',
            damage: '/damage-config',
            statusGroups: '/status-groups',
            changeOrder: '/change-order-config',
            infrastructure: {
                manager: '/infrastructure-manager',
                config: '/infrastructure-configure',
            },
            propertyAutomation: '/property-automation',
            serviceCategories: '/service-categories',
        },
        rfp: {
            rfpDashboard: '/rfp-dashboard',
        },
    },

    // Vendor-specific routes
    vendor: {
        ezwork: '/ezwork',
        settings: '/vendor-settings',
        help: '/vendor-help',
        analytics: '/analytics/:id/',
    },

    // Shared features between Apartment and Vendor
    shared: {
        communication: {
            ezchat: '/ez-chat',
        },
        financial: {
            invoiceHome: '/invoice-home',
            invoiceList: '/invoice/company/:id',
            ezpay: '/ez-pay',
        },
        analytics: {
            root: '/analytics',
            portfolio: '/portfolio-analytics',
        },
        settings: {
            root: '/settings',
            general: '/general-settings',
        },
        reports: '/reports',
        workforce: '/workforce',
        help: '/help',
        propertyDetails: '/property-details',
    },

    // Organization-specific routes
    organization: {
        root: '/organization',
        detail: '/organization/:id',
        jobBoard: '/organization-job-board',
        projects: '/organization-projects',
    },

    // Admin-specific routes
    admin: {
        root: '/admin',
        organization: {
            list: '/admin/organization',
            detail: {
                root: '/admin/organization/:id',
                users: '/admin/organization/:id/users',
                settings: '/admin/organization/:id/settings',
                properties: '/admin/organization/:id/properties',
            },
        },
        users: {
            detail: '/admin/users/:id',
            root: '/admin/users',
        },
        properties: {
            detail: '/admin/properties/:id',
            root: '/admin/properties',
        },
        vendors: {
            detail: '/admin/vendors/:id',
            root: '/admin/vendors',
        },
        invoices: {
            detail: '/admin/invoices/:id',
            root: '/admin/invoices',
        },
    },

    // Error pages
    error: {
        permissionDenied: '/denied',
    },
} as const
// Updated type to only include string paths
export type RoutePath = {
    [K in keyof typeof Routes]: typeof Routes[K] extends string
        ? typeof Routes[K]
        : never
}[keyof typeof Routes]

// Helper to ensure type safety when using routes
export const getRoutePath = (path: RoutePath): string => path
