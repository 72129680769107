import React, { useState, useEffect, useCallback } from 'react'
import {
    Theme,
    Typography,
    Grid,
    Card,
    CardContent,
    Box,
    IconButton,
    Collapse,
    Tooltip,
    CircularProgress,
} from '@material-ui/core'
import Plot from 'react-plotly.js'
import { PlotData } from 'plotly.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { currencyFormatterNoDecimals } from '../../helpers'
import { LeaseAuditController } from '../../hooks/documentAudit'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'
import FileCopy from '@material-ui/icons/FileCopy'

import { Container } from '../../components'

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

interface Props {
    theme: Theme
    height: number
    leaseAuditController: LeaseAuditController
    isActive: boolean
}

const useStyles = makeStyles((theme) => ({
    largeTooltip: {
        fontSize: '1rem', // Adjust this value as needed
    },
}))

export const AnalysisPage = (props: Props) => {
    const { theme, height, leaseAuditController, isActive } = props

    const leaseAudit = leaseAuditController.leaseAudit
    const [isGeneratingReport, setIsGeneratingReport] = useState(false)
    const [timelineFilter, setTimelineFilter] = useState<
        'past' | 'current' | 'future'
    >('current')
    const classes = useStyles()

    const SectionCard: React.FC<{
        title: React.ReactNode
        children: React.ReactNode
        expandedInitial: boolean
    }> = ({ title, children, expandedInitial = false }) => {
        const [expanded, setExpanded] = useState(expandedInitial)

        return (
            <Card style={{ marginBottom: theme.spacing(3), width: '100%' }}>
                <CardContent>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5">{title}</Typography>
                        <IconButton onClick={() => setExpanded(!expanded)}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                    <Collapse in={expanded}>{children}</Collapse>
                </CardContent>
            </Card>
        )
    }

    const createUnitTypeChart = useCallback(() => {
        if (!leaseAuditController.analysisData) return null

        const { unit_types } = leaseAuditController.analysisData.units

        // Create an array of objects and filter out empty labels
        const sortedData = unit_types.labels
            .map((label: string, index: number) => ({
                label,
                count: unit_types.counts[index],
                avg_sqft: unit_types.avg_sqft[index],
                avg_rent: unit_types.avg_rent[index],
            }))
            .filter((item: { label: string }) => item.label !== '')

        // Sort the array based on count in descending order
        sortedData.sort(
            (a: { count: number }, b: { count: number }) => b.count - a.count,
        )

        const data: Partial<PlotData>[] = [
            {
                x: sortedData.map((item: { label: string }) => item.label),
                y: sortedData.map((item: { count: number }) => item.count),
                type: 'bar',
                text: sortedData.map((item: { count: number }) =>
                    item.count.toString(),
                ),
                textposition: 'auto',
                hoverinfo: 'x+y',
            },
        ]

        const maxCount = Math.max(
            ...sortedData.map((item: { count: number }) => item.count),
        )
        const yPosUnitTypeLabel = maxCount * 1.35
        const yPosAvgSqft = maxCount * 1.28
        const yPosAvgRent = maxCount * 1.21

        const layout = {
            xaxis: { title: 'Unit Type' },
            yaxis: { title: 'Count', range: [0, maxCount * 1.4] }, // setting the upper range * 1.4, allows for the annotations to appear above the plot
            annotations: sortedData
                .map(
                    (item: {
                        label: string
                        count: number
                        avg_sqft: number
                        avg_rent: number
                    }) => [
                        {
                            x: item.label,
                            y: yPosUnitTypeLabel,
                            text: item.label,
                            showarrow: false,
                            font: { size: 14, color: 'black', weight: 'bold' },
                        },
                        {
                            x: item.label,
                            y: yPosAvgSqft,
                            text: `Avg Sqft: ${item.avg_sqft}`,
                            showarrow: false,
                            font: { size: 12, color: 'gray' },
                        },
                        {
                            x: item.label,
                            y: yPosAvgRent,
                            text: `Avg Rent: ${currencyFormatterNoDecimals.format(
                                item.avg_rent,
                            )}`,
                            showarrow: false,
                            font: { size: 12, color: 'gray' },
                        },
                    ],
                )
                .flat(),
            margin: { t: 50 },
        }

        return (
            <Plot
                data={data}
                layout={layout}
                style={{ width: '100%', height: '500px' }}
            />
        )
    }, [leaseAuditController.analysisData])

    useEffect(() => {
        if (isActive && leaseAudit) {
            leaseAuditController.fetchAnalysisData(
                leaseAudit.id,
                timelineFilter,
            )
        }
    }, [isActive, leaseAudit, timelineFilter])

    return (
        <Container>
            <Container
                style={{
                    padding: theme.spacing(1),
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                <Container
                    style={{
                        display: 'flex',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <ToggleButtonGroup
                        exclusive
                        value={timelineFilter}
                        onChange={(_, value) => {
                            if (value === null) {
                                return
                            }
                            setTimelineFilter(value)
                        }}
                        size="small"
                    >
                        <ToggleButton value="past">Past</ToggleButton>
                        <ToggleButton value="current">Current</ToggleButton>
                        <ToggleButton value="future">Future</ToggleButton>
                    </ToggleButtonGroup>

                    <Tooltip
                        title="Generate Variance Report"
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <IconButton
                            onClick={async () => {
                                if (leaseAudit) {
                                    setIsGeneratingReport(true)
                                    await leaseAuditController.generateVarianceReport(
                                        leaseAudit.id,
                                    )
                                    setIsGeneratingReport(false)
                                }
                            }}
                            disabled={isGeneratingReport}
                        >
                            {isGeneratingReport ? (
                                <CircularProgress size={24} />
                            ) : (
                                <FileCopy />
                            )}
                        </IconButton>
                    </Tooltip>
                </Container>
                {leaseAuditController.loading.fetchAnalysisData ? (
                    <CircularProgress />
                ) : leaseAuditController.analysisData ? (
                    <>
                        {/* Financial Summary */}
                        <SectionCard
                            title={
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h5">
                                        Financial Summary
                                    </Typography>
                                    <Tooltip
                                        title="Variance = Rent Roll - Lease"
                                        arrow
                                        classes={{
                                            tooltip: classes.largeTooltip,
                                        }}
                                    >
                                        <IconButton size="small">
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            expandedInitial={true}
                        >
                            <Grid container spacing={2}>
                                {Object.keys(
                                    leaseAuditController.analysisData.rent_roll,
                                ).map((key) => {
                                    const rentRollValue = leaseAuditController
                                        .analysisData.rent_roll[key] as number
                                    const leaseValue = leaseAuditController
                                        .analysisData.lease[key] as number
                                    const variance = rentRollValue - leaseValue

                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={3}
                                            key={key}
                                        >
                                            <Card>
                                                <CardContent>
                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {key
                                                            .split('_')
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0,
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1,
                                                                    ),
                                                            )
                                                            .join(' ')}
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Rent Roll:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                rentRollValue,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Lease:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                leaseValue,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography>
                                                            Variance:
                                                        </Typography>
                                                        <Typography>
                                                            {currencyFormatterNoDecimals.format(
                                                                variance,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </SectionCard>
                        {/* Occupied Units */}
                        <SectionCard
                            title="Occupied Units"
                            expandedInitial={true}
                        >
                            {createUnitTypeChart()}
                        </SectionCard>
                    </>
                ) : (
                    <Container>
                        <Typography>No data available</Typography>
                    </Container>
                )}
            </Container>
        </Container>
    )
}
