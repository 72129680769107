import React, { useState } from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Button,
} from '@material-ui/core'

interface BulkAddUserFormProps {
    open: boolean
    workspaceCount: number
    onClose: () => void
    onConfirm: (email: string) => void
}

export const BulkAddUserForm = (props: BulkAddUserFormProps) => {
    const [email, setEmail] = useState('')

    const handleClose = () => {
        setEmail('')
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                Add user to {props.workspaceCount} workspaces?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter an email address to add
                </DialogContentText>
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        props.onConfirm(email)
                        handleClose()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
