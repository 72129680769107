import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Radio, RadioGroup, Theme, useTheme } from '@material-ui/core'
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Divider,
    Paper,
    Slide,
    InputAdornment,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Search } from '@material-ui/icons'

import { RootState } from '../../../store'
import { Container } from '../../../components'
import { NewInvItemForm } from './NewInvItemForm'
import { useUser } from '../../../hooks'
import { axiosInstance } from '../../../helpers'
import {
    FREQUENCY,
    INSPECTION,
    NONE,
    PREVIEW_INSPECTION_TYPE_STEP,
} from './InspectionConfigurationModal'
import {
    AreaConfig,
    IdBoolMap,
    InspectionType,
    InventoryConfig,
} from '../../../models'
import SelectedItems from './SelectedItems'

interface Props {
    inspectionTypeName: string
    invItemsMap: IdBoolMap
    SubHeaderStyle: React.CSSProperties
    nextStep: number
    areaConfigList: AreaConfig[]
    inspectionType?: InspectionType
    setInspectionTypeName: (inspectionTypeName: string) => void
    setNextStep: (nextStep: number) => void
    setInvItemsMap: (map: IdBoolMap) => void
    handleClose: () => void
    setTab: (tab: number) => void
    setMoveOutInspection?: (val: number) => void
}

export const ConfigureInspectionType = (props: Props) => {
    const {
        inspectionTypeName,
        invItemsMap,
        SubHeaderStyle,
        inspectionType,
        nextStep,
        areaConfigList,
        setNextStep,
        setMoveOutInspection,
        setInvItemsMap,
        setTab,
        setInspectionTypeName,
        handleClose,
    } = props

    const { workspaceUser } = useUser()
    const theme = useTheme()

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const [formPage, setFormPage] = useState(INSPECTION_TYPE_FORM)
    const [doValidate, setDoValidate] = useState(false)
    const [searchText, setSearchText] = useState('')

    const filteredInvConfigList = useMemo(
        () =>
            inventoryConfigList?.filter((invConfig) =>
                invConfig.name
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase()),
            ) ?? [],
        [inventoryConfigList, searchText],
    )

    const handleCheckboxClick = useCallback(
        (id: number) => {
            const tempMap = { ...invItemsMap }
            tempMap[id] = !tempMap[id]
            setInvItemsMap(tempMap)
        },
        [setInvItemsMap, invItemsMap],
    )

    const handleSelectAllClick = useCallback(() => {
        const newMap: IdBoolMap = {}
        const atLeastOneChecked = Object.values(invItemsMap).some(Boolean)
        Object.keys(invItemsMap).forEach((keyStr) => {
            const key = Number(keyStr)
            newMap[key] = !atLeastOneChecked
        })
        setInvItemsMap(newMap)
    }, [invItemsMap, setInvItemsMap])

    const handleInspectionTypeNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setInspectionTypeName(e.target.value)
        },
        [setInspectionTypeName],
    )

    const handleSetMoveInInspection = () => {
        const req = {
            body: {
                move_out_inspection_config: inspectionType?.id,
            },
        }
        const url = `company/apartment/${workspaceUser?.active_workspace.id}/`
        axiosInstance.patch(url, req.body).then((res) => {
            if (res.data.move_out_inspection_config) {
                if (setMoveOutInspection)
                    setMoveOutInspection(res.data.move_out_inspection_config)
                toast.success('Move in inspection type changed')
            } else {
                toast.error('Can not find inspection type')
            }
        })
    }

    useEffect(() => {
        if (inspectionType) {
            setInspectionTypeName(inspectionType.name)
        }
    }, [inspectionType])

    const invAreaMap = useMemo(() => {
        return areaConfigList.reduce((acc, areaConfig) => {
            areaConfig.inventory_area_configs?.forEach((iac) => {
                const invConfigId = iac.inventory_config.id
                if (!acc[invConfigId]) {
                    acc[invConfigId] = []
                }
                acc[invConfigId].push(areaConfig)
            })
            return acc
        }, {} as Record<number, AreaConfig[]>)
    }, [areaConfigList])

    return (
        <Container flex={1} direction="column">
            <Container alignItems="center">
                <Container
                    style={{ fontWeight: 600, fontSize: '20px', flex: 1 }}
                >
                    Step 1: {inspectionType ? 'Update' : 'Configure'} Inspection
                    Type
                </Container>
                <Tooltip title="Close">
                    <IconButton onClick={handleClose}>
                        <CloseIcon
                            fontSize="inherit"
                            style={{ color: theme.palette.darkGreen.main }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Container style={{ marginBottom: theme.spacing(0.5) }}>
                {formPage !== INSPECTION_TYPE_FORM && (
                    <Container>
                        <Container style={{ marginRight: theme.spacing(1) }}>
                            Inspection Name & Choosing Inventory
                        </Container>
                        /
                        <Container
                            style={{
                                margin: theme.spacing(0, 1, 0, 1),
                                color:
                                    formPage === ADD_NEW_ITEM_FORM
                                        ? theme.palette.primary.dark
                                        : '',
                            }}
                        >
                            {` `}Adding An Item
                        </Container>
                    </Container>
                )}
                {formPage === ADD_NEW_AREA_FORM && (
                    <Container>
                        <Container>/</Container>
                        <Container
                            style={{
                                marginLeft: theme.spacing(1),
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Create New Area
                        </Container>
                    </Container>
                )}
                {formPage === ADD_NEW_STATUS_GROUP_FORM && (
                    <Container>
                        <Container>/</Container>
                        <Container
                            style={{
                                marginLeft: theme.spacing(1),
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Create New Status Group
                        </Container>
                    </Container>
                )}
            </Container>

            <Divider />

            {formPage === INSPECTION_TYPE_FORM ? (
                <Container direction="column" style={{ flex: 1 }}>
                    <Container
                        direction="column"
                        style={{
                            maxHeight: 'calc(100vh - 250px)',
                            overflowY: 'auto',
                            paddingRight: theme.spacing(1),
                        }}
                    >
                        <Container
                            style={{ ...SubHeaderStyle, fontSize: '20px' }}
                        >
                            Inspection Name & Choosing Inventory
                        </Container>
                        <Container
                            style={{
                                fontSize: '13px',
                                color: theme.palette.darkGreen.main,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            You have started to configure your inspection type.
                            In order to do so, please name your inspection type
                            and choose your inventory.
                        </Container>
                        <Container style={{ ...SubHeaderStyle }}>
                            Name Your Inspection Type:
                        </Container>
                        <Container style={{ marginBottom: theme.spacing(2) }}>
                            <TextField
                                error={doValidate && inspectionTypeName === ''}
                                helperText={
                                    doValidate && inspectionTypeName === ''
                                        ? 'Required'
                                        : ''
                                }
                                variant="outlined"
                                value={inspectionTypeName}
                                onChange={handleInspectionTypeNameChange}
                                style={{ width: '100%' }}
                                size="small"
                                label="Inspection Type Name"
                            />
                        </Container>
                        <Container style={{ ...SubHeaderStyle }}>
                            Items You Selected:
                        </Container>
                        <SelectedItems invItemsMap={invItemsMap} />
                        <TextField
                            variant="standard"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginRight: theme.spacing(4) }}
                        />
                        <Container
                            direction="column"
                            style={{
                                border: `1px solid ${theme.palette.lightGrey.dark}`,
                                borderRadius: '5px',
                                marginTop: theme.spacing(1),
                            }}
                        >
                            <Container
                                direction="column"
                                style={{
                                    maxHeight: 'calc(100vh - 700px)',
                                    minHeight: '200px',
                                    overflowY: 'auto',
                                }}
                            >
                                <Container
                                    style={{
                                        marginLeft: theme.spacing(0.5),
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        checked={Object.values(
                                            invItemsMap,
                                        ).every(Boolean)}
                                        style={{
                                            color: theme.palette.primary.dark,
                                        }}
                                        indeterminate={
                                            Object.values(invItemsMap).some(
                                                Boolean,
                                            ) &&
                                            !Object.values(invItemsMap).every(
                                                Boolean,
                                            )
                                        }
                                        onChange={handleSelectAllClick}
                                    />
                                    <Container>
                                        {Object.values(invItemsMap).some(
                                            Boolean,
                                        )
                                            ? 'Remove All'
                                            : 'Select All'}
                                    </Container>
                                </Container>

                                {filteredInvConfigList?.map((invC) => (
                                    <InventoryConfigCheckbox
                                        key={invC.id}
                                        invC={invC}
                                        invItemsMap={invItemsMap}
                                        handleCheckboxClick={
                                            handleCheckboxClick
                                        }
                                        theme={theme}
                                        invAreaMap={invAreaMap}
                                    />
                                ))}
                            </Container>
                            <Container
                                style={{
                                    borderTop: `1px solid ${theme.palette.lightGrey.dark}`,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    style={{
                                        margin: theme.spacing(1, 0, 1, 2),
                                        backgroundColor: '#008C85',
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        setFormPage(ADD_NEW_ITEM_FORM)
                                    }
                                >
                                    + Add New Item
                                </Button>
                            </Container>
                        </Container>
                        <Container
                            style={{
                                ...SubHeaderStyle,
                                flexDirection: 'column',
                                marginTop: theme.spacing(1),
                            }}
                        >
                            <Container>
                                Would you like to create an Inspection or an
                                Inspection Frequency?
                            </Container>
                            <RadioGroup value={nextStep}>
                                <FormControlLabel
                                    value="inspection"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Create an Inspection"
                                    checked={nextStep === INSPECTION}
                                    onClick={() => setNextStep(INSPECTION)}
                                />
                                <FormControlLabel
                                    value="frequency"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Create an Inspection Frequency"
                                    checked={nextStep === FREQUENCY}
                                    onClick={() => setNextStep(FREQUENCY)}
                                />
                                <FormControlLabel
                                    value="none"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label={
                                        inspectionType
                                            ? 'Update Inspection Type Only'
                                            : 'Create Inspection Type Only'
                                    }
                                    checked={nextStep === NONE}
                                    onClick={() => setNextStep(NONE)}
                                />
                            </RadioGroup>
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <Container>
                        {inspectionType && (
                            <Container style={{ marginTop: theme.spacing(2) }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleSetMoveInInspection}
                                >
                                    Set Move In Inspection
                                </Button>
                            </Container>
                        )}
                        <Container style={{ flex: 1 }} />
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            style={{
                                textTransform: 'none',
                                margin: theme.spacing(1, 0, 1, 2),
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(1, 0, 1, 2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (inspectionTypeName === '') {
                                    setDoValidate(true)
                                } else {
                                    setDoValidate(false)
                                    setTab(PREVIEW_INSPECTION_TYPE_STEP)
                                }
                            }}
                        >
                            Preview
                        </Button>
                    </Container>
                </Container>
            ) : (
                <Slide direction="left" in={formPage !== INSPECTION_TYPE_FORM}>
                    <Paper elevation={0} style={{ flex: 1 }}>
                        <NewInvItemForm
                            subHeaderStyle={SubHeaderStyle}
                            setFormPage={setFormPage}
                            invItemsMap={invItemsMap}
                            setInvItemsMap={setInvItemsMap}
                            formPage={formPage}
                        />
                    </Paper>
                </Slide>
            )}
        </Container>
    )
}

const InventoryConfigCheckbox = ({
    invC,
    invItemsMap,
    handleCheckboxClick,
    theme,
    invAreaMap,
}: {
    invC: InventoryConfig
    invItemsMap: IdBoolMap
    handleCheckboxClick: (id: number) => void
    theme: Theme
    invAreaMap: Record<number, AreaConfig[]>
}) => {
    const areaConfigs = invAreaMap[invC.id] ?? []
    const tooltipTitle = areaConfigs.map((ac) => ac.name).join(', ')

    return (
        <FormControlLabel
            key={`INV_CONF_${invC.id}_CHECK_INS_NEW_INSPECTION_TYPE`}
            control={
                <Checkbox
                    checked={invItemsMap[invC.id] ?? false}
                    style={{ color: theme.palette.primary.dark }}
                    onClick={() => handleCheckboxClick(invC.id)}
                />
            }
            label={
                <Container>
                    {invC.name}
                    <Tooltip title={tooltipTitle}>
                        <span style={{ marginLeft: theme.spacing(0.5) }}>
                            ({invC.area_config_count})
                        </span>
                    </Tooltip>
                </Container>
            }
            labelPlacement="end"
            style={{ fontWeight: 'bold', marginLeft: theme.spacing(0.5) }}
        />
    )
}

export const INSPECTION_TYPE_FORM = 0
export const ADD_NEW_ITEM_FORM = 1
export const ADD_NEW_AREA_FORM = 2
export const ADD_NEW_STATUS_GROUP_FORM = 3
