import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosInstance } from '../../../../helpers'
import { AxiosResponse } from 'axios'
import { FolderMinified } from '../../../../models'

export const usePropertyFolders = (id: string) => {
    const queryClient = useQueryClient()
    const queryKey = ['property', id, 'folders']

    const { data, isLoading, error } = useQuery<
        AxiosResponse<FolderMinified[]>,
        Error
    >({
        queryKey,
        queryFn: () =>
            axiosInstance.get(`/account-manager/properties/${id}/folders/`),
    })

    const createFolder = useMutation(
        async ({
            name,
            parentId,
        }: {
            name: string
            parentId: string | null
        }) => {
            const response = await axiosInstance.post(
                `/account-manager/properties/${id}/folders/`,
                {
                    name,
                    parent_id: parentId,
                },
            )
            return response.data
        },
        {
            onSuccess: () => {
                // Invalidate and refetch
                queryClient.invalidateQueries({ queryKey })
            },
        },
    )

    const moveFolder = useMutation({
        mutationFn: ({
            folderId,
            newParentId,
        }: {
            folderId: string
            newParentId: string
        }) => {
            let nullableParentId: string | null = newParentId
            if (newParentId === 'root') {
                nullableParentId = null
            }
            return axiosInstance.post(
                `/account-manager/properties/${id}/folders/${folderId}/move/`,
                { new_parent_id: nullableParentId },
            )
        },
        onMutate: async ({ folderId, newParentId }) => {
            await queryClient.cancelQueries({
                queryKey,
            })
            const previousFolders = queryClient.getQueryData(queryKey)

            queryClient.setQueryData<AxiosResponse<FolderMinified[]>>(
                queryKey,
                (old) => {
                    if (!old?.data) return old
                    return {
                        ...old,
                        data: old.data.map((folder) =>
                            folder.id.toString() === folderId
                                ? { ...folder, parent: Number(newParentId) }
                                : folder,
                        ),
                    }
                },
            )

            return { previousFolders }
        },
        onError: (err, variables, context) => {
            const safeContext = context as {
                previousFolders?: FolderMinified[]
            }
            const previousFolders = safeContext?.previousFolders
            if (previousFolders) {
                queryClient.setQueryData(queryKey, previousFolders)
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    const deleteFolder = useMutation({
        mutationFn: (folderId: string) =>
            axiosInstance.delete(
                `/account-manager/properties/${id}/folders/${folderId}/`,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    const renameFolder = useMutation({
        mutationFn: async ({
            folderId,
            newName,
        }: {
            folderId: string
            newName: string
        }) => {
            const response = await axiosInstance.post(
                `/account-manager/properties/${id}/folders/${folderId}/rename/`,
                { name: newName },
            )
            return response.data
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    return {
        folders: data?.data,
        isLoading,
        error,
        createFolder,
        moveFolder,
        deleteFolder,
        renameFolder,
    }
}
