import React, { useEffect } from 'react'
import {
    ListItemText,
    List,
    Checkbox,
    ListItem,
    ListItemIcon,
    makeStyles,
    Theme,
    Typography,
    Tooltip,
    Box,
    Accordion,
    AccordionSummary,
} from '@material-ui/core'
import { Container } from '../../../../../components/Container'
import clsx from 'clsx'
import {
    AGENT_DRAWER_WIDTH,
    LORI_CHANNEL_HEADER_HEIGHT,
    WALKTHROUGH_PANEL_HEIGHT,
} from '../../../../constants'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoIcon from '@material-ui/icons/Info'
import { LoriController } from '../../../../../hooks/useLori'
import { Task } from '../../types'

const useStyles = makeStyles((theme: Theme) => ({
    WalkThroughPanel: {
        position: 'absolute',
        padding: theme.spacing(1),
        top: LORI_CHANNEL_HEADER_HEIGHT,
        left: 0,
        zIndex: theme.zIndex.drawer + 3,
        width: AGENT_DRAWER_WIDTH,
        height: WALKTHROUGH_PANEL_HEIGHT,
        backgroundColor: theme.palette.background.paper,
        borderTop: `2px solid ${theme.palette.divider}`,
        transform: 'translateY(-100%)',
        transition: 'transform 0.9s cubic-bezier(0.4, 0, 0.2, 1)',
        willChange: 'transform',
        visibility: 'hidden',
        flexDirection: 'column',
        maxHeight: WALKTHROUGH_PANEL_HEIGHT - 60,
    },
    Open: {
        transform: 'translateY(0)',
        visibility: 'visible',
    },
    checklist: {
        width: '100%',
        overflowY: 'scroll',
        maxHeight: WALKTHROUGH_PANEL_HEIGHT - 70,
    },
    listItem: {
        alignItems: 'center',
        marginLeft: theme.spacing(1.5),
        marginTop: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    menuItem: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    accordionSummary: {
        minHeight: '32px !important',
        height: '32px',
        '& .MuiAccordionSummary-content': {
            margin: '0 !important',
        },
        padding: '0 12px',
    },
}))

interface Props {
    theme: Theme
    LoriController: LoriController
    handleSendMessage: (message: string) => void
}

interface TaskGroupProps {
    tasks: Task[]
    checked: number[]
    handleToggle: (taskId: number) => () => void
    handleTaskClick: (taskName: string) => void
    classes: ReturnType<typeof useStyles>
    theme: Theme
}

const TaskGroup: React.FC<TaskGroupProps> = ({
    tasks,
    checked,
    handleToggle,
    handleTaskClick,
    classes,
    theme,
}) => (
    <Box
        mb={2}
        style={{
            maxHeight: 123,
            overflowY: 'scroll',
        }}
    >
        <List dense>
            {tasks.map((task) => (
                <Container key={task.id} className={classes.listItem}>
                    <ListItemIcon
                        style={{
                            minWidth: 30,
                            display: 'flex',
                        }}
                    >
                        <Checkbox
                            edge="start"
                            checked={checked.indexOf(task.id) !== -1}
                            tabIndex={-1}
                            onClick={handleToggle(task.id)}
                            disableRipple
                        />
                    </ListItemIcon>
                    <Tooltip title="Help me do this">
                        <InfoIcon
                        onClick={() => handleTaskClick(task.planned_task.name)}
                            style={{
                                marginRight: theme.spacing(1),
                            }}
                        />
                    </Tooltip>

                    <ListItemText
                        primary={task.planned_task.name}
                        onClick={() => handleTaskClick(task.planned_task.name)}
                    />
                </Container>
            ))}
        </List>
    </Box>
)

const formatDeadline = (startDate: string, daysPrior: number): string => {
    const deadline = new Date(startDate)
    deadline.setDate(deadline.getDate() - daysPrior)
    return deadline.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    })
}

export const WalkThroughPanel = ({
    theme,
    LoriController,
    handleSendMessage,
}: Props) => {
    const classes = useStyles(theme)
    const [checked, setChecked] = React.useState<number[]>([])
    const [expanded, setExpanded] = React.useState<string | false>('sixty')

    const projectPlan = LoriController.projectPlan
    if (!projectPlan || !projectPlan.tasks) return null

    const handleToggle = (taskId: number) => () => {
        const currentIndex = checked.indexOf(taskId)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(taskId)
            LoriController.updateTask(taskId, true)
        } else {
            newChecked.splice(currentIndex, 1)
            LoriController.updateTask(taskId, false)
        }

        setChecked(newChecked)
    }

    const handleTaskClick = (taskName: string) => {
        console.log('clicked', taskName)
        handleSendMessage(`How do I ${taskName}`)
    }

    const groupedTasks: Record<'sixty' | 'thirty' | 'fourteen', Task[]> = {
        sixty: projectPlan.tasks.filter((t) => t.complete_days_prior === 60),
        thirty: projectPlan.tasks.filter((t) => t.complete_days_prior === 30),
        fourteen: projectPlan.tasks.filter((t) => t.complete_days_prior === 14),
    }

    const getCompletedTasksCount = (tasks: Task[]) => {
        return tasks.filter((task) => checked.includes(task.id)).length
    }

    const handleAccordionChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Container
            className={clsx(
                classes.WalkThroughPanel,
                LoriController.walkThroughPanelOpen && classes.Open,
            )}
        >
            {/* Header */}
            <Container
                style={{
                    display: 'flex',
                    paddingTop: theme.spacing(1),
                    paddingLeft: theme.spacing(1),
                    flexDirection: 'column',
                }}
            >
                <Typography
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        alignSelf: 'center',
                    }}
                >
                    {projectPlan.project.name} Project Plan
                </Typography>
            </Container>
            {/* Task List */}
            <Container
                style={{
                    flexDirection: 'column',
                }}
            >
                <Accordion
                    expanded={expanded === 'sixty'}
                    onChange={handleAccordionChange('sixty')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.sixty)}/
                            {groupedTasks.sixty.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 60)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.sixty.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.sixty}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            classes={classes}
                            theme={theme}
                        />
                    )}
                </Accordion>
                <Accordion
                    expanded={expanded === 'thirty'}
                    onChange={handleAccordionChange('thirty')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.thirty)}/
                            {groupedTasks.thirty.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 30)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.thirty.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.thirty}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            classes={classes}
                            theme={theme}
                        />
                    )}
                </Accordion>
                <Accordion
                    expanded={expanded === 'fourteen'}
                    onChange={handleAccordionChange('fourteen')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                    >
                        <Typography
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {getCompletedTasksCount(groupedTasks.fourteen)}/
                            {groupedTasks.fourteen.length} - Due{' '}
                            {formatDeadline(projectPlan.project.start_date, 14)}
                        </Typography>
                    </AccordionSummary>
                    {groupedTasks.fourteen.length > 0 && (
                        <TaskGroup
                            tasks={groupedTasks.fourteen}
                            checked={checked}
                            handleToggle={handleToggle}
                            handleTaskClick={handleTaskClick}
                            classes={classes}
                            theme={theme}
                        />
                    )}
                </Accordion>
            </Container>
        </Container>
    )
}
