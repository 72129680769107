import React, { useEffect, useMemo, useState } from 'react'

import { Container } from '../../components'
import { Tab, Tabs, useTheme } from '@material-ui/core'
import { rfpApi, workorderContext } from '../../contexts'
import { RFPStatus, RFPType, WorkorderType } from '../../models'
import { ActionItemList } from './ActionItemList'
import { toast } from 'react-toastify'

import {
    ActionItem,
    ActionItemStatus,
    Frequency,
    IdBoolMap,
    ModelMap,
    ServiceRequest,
    ServiceRequestStatus,
} from '../../models'
import TuneIcon from '@material-ui/icons/Tune'
import { ActionItemFilterDrawer } from './ActionItemFilterDrawer'
import { ActionItemFilterChips } from './ActionItemFilterChips'
import { ActionItemDrawer } from './ActionItemDrawer'
// import { NewActionItemFrequencyTable } from './NewActionItemFrequencyTable'
import { SearchField } from '../../components/SearchField'
import { ReactComponent as EZTurnLogo } from '../../assets/Logo.svg'
import { EZNowSelectionDrawer } from '../EZNow/EZNowSelectionDrawer'
import {
    useAptConfig,
    useCompany,
    _useFinderSelection,
    useRootInfrastructure,
    useService,
    useUser,
    useAppDispatch,
} from '../../hooks'
import { FrequencyList } from './FrequencyList'
import { ActionItemTriggerList } from './ActionItemTriggerList'
import { ApartmentEZNowList } from '../EZNow/ApartmentEZNowList'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    getInfrastructure,
    setWorkSpaceUser,
} from '../../store'

export interface ActionItemFilters {
    services: IdBoolMap
    priority: boolean
    statuses: ActionItemStatus[]
    serviceRequest: string
}

export const ActionItemHome = () => {
    const [tab, setTab] = useState(ACTION_ITEM_TAB)

    const theme = useTheme()

    const finderCreateSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        getWorkorderList,
        getFrequencyList,
        frequencyList,
        getInspectionTriggers,
        triggerList,
    } = workorderContext()

    const { getRFPList, rfpList, setSelectedRFP, selectedRFP } = rfpApi()

    const { workspaceUser, userList, actions, rootUser } = useUser()
    const { getUserList } = actions

    const { tree } = useRootInfrastructure(true)

    const dispatch = useAppDispatch()

    const {
        getAreaConfigMap,
        areaConfigList,
        getUnitConfigMap,
        unitConfigList,
        areaStatusConfigList,
        inventoryConfigList,
        customStatusList,
    } = useAptConfig({
        inventoryConfigList: true,
        customStatusList: true,
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })

    // Build required Model maps
    const areaConfigMap = useMemo(() => {
        return getAreaConfigMap()
    }, [areaConfigList])

    const unitConfigMap = useMemo(() => {
        return getUnitConfigMap()
    }, [unitConfigList])

    const queryParams = new URLSearchParams(location.search)
    const apartment = queryParams.get('apartment')
    const workorderType = queryParams.get('workorder_type')

    useEffect(() => {
        if (apartment) {
            const apartmentId = parseInt(apartment)
            const newWsUser = rootUser?.workspaces.find(
                (wsUser) => wsUser.active_workspace.id === apartmentId,
            )

            if (newWsUser) {
                dispatch(setWorkSpaceUser(newWsUser))
                dispatch(
                    getInfrastructure({
                        apartmentId: newWsUser.active_workspace.id,
                    }),
                )
            }

            if (workorderType === WorkorderType.SERVICE_REQUEST) {
                setTab(SERVICE_REQUEST_TAB)
            }
        }
    }, [apartment])

    useEffect(() => {
        getRFPList({
            params: {
                type: RFPType.EZNOW,
            },
        })

        getUserList({ params: { my_team: true } })

        getWorkorderList({
            params: {
                workorder_type:
                    workorderType === WorkorderType.SERVICE_REQUEST
                        ? WorkorderType.SERVICE_REQUEST
                        : WorkorderType.ACTION_ITEM,
            },
        }).catch((e) => {
            toast.error(`ERROR ${e}`)
        })

        getFrequencyList()
        getInspectionTriggers()
    }, [])

    return (
        <Container
            style={{
                flexDirection: 'column',
                height: 'calc(100vh - 104px)',
                flex: 1,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                }}
            >
                <Tabs
                    value={tab}
                    onChange={(_, v) => {
                        setTab(v)
                        if (v === ACTION_ITEM_TAB) {
                            getWorkorderList({
                                params: {
                                    workorder_type: WorkorderType.ACTION_ITEM,
                                },
                            }).catch((e) => {
                                toast.error(`ERROR ${e}`)
                            })
                        } else if (v === SERVICE_REQUEST_TAB) {
                            getWorkorderList({
                                params: {
                                    workorder_type:
                                        WorkorderType.SERVICE_REQUEST,
                                },
                            }).catch((e) => {
                                toast.error(`ERROR ${e}`)
                            })
                        }
                    }}
                >
                    <Tab label="Action Items" value={ACTION_ITEM_TAB} />
                    <Tab label="Service Requests" value={SERVICE_REQUEST_TAB} />
                    <Tab label="Frequencies" value={FREQUENCY_TAB} />
                    <Tab label="Triggers" value={TRIGGER_TAB} />
                    <Tab label="EZNow Requests" value={EZNOW_TAB} />
                </Tabs>
            </Container>
            {tab === ACTION_ITEM_TAB && (
                <ActionItemList
                    root={tree.root}
                    finderSelection={finderCreateSelection}
                    areaConfigMap={areaConfigMap}
                    unitWorkorderMap={{}}
                    areaStatusTagMap={{}}
                    serviceList={serviceList}
                    vendorList={vendorList}
                    unitConfigMap={unitConfigMap}
                    unitConfigList={unitConfigList}
                    userList={userList}
                    areaStatusConfigs={areaStatusConfigList ?? []}
                    workspaceUser={workspaceUser}
                    theme={theme}
                />
            )}

            {tab === SERVICE_REQUEST_TAB && (
                <ActionItemList
                    root={tree.root}
                    finderSelection={finderCreateSelection}
                    areaConfigMap={areaConfigMap}
                    unitWorkorderMap={{}}
                    areaStatusTagMap={{}}
                    serviceList={serviceList}
                    vendorList={vendorList}
                    unitConfigMap={unitConfigMap}
                    unitConfigList={unitConfigList}
                    userList={userList}
                    areaStatusConfigs={areaStatusConfigList ?? []}
                    workspaceUser={workspaceUser}
                    theme={theme}
                    isServiceRequestList
                />
            )}

            {tab === FREQUENCY_TAB && (
                <FrequencyList
                    frequencyList={frequencyList ?? []}
                    serviceList={serviceList}
                    userList={userList}
                    vendorList={vendorList}
                    root={tree.root}
                    areaConfigMap={areaConfigMap}
                />
            )}

            {tab === TRIGGER_TAB && (
                <ActionItemTriggerList
                    theme={theme}
                    triggerList={triggerList ?? []}
                    serviceList={serviceList}
                    userList={userList}
                    vendorList={vendorList}
                    root={tree.root}
                    areaConfigMap={areaConfigMap}
                    customStatusList={customStatusList}
                    inventoryConfigList={inventoryConfigList}
                />
            )}

            {tab === EZNOW_TAB && (
                <ApartmentEZNowList
                    ezNowRequestList={rfpList}
                    selectedRFP={selectedRFP}
                    setSelectedRFP={setSelectedRFP}
                />
            )}
        </Container>
    )
}

const ACTION_ITEM_TAB = 0
const SERVICE_REQUEST_TAB = 1
const FREQUENCY_TAB = 2
const TRIGGER_TAB = 3
const EZNOW_TAB = 4
