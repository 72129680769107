import React from 'react'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import { SearchField } from '../../../../components/SearchField'
import { SEARCH_HEADER_HEIGHT } from './constants'

interface SearchState {
    text: string
    userEmail: string
}

interface SearchHeaderProps {
    searchState: SearchState
    selectedCount: number
    onSearchChange: (
        field: keyof SearchState,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void
    onUserSearch: () => void
    onClearUserSearch: () => void
    onBulkAdd: () => void
    onDeselectAll: () => void
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: SEARCH_HEADER_HEIGHT,
        maxHeight: SEARCH_HEADER_HEIGHT,
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flex: 1,
        alignItems: 'center',
    },
    searchField: {
        minWidth: 200,
    },
    selectedCount: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}))

export const SearchHeader: React.FC<SearchHeaderProps> = ({
    searchState,
    selectedCount,
    onSearchChange,
    onUserSearch,
    onClearUserSearch,
    onBulkAdd,
    onDeselectAll,
}) => {
    const classes = useStyles()

    return (
        <Box className={classes.header}>
            <div className={classes.searchContainer}>
                <SearchField
                    className={classes.searchField}
                    placeholder="Search properties..."
                    value={searchState.text}
                    onChange={onSearchChange('text')}
                />
                <SearchField
                    className={classes.searchField}
                    placeholder="Search by user email..."
                    value={searchState.userEmail}
                    onChange={onSearchChange('userEmail')}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onUserSearch}
                    disabled={!searchState.userEmail}
                >
                    Search
                </Button>
                {searchState.userEmail && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onClearUserSearch}
                    >
                        Clear
                    </Button>
                )}
            </div>

            <div className={classes.selectedCount}>
                {selectedCount > 0 && (
                    <>
                        <Typography>
                            {selectedCount} workspace
                            {selectedCount !== 1 && 's'} selected
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onBulkAdd}
                        >
                            Bulk Add User
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onDeselectAll}
                        >
                            Deselect All
                        </Button>
                    </>
                )}
            </div>
        </Box>
    )
}
