import { useState } from 'react'

import { toast } from 'react-toastify'

import { SupportTicket, SupportTicketStatus } from '../models'
import { axiosInstance } from '../helpers'

export const useSupportTicket = () => {
    const [supportTicketList, setSupportTicketList] = useState<SupportTicket[]>(
        [],
    )

    const createSupportTicket = async (formData: FormData) => {
        axiosInstance
            .post('support-ticket/', formData)
            .then((res) => {
                toast.success('Support ticket created successfully')
            })
            .catch((err) => {
                toast.error('Failed to create support ticket')
            })
    }

    const getSupportTicketList = async () => {
        try {
            const response = await axiosInstance.get('support-ticket/')
            const supportTicketList: SupportTicket[] = response.data
            setSupportTicketList(supportTicketList)
            return supportTicketList
        } catch (error) {
            toast.error('Failed to get support ticket list')
            return Promise.reject(error)
        }
    }

    const updateSupportTicket = async (
        supportTicket: SupportTicket,
        status: SupportTicketStatus,
    ) => {
        try {
            const response = await axiosInstance.patch(
                `support-ticket/${supportTicket.id}/`,
                {
                    status: status,
                },
            )
            const updatedSupportTicket: SupportTicket = response.data
            setSupportTicketList(
                supportTicketList.map((ticket) =>
                    ticket.id === updatedSupportTicket.id
                        ? updatedSupportTicket
                        : ticket,
                ),
            )
            return updatedSupportTicket
        } catch (error) {
            toast.error('Failed to update support ticket')
            return Promise.reject(error)
        }
    }

    return {
        supportTicketList,
        getSupportTicketList,
        updateSupportTicket,
        createSupportTicket,
    }
}
