import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@material-ui/core'
import {
    MoreVert as MoreIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import { AppSelector, AvatarCircle, Container } from '../../../components'
import { User } from '../../../models'
import { Routes } from '../../config/routes'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../../hooks'
import OrganizationSelection from './OrganizationSelection'
import OrganizationSideBar from './OrganizationSideBar'

interface OrganizationLayoutProps {
    children: React.ReactNode
    user: User
}

export const OrganizationLayout: React.FC<OrganizationLayoutProps> = ({
    children,
    user,
}) => {
    const theme = useTheme()
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
            },

            content: {
                flexGrow: 1,
                width: `calc(100vw - ${DRAWER_WIDTH}px)`,
            },
            topBar: {
                backgroundColor: theme.palette.primary.dark,
                minHeight: '56px',
                maxHeight: '56px',
                width: '100%',
                color: theme.palette.white.main,
                alignItems: 'center',
            },
            sectionDesktop: {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                },
            },
            sectionMobile: {
                display: 'flex',
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            profilePic: {
                display: 'flex',
            },
        }),
    )
    const classes = useStyles()

    const history = useHistory()

    const { workspaceUser, organization, rootUser, actions } = useUser()

    const { logout } = actions

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const [
        mobileMoreAnchorEl,
        setMobileMoreAnchorEl,
    ] = React.useState<null | HTMLElement>(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={() => {
                    logout()
                    history.push(Routes.public.login)
                }}
            >
                Logout
            </MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AvatarCircle
                        text={rootUser?.name}
                        initials
                        image={rootUser?.profile_picture}
                        styles={{
                            container: {
                                maxHeight: '30px',
                                maxWidth: '30px',
                                minHeight: 'auto',
                                minWidth: 'auto',
                                fontSize: '1rem',
                                padding: '16px',
                            },
                        }}
                    />
                    {/* <AccountCircle /> */}
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    return (
        <Container style={{ height: '100vh', width: '100vw' }}>
            {/* Left Side Bar */}
            <OrganizationSideBar open drawerWidth={DRAWER_WIDTH} />

            {/* Right Panel, top bar and content */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Container
                    className={classes.topBar}
                    style={{ padding: theme.spacing(2) }}
                >
                    <OrganizationSelection
                        user={user}
                        selectedOrganization={organization}
                    />

                    <Container style={{ flex: 1 }} />

                    <AppSelector workspaceUser={workspaceUser} theme={theme} />

                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Typography style={{ marginRight: '5px' }}>
                                {rootUser?.name}
                            </Typography>
                            <AvatarCircle
                                text={rootUser?.name}
                                initials
                                image={rootUser?.profile_picture}
                                styles={{
                                    container: {
                                        maxHeight: 45,
                                        maxWidth: 45,
                                        minHeight: 'auto',
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                    },
                                }}
                            />
                            <ExpandMoreIcon style={{ marginLeft: '5px' }} />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Container>

                {renderMobileMenu}
                {renderMenu}

                {/* Content */}
                <main className={classes.content} style={{ padding: '24px' }}>
                    {children}
                </main>
            </Container>
        </Container>
    )
}

const DRAWER_WIDTH = 136
