import { ActionItemStatus, IdBoolMap } from '../../models'

export interface ActionItemFilters {
    services: IdBoolMap
    priority: boolean
    statuses: ActionItemStatus[]
    serviceRequest: string
}

export enum AiDrawerId {
    frequncy,
    trigger,
    actionItem,
    message,
    editActionItem,
    unitNotes,
    serviceRequest,
}

export interface AiFormState {
    createFormOpen: boolean
    filterFormOpen: boolean
    detailFormOpen: {
        index: number
        id: number
        drawerId: null | AiDrawerId
    }
}

export const closedAiFormState: AiFormState = {
    createFormOpen: false,
    filterFormOpen: false,
    detailFormOpen: {
        index: -1,
        id: -1,
        drawerId: null,
    },
}
