import React, { useState } from 'react'
import {
    Button,
    TextField,
    Popover,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Typography,
} from '@material-ui/core'
import { Container } from '../../Container'
import { useTheme } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import { LoriController } from '../../../hooks/useLori'

interface AddVideoProps {
    anchorEl: HTMLButtonElement | null
    handleClose: () => void
    setUrl: (url: string) => void
    setCategory: (category: string) => void
    url: string | undefined
    category: string | undefined
    LoriController: LoriController
}

export const AddVideo = (props: AddVideoProps) => {
    const {
        anchorEl,
        handleClose,
        setUrl,
        setCategory,
        url,
        category,
        LoriController,
    } = props

    const theme = useTheme()
    const handleSave = () => {
        LoriController.addVideo(url || '', category || '')
        handleClose()
    }

    const handleAddCategory = () => {
        LoriController.addCategory(newCategory)
        setCategory(newCategory)
        setNewCategory('')
    }

    const [newCategory, setNewCategory] = useState<string>('')

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Container
                style={{
                    padding: theme.spacing(2),
                    minWidth: 700,
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h6">Add Video</Typography>
                <TextField
                    label="Video URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: theme.spacing(1) }}
                />
                <Container
                    style={{
                        flexDirection: 'row',
                        flex: 1,
                        marginTop: theme.spacing(2),
                    }}
                >
                    <FormControl
                        variant="outlined"
                        style={{
                            minWidth: 300,
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        <InputLabel id={`category-label`}>Category</InputLabel>
                        <Select
                            labelId={`category-label`}
                            id={`category-select`}
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value as string)
                            }}
                            label="Category"
                            style={{ paddingLeft: theme.spacing(1) }}
                        >
                            {LoriController.categories.map((category) => (
                                <MenuItem
                                    key={`CATEGORY_SELECT__${category}`}
                                    value={category}
                                >
                                    <span>{category}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Add a new Category..."
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        variant="outlined"
                        fullWidth
                        style={{ marginLeft: theme.spacing(1) }}
                    />
                    <Tooltip title="Save new Category">
                        <IconButton color="primary" onClick={handleAddCategory}>
                            <DoneIcon />
                        </IconButton>
                    </Tooltip>
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Container>
            </Container>
        </Popover>
    )
}
