import { useState } from 'react'
import { axiosInstance } from '../../helpers'
import { GetWorkOrderListRequest } from './workorder'
import { WorkorderResponse } from '../../store'
import { BaseWorkorder, WorkorderStatus } from '../../models'

export const useWorkorderApi = () => {
    const [isLoading, setIsLoading] = useState(false)

    const getWorkorderList = async (request: GetWorkOrderListRequest) => {
        setIsLoading(true)

        try {
            const res = await axiosInstance.get('workorder/', request)
            const workorderRes: WorkorderResponse[] = res.data
            setIsLoading(false)
            return workorderRes
        } catch (e) {
            setIsLoading(false)
            return Promise.reject(e)
        }
    }

    const transitionWorkorder = async (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
    ): Promise<WorkorderResponse> => {
        return new Promise((resolve, reject) => {
            const url = `workorder/${workorder.id}/transition/`

            setIsLoading(true)

            axiosInstance
                .post(url, { status: status })
                .then((res) => {
                    const updatedWorkorder = res.data
                    resolve(updatedWorkorder)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setIsLoading(true)
                })
        })
    }

    const getServiceRequestChannel = async (workorder: WorkorderResponse) => {
        try {
            const res = await axiosInstance.post(
                `service-request/apartment_service_request/${workorder?.service_request?.id}/get_service_request_channel/`,
            )
            const channelId: number = res.data['id']
            return channelId
        } catch (e) {
            return Promise.reject(e)
        }
    }

    return {
        getWorkorderList,
        transitionWorkorder,
        isLoading,
        getServiceRequestChannel,
    }
}
