import React, { useState } from 'react'

import { Tooltip } from '@material-ui/core'
import { BaseWorkorder, Schedule } from '../../../models'
import { calculateTotalInvoiceForecast, formatTotals } from '../services'
import {
    LineItemTotals,
    Totals,
} from '../../../containers/Scheduler_v2/Modals/BudgetModal'
import { BaseProps } from '../../TemplateScheduleGantt/types'
import { useSchedule } from '../../../hooks'
import { UpdateScheduleRequest } from '../../../store'
import InfoIcon from '@material-ui/icons/Info'
import { Container } from '../../../components'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { HeaderValue, MoneyInputValue, MoneyValue } from '../styles'
import NumberFormat from 'react-number-format'

interface Props extends BaseProps {
    workorders: BaseWorkorder[]
    totals: Totals | undefined
    lineItemTotals: LineItemTotals | undefined
    scheduleDetail: Schedule | undefined
    portfolio: boolean
}

export const SummaryTable = (props: Props) => {
    const {
        theme,
        scheduleController,
        workorders,
        totals,
        scheduleDetail,
        lineItemTotals,
        portfolio,
    } = props

    const totalInvoiceForecast = calculateTotalInvoiceForecast(workorders)

    const { updateSchedule } = useSchedule()
    const [baselineBudget, setBaselineBudget] = useState(
        scheduleDetail?.baseline_budget || 0,
    )

    const req: UpdateScheduleRequest = {
        scheduleId: scheduleDetail?.id ?? -1,
        body: {
            baseline_budget: Number(baselineBudget),
        },
    }

    const schedule = scheduleController.schedule
    const totalServiceBudget = React.useMemo(
        () =>
            schedule?.template_services.reduce(
                (acc, ts) => acc + Number(ts.budget),
                0,
            ),
        [schedule?.template_services],
    )
    const totalLineItems = React.useMemo(
        () =>
            schedule?.line_items.reduce((acc, li) => acc + Number(li.cost), 0),
        [schedule?.line_items],
    )

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    minHeight: 40,
                }}
            >
                {/* Title */}
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    Summary
                </span>
            </Container>
            <Grid container spacing={0}>
                {/* Header Row */}
                <Grid item xs={3}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tooltip title="Total set budget for the project.">
                            <InfoIcon
                                fontSize="small"
                                color="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            ></InfoIcon>
                        </Tooltip>
                        Schedule
                    </HeaderValue>
                </Grid>
                <Grid item xs={3}>
                    <MoneyInputValue>
                        <NumberFormat
                            value={baselineBudget}
                            getInputRef={(input: HTMLInputElement | null) => {
                                input &&
                                    input.addEventListener(
                                        'focus',
                                        (event: FocusEvent) => {
                                            const target = event.target as HTMLInputElement
                                            target.select()
                                        },
                                    )
                            }}
                            onBlur={() => {
                                updateSchedule(req, {
                                    portfolio: portfolio,
                                })
                            }}
                            onChange={(e: any) => {
                                const value = Number(
                                    e.target.value.replace(/[^0-9.-]+/g, ''),
                                )
                                if (isNaN(value)) {
                                    return
                                }
                                setBaselineBudget(value)
                            }}
                            thousandSeparator={true}
                            decimalScale={0}
                            prefix={`$`}
                            style={{
                                // ...theme.typography.subtitle2,
                                fontWeight:
                                    props.theme.typography.fontWeightBold,
                                textAlign: 'right',
                                border: `1px solid ${props.theme.palette.grey[400]}`,
                                borderRadius: props.theme.shape.borderRadius,
                                padding: props.theme.spacing(0.25, 1),
                                width: 80,
                            }}
                        />
                    </MoneyInputValue>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tooltip title="Total forecasted cost of the project.">
                            <InfoIcon
                                fontSize="small"
                                color="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            ></InfoIcon>
                        </Tooltip>
                        Forecast
                    </HeaderValue>
                </Grid>
                <Grid item xs={3}>
                    <MoneyValue>
                        {formatTotals(totalInvoiceForecast.totalForecast)}
                    </MoneyValue>
                </Grid>
                <Grid item xs={3}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tooltip title="Total set budget for services.">
                            <InfoIcon
                                fontSize="small"
                                color="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            ></InfoIcon>
                        </Tooltip>
                        Services
                    </HeaderValue>
                </Grid>
                <Grid item xs={3}>
                    <MoneyValue>{formatTotals(totalServiceBudget)}</MoneyValue>
                </Grid>
                <Grid item xs={3}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tooltip title="Total dollars invoiced in schedule.">
                            <InfoIcon
                                fontSize="small"
                                color="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            ></InfoIcon>
                        </Tooltip>
                        Invoiced
                    </HeaderValue>
                </Grid>
                <Grid item xs={3}>
                    <MoneyValue>
                        {formatTotals(totalInvoiceForecast.totalInvoice)}
                    </MoneyValue>
                </Grid>
                <Grid item xs={3}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Tooltip title="Total budget for line items">
                            <InfoIcon
                                fontSize="small"
                                color="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                            ></InfoIcon>
                        </Tooltip>
                        Line Items
                    </HeaderValue>
                </Grid>
                <Grid item xs={3}>
                    <MoneyValue>{formatTotals(totalLineItems)}</MoneyValue>
                </Grid>
            </Grid>
        </Box>
    )
}
