import React, { useEffect, useState, useRef } from 'react'
import { APARTMENT, axiosInstance, isAccountManager } from '../../helpers'
import FileDownload from 'js-file-download'

//components
import { Container, SideDrawer, SideDrawerContainer } from '../../components'
import {
    useTheme,
    Tooltip,
    IconButton,
    Popover,
    TextField,
    InputAdornment,
    Button,
    CircularProgress,
    Tabs,
    Tab,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import SearchIcon from '@material-ui/icons/Search'

//hooks
import {
    AppDispatch,
    useAppDispatch,
    useCompany,
    useService,
    useUser,
} from '../../hooks'

//types
import {
    User,
    ListVendor,
    PermissionRole,
    EDIT_PERMISSION_ROLES,
    SEE_INCOME_FINANCES,
    Service,
    AreaConfig,
    UnitConfig,
    ServiceContract,
    IdBoolMap,
} from '../../models'

import { useServiceContract } from '../../hooks/useServiceContract'
import { getPermissionRoleList } from '../../store/permissions'

import { PermissionRoleDrawer } from './PermissionRoleDrawer'

import { toast } from 'react-toastify'
import { UserModal } from './UserModal'
import { WorkForceVendorTable } from './WorkforceVendorTable'
import { WorkforceNewPermissionTable } from './WorkforcePermissionTable'
import { WorkforceTeamTable } from './WorkforceTeamTable'
import { VendorAnalyticsDrawer } from './VendorAnalyticsDrawer'
import { useSelector } from 'react-redux'
import {
    AddRemoveVendorAptRequest,
    getAreaConfigList,
    getUnitConfigList,
    removeVendorFromApt,
    RootState,
} from '../../store'
import { hasPermission } from '../../models/Users/services'
import { PriceData } from '.'
import { MergeVendorsModal } from '../Invoice/MergeVendorsModal'
import { VendorModal } from './VendorModal/VendorModal'
import { ApartmentVendor } from '../NationalPropertyList'
import { useHistory, useLocation } from 'react-router-dom'

export const NEW_VENDOR_ID = 0

export const Workforce = () => {
    const {
        getVendorList,
        vendorList,
        updateVendor,
        updateVendorServices,
        createVendor,
    } = useCompany()
    const { getServiceContractList } = useServiceContract()
    const { serviceList, getServiceList } = useService()
    const { actions, userList, workspaceUser } = useUser()
    const { getUserList } = actions
    const permissionRoleList = useSelector(
        (state: RootState) => state.permission.permissionRoleList,
    )
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const history = useHistory()
    const location = useLocation()

    const VENDOR_TAB = 0
    const MY_TEAM = 1
    const PERMISSION_ROLES_TAB = 2

    const tabToHash: { [key: number]: string } = {
        [VENDOR_TAB]: 'my-vendors',
        [MY_TEAM]: 'my-team',
        [PERMISSION_ROLES_TAB]: 'permissions',
    }

    const hashToTab = Object.fromEntries(
        Object.entries(tabToHash).map(([key, value]) => [value, parseInt(key)]),
    )

    useEffect(() => {
        const hash = location.hash.replace('#', '')
        if (hash in hashToTab) {
            setSelectedTab(hashToTab[hash])
        }
    }, [location])

    const [selectedTab, setSelectedTab] = useState<number>(() => {
        const hash = location.hash.replace('#', '')
        return hash in hashToTab
            ? hashToTab[hash]
            : workspaceUser?.active_workspace.company_type === 'APARTMENT'
            ? VENDOR_TAB
            : MY_TEAM
    })

    const [openDrawer, setOpenDrawer] = useState(false)
    const [editUser, setEditUser] = useState<User>()
    const [editVendor, setEditVendor] = useState<ListVendor | undefined>()
    const [
        editPermissionRole,
        setEditPermissionRole,
    ] = useState<PermissionRole>()

    const [searchText, setSearchText] = useState('')

    const [popoverOpen, setPopoverOpen] = useState(false)
    const popoverRef = useRef<HTMLDivElement>(null)
    const uploadUnitSheetRef = useRef<HTMLInputElement>(null)

    const [unitConfigList, setUnitConfigList] = useState<UnitConfig[]>([])
    const [areaConfigList, setAreaConfigList] = useState<AreaConfig[]>([])
    const [
        vendorCanSeeUnitNotesMap,
        setVendorCanSeeUnitNotesMap,
    ] = useState<IdBoolMap>({})

    const [vendor, setVendor] = useState<ListVendor | undefined>()

    const [formState, setFormState] = useState<WorkforceFormState>(
        closedFormState,
    )

    const [loadingState, setLoadingState] = useState(true)

    const [openMergeVendors, setOpenMergeVendors] = useState(false)

    const WORKFORCE_DRAWER_WIDTH = 360

    useEffect(() => {
        if (workspaceUser?.active_workspace.company_type === 'APARTMENT') {
            const vendorListPromise = getVendorList({
                params: { my_team: true },
            })
            const serviceListPromise = getServiceList({})
            const userListPromise = getUserList({ params: { my_team: true } })
            const areaListPromise = dispatch(
                getAreaConfigList({ shows_on_schedule: true, minimal: true }),
            )
            const aptVendorPromise = axiosInstance.get(
                'company/apartment_vendors/',
            )
            const unitListPromise = dispatch(
                getUnitConfigList({ shows_on_schedule: true }),
            )

            dispatch(getPermissionRoleList({}))

            Promise.all([
                vendorListPromise,
                serviceListPromise,
                userListPromise,
                areaListPromise,
                unitListPromise,
                aptVendorPromise,
            ])
                .then((values) => {
                    const vendorList = values[0].data
                    const serviceList = values[1].data
                    const areaConfigList = values[3].data
                    const unitConfigList = values[4].data
                    const aptVendorList = values[5].data

                    const newVendorUnitNotesMap: IdBoolMap = {}
                    aptVendorList.forEach((aptVendor: ApartmentVendor) => {
                        newVendorUnitNotesMap[aptVendor.vendor.id] =
                            aptVendor.vendor_see_unit_notes
                    })

                    setVendorCanSeeUnitNotesMap(newVendorUnitNotesMap)
                    setUnitConfigList(unitConfigList)
                    setAreaConfigList(areaConfigList)
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    setLoadingState(false)
                })
        } else {
            const userListPromise = getUserList({ params: { my_team: true } })
            dispatch(getPermissionRoleList({}))

            Promise.all([userListPromise])
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    setLoadingState(false)
                })
        }
    }, [])

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '17px',
        flex: 2,
    }

    const CellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    const USER_CAN_EDIT_PERMISSION_ROLES = hasPermission(
        workspaceUser,
        EDIT_PERMISSION_ROLES,
    )

    const resendWelcomeEmail = (vendor: ListVendor) => {
        const body = {
            email: vendor.email,
            vendor_id: vendor.id,
        }
        axiosInstance
            .post(`company/vendor/resend_vendor_welcome_email/`, body)
            .then(() => {
                toast.success(`Welcome email resent to ${vendor.name}`)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
    }

    const handleTabChange = (_: any, newValue: number) => {
        if (newValue !== selectedTab) {
            setSelectedTab(newValue)
            history.push(`/workforce#${tabToHash[newValue]}`)
        }
    }

    return (
        <SideDrawerContainer open={false} width={WORKFORCE_DRAWER_WIDTH}>
            <Container
                flex={1}
                direction="column"
                style={{ height: 'calc(100vh - 104px)' }}
            >
                {/* Header */}
                <Container style={{ width: '100%' }}>
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h4,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {' '}
                            Workforce
                        </span>
                    </Container>

                    <div style={{ flex: 1 }} />

                    {hasPermission(workspaceUser, SEE_INCOME_FINANCES) && (
                        <Tooltip title="Upstream Service Contracts">
                            <IconButton onClick={() => setPopoverOpen(true)}>
                                <FileCopyIcon
                                    fontSize="large"
                                    innerRef={popoverRef}
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Popover
                        id="UPSTREAM_FINANCE_POPOVER"
                        open={popoverOpen}
                        anchorEl={popoverRef.current}
                        onClose={() => setPopoverOpen(false)}
                    >
                        <Container
                            style={{
                                backgroundColor: theme.palette.grey[100],
                                flexDirection: 'column',
                                padding: theme.spacing(2),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Upstream Contract Sheet
                            </span>

                            <Container
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <Tooltip title="Download upstream contract sheet">
                                    <IconButton
                                        onClick={() => {
                                            axiosInstance
                                                .get(
                                                    'service-contract/upstream-contract-sheet/',
                                                    {
                                                        responseType: 'blob',
                                                    },
                                                )
                                                .then((response) => {
                                                    FileDownload(
                                                        new Blob([
                                                            response.data,
                                                        ]),
                                                        'upstream_contract.xlsx',
                                                    )
                                                })
                                        }}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>

                                <span>Download</span>

                                <div style={{ flex: 1 }} />

                                <Tooltip title="Upload upstream contract sheet">
                                    <IconButton
                                        onClick={() =>
                                            uploadUnitSheetRef.current?.click()
                                        }
                                    >
                                        <PublishIcon />
                                    </IconButton>
                                </Tooltip>

                                <span>Upload</span>
                            </Container>
                        </Container>
                    </Popover>
                </Container>

                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {workspaceUser?.active_workspace.company_type ===
                        'APARTMENT' && (
                        <Tab label="My Vendors" value={VENDOR_TAB} />
                    )}
                    <Tab label="My Team" value={MY_TEAM} />
                    <Tab
                        label="Permission Roles"
                        value={PERMISSION_ROLES_TAB}
                    />
                </Tabs>

                {/* Tables */}
                {(loadingState && <CircularProgress />) || (
                    <Container direction="column">
                        <Container>
                            {selectedTab === MY_TEAM && (
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(2),
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        View all of your workers within the team
                                    </Container>

                                    <Container>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={(e) =>
                                                setSearchText(e.target.value)
                                            }
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{
                                                margin: theme.spacing(
                                                    3,
                                                    0,
                                                    2,
                                                    0,
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            style={{
                                                margin: theme.spacing(
                                                    3,
                                                    0,
                                                    2,
                                                    2,
                                                ),
                                                backgroundColor: '#008C85',
                                                color: 'white',
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setOpenDrawer(true)
                                                setEditUser(undefined)
                                                setEditVendor(undefined)
                                                setEditPermissionRole(undefined)
                                            }}
                                        >
                                            + Add Member
                                        </Button>
                                    </Container>
                                    <WorkforceTeamTable
                                        headCellStyle={HeadCellStyle}
                                        userList={userList.filter((user) =>
                                            user.name
                                                .toLocaleLowerCase()
                                                .includes(
                                                    searchText.toLocaleLowerCase(),
                                                ),
                                        )}
                                        cellStyle={CellStyle}
                                        setEditUser={setEditUser}
                                        setOpenDrawer={setOpenDrawer}
                                    />
                                </Container>
                            )}
                            {selectedTab === VENDOR_TAB && (
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(2),
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        View all of your vendors around your
                                        area and schedule them out for work!
                                    </Container>

                                    <Container style={{ flex: 1 }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={(e) =>
                                                setSearchText(e.target.value)
                                            }
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{
                                                margin: theme.spacing(
                                                    3,
                                                    0,
                                                    2,
                                                    0,
                                                ),
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            style={{
                                                margin: theme.spacing(
                                                    3,
                                                    0,
                                                    2,
                                                    2,
                                                ),
                                                backgroundColor: '#008C85',
                                                color: 'white',
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setFormState({
                                                    ...formState,
                                                    modal: {
                                                        ...formState.modal,
                                                        vendorModal: {
                                                            open: true,
                                                            vendorId: -1,
                                                        },
                                                    },
                                                })
                                            }}
                                        >
                                            + Add Vendor
                                        </Button>

                                        {isAccountManager(workspaceUser) && (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    margin: theme.spacing(
                                                        3,
                                                        2,
                                                        2,
                                                        2,
                                                    ),
                                                    backgroundColor: '#8A8A8A',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    setOpenMergeVendors(true)
                                                }
                                            >
                                                Merge Vendors
                                            </Button>
                                        )}
                                    </Container>
                                    <WorkForceVendorTable
                                        headCellStyle={HeadCellStyle}
                                        vendorList={vendorList.filter(
                                            (vendor) =>
                                                vendor.name
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                        searchText.toLocaleLowerCase(),
                                                    ),
                                        )}
                                        cellStyle={CellStyle}
                                        setEditVendor={setEditVendor}
                                        setOpenDrawer={setOpenDrawer}
                                        onClickVendorRow={(vendor) => {
                                            setVendor(vendor)
                                            setFormState({
                                                ...formState,
                                                modal: {
                                                    ...formState.modal,
                                                    vendorModal: {
                                                        open: true,
                                                        vendorId: vendor.id,
                                                    },
                                                },
                                            })
                                        }}
                                        setOpenAnalytics={(vendor) => {
                                            setVendor(vendor)
                                            setFormState({
                                                ...formState,
                                                drawer: {
                                                    ...formState.drawer,
                                                    vendorAnalyticsDrawer: {
                                                        open: true,
                                                        vendorId: vendor.id,
                                                    },
                                                },
                                            })
                                        }}
                                        resendWelcomeEmail={resendWelcomeEmail}
                                    />
                                </Container>
                            )}
                            {selectedTab === PERMISSION_ROLES_TAB && (
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(2),
                                            fontSize: '15px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        View your current permission roles down
                                        below
                                    </Container>

                                    <Container>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={(e) =>
                                                setSearchText(e.target.value)
                                            }
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{
                                                margin: theme.spacing(
                                                    3,
                                                    0,
                                                    2,
                                                    0,
                                                ),
                                            }}
                                        />
                                        {USER_CAN_EDIT_PERMISSION_ROLES && (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    margin: theme.spacing(
                                                        3,
                                                        0,
                                                        2,
                                                        2,
                                                    ),
                                                    backgroundColor: '#008C85',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setOpenDrawer(true)
                                                    setEditUser(undefined)
                                                    setEditVendor(undefined)
                                                    setEditPermissionRole(
                                                        undefined,
                                                    )
                                                }}
                                            >
                                                + Add Role
                                            </Button>
                                        )}
                                    </Container>
                                    <WorkforceNewPermissionTable
                                        permissionRoleList={
                                            permissionRoleList?.filter((role) =>
                                                role.name
                                                    .toLocaleLowerCase()
                                                    .includes(
                                                        searchText.toLocaleLowerCase(),
                                                    ),
                                            ) ?? []
                                        }
                                        cellStyle={CellStyle}
                                        headCellStyle={HeadCellStyle}
                                        setOpenDrawer={setOpenDrawer}
                                        setEditPermissionRole={(role) => {
                                            setEditPermissionRole(role)
                                            setOpenDrawer(true)
                                        }}
                                    />
                                </Container>
                            )}
                        </Container>
                    </Container>
                )}
            </Container>

            {/* side drawer */}
            {selectedTab === VENDOR_TAB && vendor && (
                <VendorAnalyticsDrawer
                    vendorList={vendorList}
                    formState={formState}
                    handleClose={() => setFormState(closedFormState)}
                    width={WORKFORCE_DRAWER_WIDTH}
                />
            )}

            {selectedTab === VENDOR_TAB && (
                <VendorModal
                    formState={formState}
                    theme={theme}
                    vendorList={vendorList}
                    onClose={() => setFormState(closedFormState)}
                    serviceList={serviceList}
                    unitConfigs={unitConfigList}
                    areaConfigs={areaConfigList}
                    updateVendor={updateVendor}
                    updateVendorServices={updateVendorServices}
                    createVendor={createVendor}
                    vendorCanSeeUnitNotesMap={vendorCanSeeUnitNotesMap}
                    setVendorCanSeeUnitNotesMap={setVendorCanSeeUnitNotesMap}
                />
            )}

            {selectedTab === MY_TEAM && (
                <UserModal
                    handleClose={() => setOpenDrawer(false)}
                    drawerUser={editUser}
                    open={openDrawer}
                />
            )}

            {selectedTab === PERMISSION_ROLES_TAB && workspaceUser && (
                <SideDrawer
                    open={openDrawer}
                    width={WORKFORCE_DRAWER_WIDTH}
                    handleClose={() => setOpenDrawer(false)}
                    header={<></>}
                >
                    <PermissionRoleDrawer
                        permissionRole={editPermissionRole}
                        handleClose={() => setOpenDrawer(false)}
                        open={openDrawer}
                        organization={
                            workspaceUser.active_workspace.organization
                        }
                    />
                </SideDrawer>
            )}

            {selectedTab === VENDOR_TAB && isAccountManager(workspaceUser) && (
                <MergeVendorsModal
                    open={openMergeVendors}
                    handleClose={() => setOpenMergeVendors(false)}
                    vendors={vendorList}
                />
            )}

            <input
                ref={uploadUnitSheetRef}
                type="file"
                style={{ display: 'none' }}
                accept={'.xlsx'}
                onChange={(e) => {
                    if (
                        e.target.files?.length !== undefined &&
                        e.target.files.length > 0
                    ) {
                        const sheet = e.target.files[0]

                        const reqBody = new FormData()
                        reqBody.append('sheet', sheet)

                        axiosInstance
                            .post(
                                'service-contract/upstream-contract-sheet/',
                                reqBody,
                            )
                            .then(() => {
                                toast.success(
                                    'Success you will receive an email with your upload report soon.',
                                )
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your sheet.',
                                )
                            })
                    }
                }}
            />
        </SideDrawerContainer>
    )
}

export type WorkforceFormState = {
    modal: {
        vendorModal: {
            open: boolean
            vendorId: number
        }
    }
    drawer: {
        vendorAnalyticsDrawer: {
            open: boolean
            vendorId: number
        }
    }
}

const closedFormState: WorkforceFormState = {
    modal: {
        vendorModal: {
            open: false,
            vendorId: -1,
        },
    },
    drawer: {
        vendorAnalyticsDrawer: {
            open: false,
            vendorId: -1,
        },
    },
}
