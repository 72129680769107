import React, { useState } from 'react'

import {
    Divider,
    IconButton,
    makeStyles,
    Popover,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/Close'

import { Container, Selector } from '../../../components'
import { InventoryConfig, StatusGroup } from '../../../models'
import { usePopover } from '../../../hooks/usePopover'

const useStyles = makeStyles<Theme, Props>((theme) => ({
    statusContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        position: 'relative',
        padding: theme.spacing(1),
        '&:hover': {
            border: (props) =>
                props.hasEditPermission
                    ? `1px solid ${theme.palette.grey[400]}`
                    : 'none',
            cursor: (props) =>
                props.hasEditPermission ? 'pointer' : 'default',
            '& $editButton': {
                opacity: 1,
            },
        },
    },
    editButton: {
        opacity: 0,
        transition: 'opacity 0.2s',
    },
}))

interface Props {
    inventoryConfig: InventoryConfig
    theme: Theme
    statusGroupList: StatusGroup[]
    hasEditPermission: boolean
    updateInventoryConfig: (
        inventoryConfig: InventoryConfig,
        keyValuePairs: { key: string; value: string }[],
    ) => void
}

export const StatusGroupDisplay = (props: Props) => {
    const {
        inventoryConfig,
        theme,
        hasEditPermission,
        updateInventoryConfig,
    } = props

    const classes = useStyles(props)

    const [statusGroupId, setStatusGroupId] = useState(
        inventoryConfig.status_group?.id ?? -1,
    )

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const defaultStatus = inventoryConfig.status_group?.default_status

    return (
        <Container className={classes.statusContainer}>
            <Container
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flex: 1,
                }}
            >
                {inventoryConfig.custom_status_list?.map((status) => {
                    const isDefaultStatus = status.id === defaultStatus?.id

                    return (
                        <span
                            key={`inv_conf_${inventoryConfig.id}_status_${status.id}`}
                            style={{
                                padding: theme.spacing(1, 2),
                                border: `1px solid black`,
                                borderRadius: theme.shape.borderRadius,
                                textAlign: 'center',
                                margin: theme.spacing(1),
                                width: `calc((100% / 3) - ${theme.spacing(
                                    2,
                                )}px)`,
                                boxSizing: 'border-box',
                                borderColor: isDefaultStatus ? '' : 'black',
                                backgroundColor: isDefaultStatus
                                    ? defaultStatus.color
                                    : 'white',
                                color: isDefaultStatus ? 'white' : 'black',
                            }}
                        >
                            {status.name}
                        </span>
                    )
                })}
            </Container>
            {hasEditPermission && (
                <Container
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Tooltip title="Change Status Group">
                        <IconButton
                            // Temporarily apply styles directly for testing
                            className={classes.editButton} // Ensure this is applied
                            size="small"
                            onClick={handleOpen}
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Container>
            )}
            <Popover
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isOpen}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <Container style={{ margin: theme.spacing(1) }}>
                        <Selector
                            label="Status Group"
                            currentValue={statusGroupId}
                            data={props.statusGroupList ?? []}
                            customStyle={{
                                formControl: { margin: 0, width: 200 },
                            }}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                const newDefaultGroup = props.statusGroupList?.find(
                                    (s) =>
                                        s.id === (event.target.value as number),
                                )
                                setStatusGroupId(newDefaultGroup?.id ?? -1)
                            }}
                            getDisplayString={(s: StatusGroup) => `${s.name}`}
                        />
                    </Container>
                    <Divider />
                    <Container>
                        <div style={{ flex: 1 }} />
                        <Tooltip
                            title="Save"
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            <IconButton
                                onClick={() => {
                                    updateInventoryConfig(inventoryConfig, [
                                        {
                                            key: 'status_group',
                                            value: statusGroupId.toString(),
                                        },
                                    ])
                                    handleClose()
                                }}
                            >
                                <SaveIcon color="primary" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Cancel">
                            <IconButton onClick={handleClose}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <div style={{ flex: 1 }} />
                    </Container>
                </Container>
            </Popover>
        </Container>
    )
}
