import { Company } from '../Company'
import {
    AreaConfig,
    BaseAreaConfig,
    CustomStatus,
    InventoryConfig,
} from '../Config'
import {
    Inspection,
    InventoryInspection,
    MoveInspection,
    UnitInspection,
} from '../Inspection'
import { Damage } from '../Lease'
import { IdentifiableNamedObject, IdentifiableObject } from '../types'
import { User } from '../Users'
import { _Workorder, AreaStatusTag } from '../WorkOrder'

export enum OccStatus {
    VACANT = 'VACANT',
    RENEWAL = 'RENEWAL',
    TRANSFER_IN = 'TRANSFER_IN',
    TRANSFER_OUT = 'TRANSFER_OUT',
    EARLY_MOVE_IN = 'EARLY_MOVE_IN',
    OCCUPIED = 'OCCUPIED',
    DO_NOT_TURN = 'DO_NOT_TURN',
}

export enum NonOccStatus {
    YES = 'YES',
    NO = 'NO',
}

export type AreaStatus = OccStatus | NonOccStatus

// base for the folder that is used below and for jobsite locations
export interface BaseFolder extends IdentifiableNamedObject {
    path: string
    pathIds: string
    parent: Folder | null
}

export interface Folder extends BaseFolder {
    apartmentOwner: Company | null
    units: Unit[]
    children: Folder[]
    lineageUnitCount: number
}

export interface FilterableUnit extends IdentifiableNamedObject {
    unit_config: number
}

export interface Unit extends FilterableUnit {
    folder: Folder
    areas: Area[]
    workorders: _Workorder[]
    inspectionDamages: Damage[]
    inventoryInspections: InventoryInspection[]
    unitInspection?: UnitInspection
    channel_id?: number
    unit_notes_msg_count?: number
    unread_unit_notes?: number
}

export interface UnitMinified extends IdentifiableObject {
    name: string
    folder: number
    unit_config: number
    area_status_tags?: AreaStatusTag[]
}

export interface Area extends IdentifiableObject {
    area_config: number
    area_label: string
    unit: number
    area_config_name?: string
    upcoming_move_in_inspection?: MoveInspection | null
    upcoming_move_out_inspection?: MoveInspection | null
}

export interface AreaWithConfig extends IdentifiableObject {
    area_label: string
    unit: number
    area_config: BaseAreaConfig
}

export interface Inventory extends IdentifiableObject {
    area: number
    inventory_config: number
}

export interface InventoryArea extends IdentifiableObject {
    area_config: AreaConfig
    area_number: number
    area_type: 'OCCUPIABLE' | 'NON_OCCUPIABLE'
    unit: number
}

export interface InventoryInspectionTransitionLog extends IdentifiableObject {
    user: User
    from_status: CustomStatus
    to_status: CustomStatus
    created_date: string
}

export interface InspectionHistory extends IdentifiableObject {
    status: CustomStatus
    inspection: Inspection
    transition_logs: InventoryInspectionTransitionLog[]
    unit_inspection: UnitInspection | null
}

export interface InventoryDetail extends IdentifiableObject {
    area: InventoryArea
    inventory_config: InventoryConfig
    inspection_history: InspectionHistory[]
}

export enum UnitOccupanyType {
    PARTIAL,
    FULL_RENEW,
    FULL_VACANT,
}

export interface FolderMinified extends IdentifiableObject {
    name: string
    parent: number | null
    path: string
    path_ids: string
}
