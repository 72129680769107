import React from 'react'
import { navigationConfig } from '../../config/navigationItems'

import NavItem from '../NavItem'
import { useTheme } from '@material-ui/core'

export const ApartmentItems = () => {
    const theme = useTheme()
    return (
        <>
            {navigationConfig.apartment.mainItems.map((item) => (
                <NavItem key={item.name} item={item} />
            ))}
            {/* <div
                style={{
                    marginTop: 5,
                    marginBottom: 5,
                    borderBottom: '1px solid white',
                }}
            /> */}
            {/* {navigationConfig.apartment.footerItems.map((item) => (
                <NavItem key={item.name} item={item} />
            ))} */}
        </>
    )
}
