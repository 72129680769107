import React from 'react'
import { WorkSpaceUser } from '../../models'
import { Routes } from '../config/routes'
import { Redirect } from 'react-router-dom'

interface ManageGuardProps {
    children: React.ReactNode
    workspaceUser?: WorkSpaceUser
}

export const ManageGuard = ({
    children,
    workspaceUser,
}: ManageGuardProps) => {
    if (!workspaceUser) {
        return <Redirect to={Routes.error.permissionDenied} />
    }

    // TODO: Add logic to check if the user is in an organization

    return <div>{children}</div>
}
