import React, { useState, useEffect } from 'react'
import { Container } from '../../../components'
import {
    PropertyGradeResponseData,
    useAnalyticData,
    useDateRange,
    useUser,
} from '../../../hooks'
import { CircularProgress, Modal, Paper, Slide, Theme } from '@material-ui/core'
import {
    ALL,
    DateFilter,
    DateFilterButtons,
    LAST_MONTH,
    THIS_MONTH,
} from '../../../components/DateFilterButtons'
import { getPreviousMonthDates } from '../../../helpers/date'
import { PropertyGradeTable } from './PropertyGradeTable'
import { InspectionTypeGradeTable } from './InspectionTypeGradeTable'

interface Props {
    theme: Theme
    ezFolio?: boolean
}

export const PagePropertyGrade = (props: Props) => {
    const { theme, ezFolio } = props
    const [dateFilter, setDateFilter] = useState<DateFilter>(ALL)
    const [openInspectionTypeModal, setOpenInspectionTypeModal] = useState(
        false,
    )
    const [
        selectedPropertyGrade,
        setSelectedPropertyGrade,
    ] = useState<PropertyGradeResponseData | null>(null)
    const { dateRange, setStartDate, setEndDate } = useDateRange('')
    const {
        loading,
        propertyGradeList,
        chartData,
        setPropertyGradeList,
        getPropertyGradeList,
        setInspectionTypeGrades,
        getChartData,
        getInspectionTypeGrades,
        inspectionTypeGrades,
        setInspectionDetailGrades,
        inspectionDetailGrades,
        getInspectionDetailGrades,
    } = useAnalyticData()

    const { organization } = useUser()

    useEffect(() => {
        if (dateFilter === ALL) {
            getPropertyGradeList({
                organization:
                    organization && ezFolio ? organization : undefined,
            })
        } else if (dateFilter === LAST_MONTH) {
            const { startDate, endDate } = getPreviousMonthDates()
            getPropertyGradeList({
                start_date: startDate,
                end_date: endDate,
                organization:
                    organization && ezFolio ? organization : undefined,
            })
        } else if (dateFilter === THIS_MONTH) {
            const today = new Date()
            const startOfMonth = new Date(
                today.getFullYear(),
                today.getMonth(),
                1,
            )
            const endOfMonth = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
            )
            getPropertyGradeList({
                start_date: startOfMonth,
                end_date: endOfMonth,
                organization:
                    organization && ezFolio ? organization : undefined,
            })
        }
    }, [dateFilter, organization])

    return (
        <Container direction="column">
            {/* header */}
            <Container style={{ marginTop: theme.spacing(2) }}>
                <DateFilterButtons
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    dateRange={dateRange}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onApply={() => {
                        getPropertyGradeList({
                            start_date: dateRange.startDate,
                            end_date: dateRange.endDate,
                            organization:
                                organization && ezFolio
                                    ? organization
                                    : undefined,
                        })
                    }}
                />
            </Container>
            {loading ? (
                <Container flex={1} alignItems="center" justifyContent="center">
                    <CircularProgress
                        size={100}
                        style={{ marginTop: theme.spacing(10) }}
                    />
                </Container>
            ) : (
                <PropertyGradeTable
                    propertyGradeList={propertyGradeList}
                    getChartData={getChartData}
                    chartData={chartData}
                    getInspectionTypeGrades={getInspectionTypeGrades}
                    timeline={dateRange}
                    dateFilter={dateFilter}
                    setPropertyGradeList={setPropertyGradeList}
                    setOpenInspectionTypeModal={setOpenInspectionTypeModal}
                    setSelectedPropertyGrade={setSelectedPropertyGrade}
                    selectedPropertyGrade={selectedPropertyGrade}
                />
            )}

            <Modal
                open={openInspectionTypeModal}
                onClose={() => {
                    setOpenInspectionTypeModal(false)
                    setSelectedPropertyGrade(null)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={openInspectionTypeModal}>
                    <Paper
                        style={{
                            padding: theme.spacing(2),
                            minWidth: '900px',
                        }}
                    >
                        <Container style={{ ...theme.typography.h4 }}>
                            {selectedPropertyGrade?.name}
                        </Container>
                        <Container style={{ ...theme.typography.h6 }}>
                            BIPS: {selectedPropertyGrade?.bips}
                        </Container>
                        <Container>
                            {inspectionTypeGrades?.length === 0 ? (
                                <Container
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    There are no inspections during this time
                                    frame. Consider adjusting your date filters.
                                </Container>
                            ) : (
                                <InspectionTypeGradeTable
                                    getInspectionDetailGrades={
                                        getInspectionDetailGrades
                                    }
                                    timeline={dateRange}
                                    dateFilter={dateFilter}
                                    inspectionTypeGrades={inspectionTypeGrades}
                                    setInspectionTypeGrades={
                                        setInspectionTypeGrades
                                    }
                                    inspectionDetailGrades={
                                        inspectionDetailGrades
                                    }
                                    setInspectionDetailGrades={
                                        setInspectionDetailGrades
                                    }
                                />
                            )}
                        </Container>
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
