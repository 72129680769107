import React from 'react'
import { makeStyles, Theme, Typography, Button } from '@material-ui/core'
import { ChatMessageContent } from '../../types'
import { Container } from '../../../../../components/Container'
import { DeadlineCard } from './CustomCards/DeadlineCard'
import { ProjectCard } from './CustomCards/ProjectCard'
import { HelpVideoCard } from './CustomCards/HelpVideoCard'
import { ContactSupportButton } from './CustomCards/ContactSupportButton'
import { LoriController } from '../../../../../hooks/useLori'

interface ChatMessageProps {
    avatar: string | null
    message: ChatMessageContent
    theme: Theme
    userName: string | undefined
    handleSendMessage: (message: string, displayMessage: boolean) => void
    loriController: LoriController
}

const useStyles = makeStyles((theme: Theme) => ({
    messageContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        alignItems: 'flex-end',
    },
    messageContent: {
        maxWidth: '70%',
        padding: theme.spacing(1, 2),
        borderRadius: theme.shape.borderRadius * 3,
    },
    userMessage: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginLeft: 'auto',
        borderBottomRightRadius: theme.shape.borderRadius,
        borderTopLeftRadius: theme.shape.borderRadius * 2,
    },
    aiMessage: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        marginRight: 'auto',
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius * 2,
    },
    cardContainer: {
        width: '100%',
        margin: theme.spacing(1, 0),
        '& .MuiCard-root': {
            width: '100%',
        },
    },
}))

export const ChatMessage = ({
    message,
    theme,
    handleSendMessage,
    loriController,
}: ChatMessageProps) => {
    const classes = useStyles(theme)
    const { role, content_type, function_call, message_items } = message
    const isUser = role === 'user'

    const assistantMessage = () => {
        if (message.message_items && message.message_items.length > 0) {
            return message.message_items[0]?.value
        }
        return null
    }

    const renderContent = () => {
        if (function_call) {
            switch (function_call) {
                case 'create_project':
                    if (message.project_response) {
                        return (
                            <ProjectCard
                                theme={theme}
                                projectResponse={message.project_response}
                            />
                        )
                    }
                    return null
                case 'create_deadline':
                    if (message.deadline_response) {
                        return (
                            <DeadlineCard
                                theme={theme}
                                deadlineResponse={message.deadline_response}
                            />
                        )
                    }
                    return null

                case 'how_to_video':
                    if (message.how_to_video_response) {
                        return (
                            <HelpVideoCard
                                how_to_video_response={
                                    message.how_to_video_response
                                }
                                handleSendMessage={handleSendMessage}
                            />
                        )
                    }
                    return null
                case 'contact_support':
                    return (
                        <ContactSupportButton
                            theme={theme}
                            conversation={
                                loriController.activeConversation ?? null
                            }
                        />
                    )
                default:
                    return null
            }
        }

        return null
    }

    return (
        <Container
            style={{
                display: 'flex',
                marginBottom: theme.spacing(1),
                justifyContent: isUser ? 'flex-end' : 'flex-start',
                alignItems: 'center',
                position: 'relative',
                padding: 0,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    maxWidth: function_call ? '100%' : '90%',
                    width: function_call ? 'calc(100% - 24px)' : 'auto',
                    flex: function_call ? 1 : 'initial',
                }}
            >
                {' '}
                {function_call ? (
                    renderContent()
                ) : (
                    <Typography
                        component="div"
                        style={{
                            padding: theme.spacing(1, 1),
                            borderRadius: theme.shape.borderRadius * 3,
                            backgroundColor: isUser
                                ? theme.palette.primary.main
                                : theme.palette.grey[100],
                            color: isUser
                                ? theme.palette.primary.contrastText
                                : theme.palette.text.primary,
                            borderBottomRightRadius: isUser
                                ? theme.shape.borderRadius
                                : undefined,
                            borderBottomLeftRadius: !isUser
                                ? theme.shape.borderRadius
                                : undefined,
                            borderTopLeftRadius: isUser
                                ? theme.shape.borderRadius * 2
                                : undefined,
                            borderTopRightRadius: !isUser
                                ? theme.shape.borderRadius * 2
                                : undefined,
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {assistantMessage()}
                    </Typography>
                )}
            </div>
        </Container>
    )
}
