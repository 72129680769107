import React, { useEffect, useState } from 'react'
import { _useLease } from '../../../hooks'

import { Button, Divider, useTheme } from '@material-ui/core'
import { Container, NumberInput, SideDrawer } from '../../../components'
import { DateRange, Range } from 'react-date-range'

import { getLeaseTimeline, Lease, Timeline } from '../../../models'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
    getNextAndPrevLeases,
    RootState,
    setNextPrevLease,
} from '../../../store'
import { toMMDDYYYY } from '../../../helpers'

interface Props {
    open: boolean
    lease?: Lease
    historyMode: boolean
    handleClose: () => void
}

export const LeaseForm = (props: Props) => {
    const { open, lease, historyMode, handleClose } = props
    const theme = useTheme()
    const dispatch = useDispatch()

    const RANGE_KEY = 'RANGE_KEY'
    const timeline: Timeline | undefined = lease
        ? getLeaseTimeline(lease)
        : undefined
    const [dateRange, setDateRange] = useState<Range>({
        startDate: timeline?.startDate,
        endDate: timeline?.endDate,
        color: theme.palette.primary.main,
        key: RANGE_KEY,
    })
    const [rent, setRent] = useState(String(lease?.rent))
    const nextLease = useSelector((state: RootState) => state.lease.nextLease)
    const prevLease = useSelector((state: RootState) => state.lease.prevLease)

    const { bulkUpdateLeases } = _useLease()

    useEffect(() => {
        let timeline: Timeline | undefined = undefined
        if (lease) {
            dispatch(getNextAndPrevLeases({ id: lease.id }))
            timeline = getLeaseTimeline(lease)
        }
        setDateRange({
            ...dateRange,
            startDate: timeline?.startDate,
            endDate: timeline?.endDate,
        })
        setRent(String(lease?.rent))

        return () => {
            dispatch(setNextPrevLease(undefined, undefined))
        }
    }, [lease])

    const onPressSave = () => {
        bulkUpdateLeases({
            body: {
                leases: [
                    {
                        lease: lease?.id ?? -1,
                        start_date: dateRange.startDate?.toISOString(),
                        end_date: dateRange.endDate?.toISOString(),
                        rent: rent === '' ? undefined : Number(rent),
                    },
                ],
            },
        }).then(() => {
            toast.success('Lease Updated!')
        })
    }

    return (
        <SideDrawer open={open} handleClose={handleClose} title="Edit Lease">
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflowY: 'hidden',
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(2),
                        overflowX: 'hidden',
                    }}
                    scrollY
                >
                    <DateRange
                        disabled={historyMode}
                        ranges={[dateRange]}
                        fixedHeight
                        onChange={(range) => {
                            const key = RANGE_KEY as keyof typeof range
                            const newRange = range[key] as {
                                startDate: Date
                                endDate: Date
                            }
                            setDateRange(newRange)
                        }}
                    />
                    <NumberInput
                        disabled={historyMode}
                        label="Rent"
                        prefix={'$'}
                        variant="outlined"
                        value={rent}
                        onChange={(event) => {
                            setRent(event.target.value)
                        }}
                    />

                    {/* next and prev lease */}
                    <span
                        style={{
                            marginTop: theme.spacing(1),
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Previous Lease
                    </span>
                    <RelatedLease lease={prevLease} />
                    <span
                        style={{
                            marginTop: theme.spacing(2),
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Next Lease
                    </span>
                    <RelatedLease lease={nextLease} />
                </Container>

                {/* footer */}
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        style={{ marginRight: theme.spacing(2) }}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    {!historyMode && (
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={onPressSave}
                        >
                            Save
                        </Button>
                    )}
                </Container>
            </Container>
        </SideDrawer>
    )
}

interface RelatedLeaseProps {
    lease?: Lease
}

const RelatedLease = (props: RelatedLeaseProps) => {
    const { lease } = props
    const theme = useTheme()

    let timeline: Timeline | undefined = undefined
    if (lease) {
        timeline = getLeaseTimeline(lease)
    }

    const HEIGHT = 80

    let content = (
        <Container
            style={{
                height: HEIGHT,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span style={{ ...theme.typography.h4 }}>No Lease</span>
        </Container>
    )

    if (lease) {
        content = (
            <Container style={{ height: HEIGHT, flexDirection: 'column' }}>
                <Container
                    style={{
                        marginBottom: theme.spacing(1),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Lease Term:</span>

                    <Container>
                        <span>{toMMDDYYYY(timeline?.startDate)}</span>
                        <span style={{ margin: theme.spacing(0, 2) }}>-</span>
                        <span>{toMMDDYYYY(timeline?.endDate)}</span>
                    </Container>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Rent:</span>
                    <span>{lease?.rent ?? 'None'}</span>
                </Container>
            </Container>
        )
    }

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[3],
                ...theme.typography.body1,
                flexDirection: 'column',
            }}
        >
            {content}
        </Container>
    )
}
