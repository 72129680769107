import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import {
    Button,
    Divider,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../../components'
import {
    AreaConfig,
    convertListToMap,
    IdBoolMap,
    InspectionType,
    InventoryConfig,
    StatusGroup,
} from '../../../models'
import { CONGIFURE_INSPECTION_TYPE_STEP } from './InspectionConfigurationModal'
import { updateInventoryConfigReq } from '../../../store'
import { useAppDispatch } from '../../../hooks'
import { AreaConfigButton } from './AreaConfigButton'
import { InventoryConfigRow } from './InventoryConfigRow'

interface Props {
    invItemsMap: IdBoolMap
    inventoryConfigList: InventoryConfig[]
    areaConfigList: AreaConfig[]
    statusGroupList: StatusGroup[]
    hasEditPermission: boolean
    inspectionType?: InspectionType
    handleClose: () => void
    setTab: (tab: number) => void
    setInvItemsMap: (invItemsMap: IdBoolMap) => void
    saveInspectionType: () => void
}

export const PreviewInspectionType = (props: Props) => {
    const {
        invItemsMap,
        inspectionType,
        inventoryConfigList,
        areaConfigList,
        statusGroupList,
        hasEditPermission,
        handleClose,
        setTab,
        setInvItemsMap,
        saveInspectionType,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const inventoryConfigMap = convertListToMap(inventoryConfigList)

    const [
        selectedAreaConfig,
        setSelectedAreaConfig,
    ] = useState<AreaConfig | null>(null)

    const selectedAreaConfigs = useMemo(() => {
        return areaConfigList.filter((areaConfig) =>
            areaConfig.inventory_area_configs?.some(
                (inventoryAreaConfig) =>
                    invItemsMap[inventoryAreaConfig.inventory_config.id],
            ),
        )
    }, [areaConfigList, invItemsMap])

    // Automatically select the first selectedAreaConfig
    useEffect(() => {
        if (selectedAreaConfigs.length > 0) {
            setSelectedAreaConfig(selectedAreaConfigs[0])
        }
    }, [])

    const updateInventoryConfig = (
        inventoryConfig: InventoryConfig,
        keyValuePairs: { key: string; value: string }[],
    ) => {
        const invConfigFormData = new FormData()
        keyValuePairs.forEach(({ key, value }) => {
            invConfigFormData.append(key, value)
        })
        dispatch(
            updateInventoryConfigReq({
                id: inventoryConfig.id,
                req: invConfigFormData,
            }),
        ).then(() => {
            toast.success(`${inventoryConfig.name} updated successfully!`)
        })
    }

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Container
                alignItems="center"
                style={{ marginBottom: theme.spacing(2) }}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        flex: 1,
                    }}
                >
                    Step 2: New Inspection Configuration
                </Container>
                <Tooltip title="Close">
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon
                            fontSize="inherit"
                            style={{
                                color: theme.palette.darkGreen.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Divider />

            <Container
                style={{
                    marginTop: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                    overflowX: 'auto',
                    display: 'flex',
                }}
            >
                {selectedAreaConfigs.map((areaConfig) => (
                    <AreaConfigButton
                        key={areaConfig.id}
                        areaConfig={areaConfig}
                        selected={selectedAreaConfig?.id === areaConfig.id}
                        theme={theme}
                        onClick={() => {
                            setSelectedAreaConfig(areaConfig)
                        }}
                    />
                ))}
            </Container>

            <Container
                style={{
                    marginTop: theme.spacing(2),
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 'calc(100vh - 325px)',
                    minHeight: 'calc(100vh - 325px)',
                }}
            >
                {selectedAreaConfig?.inventory_area_configs?.map(
                    (inventoryAreaConfig) => {
                        const inventoryConfig =
                            inventoryConfigMap[
                                inventoryAreaConfig.inventory_config.id
                            ]
                        if (!inventoryConfig) return null
                        if (invItemsMap[inventoryConfig.id]) {
                            return (
                                <InventoryConfigRow
                                    key={`INV_CONF_${inventoryConfig.id}`}
                                    inventoryConfig={inventoryConfig}
                                    theme={theme}
                                    statusGroupList={statusGroupList}
                                    hasEditPermission={hasEditPermission}
                                    onRemoveInvType={() => {
                                        setInvItemsMap({
                                            ...invItemsMap,
                                            [inventoryConfig.id]: false,
                                        })
                                    }}
                                    updateInventoryConfig={
                                        updateInventoryConfig
                                    }
                                />
                            )
                        }
                        return null
                    },
                )}
            </Container>

            <Container justifyContent="flex-end">
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1, 0, 1, 2),
                    }}
                    onClick={() => setTab(CONGIFURE_INSPECTION_TYPE_STEP)}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => saveInspectionType()}
                >
                    {inspectionType ? 'Update' : 'Create'}
                </Button>
            </Container>
        </Container>
    )
}
