import React from 'react'

import { OrganizationProjectPage } from './OrganizationProjectPage'
import { useWorkorderState, WorkorderContext } from '../../../../contexts'

export const OrganizationProjectRoot = () => {
    const workorderState = useWorkorderState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <OrganizationProjectPage />
        </WorkorderContext.Provider>
    )
}
