import {
    CircularProgress,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import {
    BarChart,
    FormatPaint,
    LocationOn,
    MonetizationOn,
} from '@material-ui/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Container,
    EllipsisSpan,
    MessageForm,
    StyledProgressBar,
} from '../../components'
import {
    Routes,
    currencyFormatter,
    resolveRoute,
    toMMDDYYYY,
} from '../../helpers'
import { useAppDispatch, useOnScreen, useService } from '../../hooks'
import {
    addWorkorderToAnalytics,
    createWorkorderAnalyticDict,
    getTotalPriceExpense,
    PortfolioSchedule,
    Service,
    WorkorderStatus,
} from '../../models'
import {
    getPortfolioScheduleList,
    getScheduleDetail,
    getVendorList,
    getWorkOrderList,
    GET_PORTFOLIO_SCHEDULE_LIST_REQUEST,
    GET_SCHEDULE_DETAIL_REQUEST,
    GET_WORKORDER_LIST_REQUEST,
    RootState,
    setPortfolioScheduleBudgetItemList,
    setPortfolioScheduleDetail,
    setPortfolioVendorAnalytics,
} from '../../store'
import { useStyles } from '../../styles'
import { SchedulePreTurnChecklist } from '../Scheduler_v2/Header/SchedulePreTurnChecklist'
import { BudgetModal } from '../Scheduler_v2/Modals/BudgetModal/BudgetModal'
import { PortfolioScheduleAnalyticsModal } from './PortfolioScheduleAnalyticsModal'
import { PortfolioVendorAnalytics } from './PortfolioVendorAnalytics'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { ReactComponent as UnitNotesIcon } from '../../assets/icons/unitnotes.svg'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

interface Props {
    filterOpen: boolean
    filterCount: number
    showActiveSchedules: boolean
}

export const PortfolioScheduleList = (props: Props) => {
    const { showActiveSchedules } = props

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const portfolioScheduleList = useSelector(
        (state: RootState) => state.workorder.schedule.portfolioScheduleList,
    )
    const isPortfolioScheduleLoading = useSelector(
        (state: RootState) =>
            state.workorder.schedule.isLoading[
                GET_PORTFOLIO_SCHEDULE_LIST_REQUEST
            ],
    )

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const nextLink = useSelector(
        (state: RootState) =>
            state.pagination.GET_PORTFOLIO_SCHEDULE_LIST_REQUEST?.next,
    )
    const atBottomRef = useRef<HTMLDivElement>(null)

    const atBottom = useOnScreen(atBottomRef)

    const [
        openProjectNotes,
        setOpenProjectNotes,
    ] = useState<PortfolioSchedule | null>(null)

    useEffect(() => {
        if (atBottom && nextLink) {
            dispatch(getPortfolioScheduleList({ url: nextLink }))
        }
    }, [atBottom])

    let heightPercent = 75
    if (props.filterOpen) {
        heightPercent = heightPercent - 10
        heightPercent = heightPercent - 10 * props.filterCount
    }

    const navigateToScheduleDetail = (scheduleId: number) => {
        const project = portfolioScheduleList?.find(
            (project) => project.id === scheduleId,
        )

        let route = Routes.apartmentScheduleDetail
        if (project && !project.active) {
            route = Routes.apartmentScheduleArchivedDetail
        }
        const params = new URLSearchParams({
            apartment: project?.apartment.id,
            setup: 'false',
        }).toString()

        const url = `${resolveRoute(route, ':id', scheduleId)}?${params}`

        window.open(url, '_blank')
    }

    const { getServiceList } = useService()

    useEffect(() => {
        getServiceList({})
    }, [])

    return (
        <Container
            scrollY
            style={{
                flex: 1,
                flexDirection: 'column',
                maxHeight: 'calc(65vh)',
            }}
        >
            <Container style={{ overflow: 'hidden', flex: 1 }}>
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowY: 'scroll',
                    }}
                >
                    {showActiveSchedules
                        ? portfolioScheduleList
                              ?.filter((schedule) => schedule.active)
                              .map((schedule) => (
                                  <PortfolioScheduleComponent
                                      schedule={schedule}
                                      key={`PORTFOLIO_ANALYTICS_SCHEUDLE_${schedule.id}`}
                                      navigateToScheduleDetail={
                                          navigateToScheduleDetail
                                      }
                                      setOpenProjectNotes={setOpenProjectNotes}
                                      serviceList={serviceList}
                                  />
                              ))
                        : portfolioScheduleList?.map((schedule) => (
                              <PortfolioScheduleComponent
                                  schedule={schedule}
                                  key={`PORTFOLIO_ANALYTICS_SCHEUDLE_${schedule.id}`}
                                  navigateToScheduleDetail={
                                      navigateToScheduleDetail
                                  }
                                  setOpenProjectNotes={setOpenProjectNotes}
                                  serviceList={serviceList}
                              />
                          ))}
                </Container>

                {openProjectNotes?.channel && (
                    <Container
                        style={{
                            width: 400,
                            borderLeft: `1px solid ${theme.palette.grey[700]}`,
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                onClick={() => setOpenProjectNotes(null)}
                            >
                                <ChevronRightIcon />
                            </IconButton>

                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {openProjectNotes.name} - Project Notes
                            </span>
                        </Container>

                        <MessageForm
                            theme={theme}
                            channelId={openProjectNotes.channel}
                        />
                    </Container>
                )}
            </Container>
            <div
                ref={atBottomRef}
                style={{
                    padding: theme.spacing(3),
                    justifyContent: 'center',
                    display: 'flex',
                    flex: 1,
                }}
            >
                {isPortfolioScheduleLoading && <CircularProgress size={100} />}
            </div>
        </Container>
    )
}
const COLUMN_HEAD_HEIGTH = 80
const BAR_COLUMN_WIDTH = 200
interface PortfolioScheduleProps {
    schedule: PortfolioSchedule
    navigateToScheduleDetail: (id: number) => void
    setOpenProjectNotes: (schedule: PortfolioSchedule) => void
    serviceList: Service[]
}

const PortfolioScheduleComponent = (props: PortfolioScheduleProps) => {
    const { schedule, navigateToScheduleDetail, setOpenProjectNotes } = props

    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const [budgetModalOpen, setBudgetModalOpen] = useState(false)
    const [scheduleDetailOpen, setScheduleDetailOpen] = useState(false)
    const [analyticDict, setAnalyticDict] = useState(
        createWorkorderAnalyticDict(),
    )
    const [vendorModalOpen, setVendorModalOpen] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const scheduleBudgetItemList = useSelector(
        (state: RootState) =>
            state.workorder.schedule.portfolioScheduleBudgetItems,
    )
    const scheduleDetail = useSelector(
        (state: RootState) => state.workorder.schedule.portfolioScheudleDetail,
    )

    const scheduleLoading = useSelector(
        (state: RootState) =>
            state.workorder.schedule.isLoading[GET_SCHEDULE_DETAIL_REQUEST],
    )
    const workorderLoading = useSelector(
        (state: RootState) =>
            state.workorder.workorder.isLoading[GET_WORKORDER_LIST_REQUEST],
    )

    const workorderList = useSelector(
        (state: RootState) => state.workorder.workorder.portfolioWorkorderList,
    )

    const textStyle: React.CSSProperties = {
        ...theme.typography.body1,
        marginRight: theme.spacing(2),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }

    useEffect(() => {
        if (
            !scheduleLoading &&
            !workorderLoading &&
            scheduleDetail &&
            scheduleDetail.id === schedule.id
        ) {
            const mutableAnalyticDict = createWorkorderAnalyticDict()

            workorderList.forEach((wo) => {
                addWorkorderToAnalytics(mutableAnalyticDict, wo)
                const woPrice = getTotalPriceExpense(wo)

                if (wo.service_id) {
                    if (
                        !(wo.service_id in mutableAnalyticDict.serviceBudgets)
                    ) {
                        mutableAnalyticDict.serviceBudgets[wo.service_id] = {
                            serviceName: wo.service_name ?? '',
                            totalBudget: 0,
                            invoicedBudget: 0,
                        }
                    }

                    mutableAnalyticDict.forecastedBudget += woPrice
                    mutableAnalyticDict.serviceForecastedBudget += woPrice
                    mutableAnalyticDict.serviceBudgets[
                        wo.service_id
                    ].totalBudget += woPrice
                    if (wo.status === WorkorderStatus.INVOICED) {
                        mutableAnalyticDict.serviceBudgets[
                            wo.service_id
                        ].invoicedBudget += woPrice
                        mutableAnalyticDict.invoicedBudget += woPrice
                    }
                }
            })

            // for each schedule budget item, add its cost to forecasted cost
            scheduleBudgetItemList?.forEach((item) => {
                mutableAnalyticDict.forecastedBudget += item.cost
                mutableAnalyticDict.lineItemForecastedBudget += item.cost
            })
            mutableAnalyticDict.unitCount = scheduleDetail.unit_count ?? 0
            setAnalyticDict(mutableAnalyticDict)
        }
    }, [scheduleDetail, workorderList, scheduleLoading, workorderLoading])

    const now = new Date()
    const startDate = new Date(schedule.start_date)
    const endDate = new Date(schedule.end_date)

    const differenceInTime = endDate.getTime() - now.getTime()

    const differenceInDays = (differenceInTime / (1000 * 3600 * 24)).toFixed(0)

    let completePercent = 0
    let approvedPercent = 0
    let invoicedPercent = 0

    if (schedule.assigned_count > 0) {
        completePercent =
            (schedule.complete_count / schedule.assigned_count) * 100
        approvedPercent =
            (schedule.approved_count / schedule.assigned_count) * 100
        invoicedPercent =
            (schedule.invoiced_count / schedule.assigned_count) * 100
    }

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const popOverOpen = Boolean(anchorEl)

    return (
        <Container
            style={{
                marginBottom: theme.spacing(1),
                height: 220,
                border: `1px solid ${theme.palette.common.black}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                boxShadow: theme.shadows[3],
                backgroundColor: theme.palette.grey[100],
            }}
        >
            {/* property info */}

            <Container
                direction="column"
                style={{
                    marginRight: theme.spacing(2),
                    borderRight: `1px solid ${theme.palette.common.black}`,
                    minWidth: 150,
                    maxWidth: 150,
                }}
            >
                {/* apartment name */}
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginLeft: theme.spacing(0.5),
                        }}
                    >
                        {schedule.apartment.name}
                    </span>
                </Container>
                {/* City and State */}
                <Container style={{ alignItems: 'center' }}>
                    <LocationOn />
                    <span style={{ ...theme.typography.body1 }}>
                        {schedule.apartment.address}, {schedule.apartment.city},{' '}
                        {schedule.apartment.state} {schedule.apartment.zip_code}
                    </span>
                </Container>
            </Container>

            {/* sch info */}
            <Container
                style={{
                    marginRight: theme.spacing(2),
                    paddingRight: theme.spacing(1),
                    borderRight: `1px solid ${theme.palette.common.black}`,
                    minWidth: 500,
                    flexDirection: 'column',
                }}
            >
                <Container style={{ height: COLUMN_HEAD_HEIGTH }}>
                    <Container style={{ flexDirection: 'column', flex: 1 }}>
                        <Container style={{ flex: 1 }}>
                            <Container style={{ flexDirection: 'column' }}>
                                <Container style={{ alignItems: 'center' }}>
                                    <Tooltip title={`Go to ${schedule.name}`}>
                                        <IconButton
                                            size="small"
                                            style={{
                                                marginRight: theme.spacing(1),
                                            }}
                                            onClick={() => {
                                                navigateToScheduleDetail(
                                                    schedule.id,
                                                )
                                            }}
                                        >
                                            <ExitToAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <span
                                        style={{
                                            ...theme.typography.h6,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {schedule.name}
                                    </span>
                                </Container>
                                <Container>
                                    <EllipsisSpan style={textStyle}>
                                        {' '}
                                        {toMMDDYYYY(startDate)} -{' '}
                                        {toMMDDYYYY(endDate)}
                                    </EllipsisSpan>
                                    <EllipsisSpan style={{ ...textStyle }}>
                                        {' '}
                                        {differenceInDays} days left
                                    </EllipsisSpan>
                                </Container>
                            </Container>
                            <div style={{ flex: 1 }} />
                            <div>
                                <Tooltip title="Project Notes">
                                    <IconButton
                                        onClick={() => {
                                            if (schedule.channel) {
                                                setOpenProjectNotes(schedule)
                                            }
                                        }}
                                    >
                                        <UnitNotesIcon fontSize="default" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Schedule Analytics">
                                    <IconButton
                                        onClick={() => {
                                            setScheduleDetailOpen(true)
                                            dispatch(
                                                getScheduleDetail({
                                                    scheduleId: schedule.id,
                                                    params: { portfolio: true },
                                                    scheduleCallbacks: [
                                                        setPortfolioScheduleDetail,
                                                    ],
                                                    scheduleBudgetItemsCallbacks: [
                                                        setPortfolioScheduleBudgetItemList,
                                                    ],
                                                }),
                                            )
                                            dispatch(
                                                getWorkOrderList({
                                                    isForPortfolioSchedule: true,
                                                    params: {
                                                        schedule: schedule.id,
                                                        portfolio: true,
                                                    },
                                                }),
                                            )
                                        }}
                                    >
                                        <BarChart fontSize="default" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Budget Analytics">
                                    <IconButton
                                        onClick={() => {
                                            dispatch(
                                                getScheduleDetail({
                                                    scheduleId: schedule.id,
                                                    params: { portfolio: true },
                                                    scheduleCallbacks: [
                                                        setPortfolioScheduleDetail,
                                                    ],
                                                    scheduleBudgetItemsCallbacks: [
                                                        setPortfolioScheduleBudgetItemList,
                                                    ],
                                                }),
                                            ).then(() => {
                                                setBudgetModalOpen(true)
                                            })
                                            dispatch(
                                                getWorkOrderList({
                                                    isForPortfolioSchedule: true,
                                                    params: {
                                                        schedule: schedule.id,
                                                        portfolio: true,
                                                    },
                                                }),
                                            )
                                        }}
                                    >
                                        <MonetizationOn fontSize="default" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Vendor Analytics">
                                    <IconButton
                                        onClick={() => {
                                            setVendorModalOpen(true)
                                            dispatch(
                                                getVendorList({
                                                    params: {
                                                        portfolio_apartment:
                                                            schedule.apartment
                                                                .id,
                                                    },
                                                }),
                                            )
                                        }}
                                    >
                                        <FormatPaint fontSize="default" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Container>
                    </Container>
                </Container>

                <Container style={{ flex: 1, flexDirection: 'column' }}>
                    <Container style={{ alignItems: 'center' }}>
                        <EllipsisSpan
                            style={{ ...textStyle, width: BAR_COLUMN_WIDTH }}
                        >
                            Complete: {completePercent.toFixed(2)}%
                        </EllipsisSpan>
                        <StyledProgressBar
                            value={completePercent}
                            variant="determinate"
                            barColor={theme.palette.info.main}
                        />
                    </Container>
                    <Container style={{ alignItems: 'center' }}>
                        <EllipsisSpan
                            style={{ ...textStyle, width: BAR_COLUMN_WIDTH }}
                        >
                            Approved: {approvedPercent.toFixed(2)}%
                        </EllipsisSpan>
                        <StyledProgressBar
                            value={approvedPercent}
                            variant="determinate"
                            barColor={theme.palette.secondary.main}
                        />
                    </Container>
                    <Container style={{ alignItems: 'center' }}>
                        <EllipsisSpan
                            style={{ ...textStyle, width: BAR_COLUMN_WIDTH }}
                        >
                            Invoiced: {invoicedPercent.toFixed(2)}%
                        </EllipsisSpan>
                        <StyledProgressBar
                            value={invoicedPercent}
                            variant="determinate"
                            barColor={theme.palette.primary.main}
                        />
                    </Container>
                </Container>
                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <EllipsisSpan style={textStyle}>
                            Set Budget:
                        </EllipsisSpan>
                        <EllipsisSpan
                            style={{
                                ...textStyle,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(schedule.baseline_budget)}
                        </EllipsisSpan>
                    </Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <EllipsisSpan style={textStyle}>
                            Forecasted Budget:{' '}
                        </EllipsisSpan>
                        <EllipsisSpan
                            style={{
                                ...textStyle,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(
                                schedule.forecasted_budget,
                            )}
                        </EllipsisSpan>
                    </Container>
                    {schedule.pre_turn_denominator > 0 && (
                        <Container style={{ flexDirection: 'column' }}>
                            <EllipsisSpan style={textStyle}>
                                Checklist:{' '}
                            </EllipsisSpan>
                            <EllipsisSpan
                                style={{
                                    ...textStyle,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                                onClick={(e) => {
                                    openPopover(e)
                                }}
                                className={classes.hoverGrey200}
                            >
                                {`${schedule.pre_turn_numerator}`}/
                                {`${schedule.pre_turn_denominator}`}
                            </EllipsisSpan>
                        </Container>
                    )}
                </Container>
            </Container>
            {/* ins info */}
            {schedule.inspection ? (
                <Container style={{ flexDirection: 'column' }}>
                    <Container style={{ height: COLUMN_HEAD_HEIGTH }}>
                        <Container style={{ flexDirection: 'column' }}>
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {schedule.inspection_name}
                            </span>
                            <EllipsisSpan style={textStyle}>
                                {' '}
                                {toMMDDYYYY(
                                    new Date(
                                        schedule.inspection_start_date ?? '',
                                    ),
                                )}{' '}
                                -{' '}
                                {toMMDDYYYY(
                                    new Date(
                                        schedule.inspection_end_date ?? '',
                                    ),
                                )}
                            </EllipsisSpan>
                        </Container>
                    </Container>
                    <Container style={{ flex: 1, flexDirection: 'column' }}>
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{
                                    ...textStyle,
                                    width: BAR_COLUMN_WIDTH,
                                }}
                            >
                                Assigned:{' '}
                                {(schedule.inspection_assigned * 100).toFixed(
                                    2,
                                )}
                                %
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={schedule.inspection_assigned * 100}
                                variant="determinate"
                                barColor={theme.palette.info.main}
                            />
                        </Container>
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{
                                    ...textStyle,
                                    width: BAR_COLUMN_WIDTH,
                                }}
                            >
                                Complete:{' '}
                                {(schedule.inspection_complete * 100).toFixed(
                                    2,
                                )}
                                %
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={schedule.inspection_complete * 100}
                                variant="determinate"
                                barColor={theme.palette.secondary.main}
                            />
                        </Container>
                        <Container style={{ alignItems: 'center' }}>
                            <EllipsisSpan
                                style={{
                                    ...textStyle,
                                    width: BAR_COLUMN_WIDTH,
                                }}
                            >
                                Handled:{' '}
                                {(schedule.inspection_handled * 100).toFixed(2)}
                                %
                            </EllipsisSpan>
                            <StyledProgressBar
                                value={schedule.inspection_handled * 100}
                                variant="determinate"
                                barColor={theme.palette.primary.main}
                            />
                        </Container>
                    </Container>
                </Container>
            ) : (
                <span
                    style={{
                        ...theme.typography.body1,
                        marginRight: theme.spacing(2),
                    }}
                >
                    {' '}
                    No Inspection
                </span>
            )}
            {budgetModalOpen && (
                <BudgetModal
                    open={budgetModalOpen}
                    onClose={() => setBudgetModalOpen(false)}
                    workorders={workorderList}
                    serviceList={props.serviceList}
                    scheduleDetail={scheduleDetail}
                    portfolio
                />
            )}
            <PortfolioScheduleAnalyticsModal
                open={scheduleDetailOpen}
                schedule={schedule}
                analyticDict={analyticDict}
                onClose={() => setScheduleDetailOpen(false)}
            />
            <PortfolioVendorAnalytics
                open={vendorModalOpen}
                onClose={() => {
                    setVendorModalOpen(false)
                    dispatch(setPortfolioVendorAnalytics(undefined))
                }}
                schedule={schedule}
            />
            <SchedulePreTurnChecklist
                open={popOverOpen}
                schedule={schedule}
                onClose={closePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                portfolio
            />
        </Container>
    )
}
