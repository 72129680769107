import React, { useState } from 'react'
import { Container } from '../../components'
import {
    Button,
    Divider,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import { SupportRequestForm } from '../../components/SupportRequestForm/SupportRequestForm'
import { ScheduleAppointment } from '../../components/CalendlyAppointment/ScheduleAppointment'
import { Vidyard } from '../../components/VidyardEmbed'
import { DisplayVideo } from './DisplayVideo'

export const VendorHelp = () => {
    const [supportModalOpen, setSupportModalOpen] = useState(false)

    const theme = useTheme()

    return (
        <Container
            direction="column"
            flex={1}
            style={{
                padding: theme.spacing(4, 3, 3, 3),
            }}
        >
            <Container
                style={{
                    fontWeight: 400,
                    fontSize: '15px',
                    color: theme.palette.darkGreen.main,
                    marginBottom: theme.spacing(3),
                }}
            >
                <span
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    Help Screen
                </span>
            </Container>
            <Container
                style={{
                    fontWeight: 700,
                    fontSize: '40px',
                    marginBottom: theme.spacing(2),
                }}
            >
                Videos From Us To Help
            </Container>
            <Container
                style={{
                    marginBottom: theme.spacing(2),
                }}
            >
                <Container
                    style={{
                        fontSize: '15px',
                        fontWeight: 400,
                    }}
                >
                    Below you will find some helpful videos we created to guide
                    you through the site!
                </Container>
                <div style={{ flex: 1 }} />
                <Button
                    variant="outlined"
                    onClick={() => setSupportModalOpen(true)}
                    style={{
                        color: theme.palette.darkGreen.main,
                        textTransform: 'none',
                        borderColor: theme.palette.lightGrey.main,
                    }}
                >
                    Request Help
                </Button>
            </Container>
            <Divider />
            <Container flex={1} direction="column">
                <Container
                    style={{
                        marginTop: theme.spacing(3),
                    }}
                    direction="column"
                >
                    <Container
                        direction="column"
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: '15px',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Vendor Mobile Training
                        </span>
                        <span
                            style={{
                                fontWeight: 400,
                                fontSize: '13px',
                                color: theme.palette.darkGreen.main,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Here is where you will find the mobile trainings for
                            vendors in both English and Spanish
                        </span>
                    </Container>
                    <Container
                        style={{
                            height: screen.height / 4,
                            width: screen.width / 3,
                        }}
                    >
                        <DisplayVideo
                            url={
                                'https://play.vidyard.com/V55f8eDzVNu3NmLT1oKgWF.jpg'
                            }
                            uuid={'V55f8eDzVNu3NmLT1oKgWF'}
                            height={screen.height / 4}
                            width={screen.width / 3}
                        />
                    </Container>
                </Container>
            </Container>

            <Modal
                open={supportModalOpen}
                onClose={() => setSupportModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={supportModalOpen}>
                    <Paper style={{ width: '90%', height: '90%' }}>
                        <SupportRequestForm />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
