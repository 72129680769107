import { Service } from '..'
import { Address, IdentifiableNamedObject, IdentifiableObject } from '../types'
import { User } from '../Users'

export interface Company extends IdentifiableNamedObject {
    //this is the response from the company list serializer
    address: string
    city: string
    state: string
    zip_code: string
    phone: string | null
    company_type: CompanyType
    turn_co_sheet_vendor: number | null
    email?: string
    employees?: User[]
    organization: number
    organization_name: string
    entrata_integrated: boolean
    move_out_inspection_config?: number | null
    property_manager?: User | null
    profile_picture: string | null
    url: string | null
    inv_sheet_uploaded: boolean
    area_sheet_uploaded: boolean
    infrastructure_sheet_uploaded: boolean
    status_group_sheet_uploaded: boolean
    unit_sheet_uploaded: boolean
    allow_service_request: boolean | null
    stripe_subscription: StripeSubscription | null
    has_infrastructure: boolean
    account_manager_channel: number | null
    register_step?: RegisterStep
    skillcat?: boolean
    run_auto_move_out_events: boolean
    run_inspection_frequencies: boolean
    run_action_item_frequencies: boolean
    days_before_move_out_schedule: number | null
    company_hours?: CompanyHours | null
    on_call_backups?: OnCallBackups | null
}

export interface ListVendor extends BaseVendor {
    // the list serializer with all the things that are unique to the detail serializer listed as optional
    services: Service[]
    in_house?: number | null
    my_team?: boolean
    employees?: User[]
    wo_count?: number
    sch_count?: number
    tagline?: string
    url?: string
    sponsor_image?: string
    will_travel?: boolean
    plan: string | null
    step?: string
    imported?: boolean
    featured_image?: string
    description?: string
    documents?: VendorDocuments
    tokens?: number
    entrata_sync_enabled?: boolean
    profile_picture?: string
    register_step?: RegisterStep
    is_skillcat: boolean
    company_hours?: CompanyHours | null
}

export interface BaseVendor extends IdentifiableNamedObject {
    address: string
    city: string
    state: string
    zip_code: string
    phone: string | null
    email?: string
}

export interface RatingInterface {
    professionalism: number
    work_quality: number
}

export interface VendorScheduleRating
    extends IdentifiableObject,
        RatingInterface {
    vendor: number
    schedule: number
}

export interface VendorAd extends IdentifiableObject {
    vendor: number
    ad: string
}

export interface VendorDocuments {
    reference_1?: Document
    reference_2?: Document
    reference_3?: Document
    insurance_1?: Document
    insurance_2?: Document
    w9_document?: Document
}

export interface Document {
    name?: string
    url?: string
}

export enum PlanStatus {
    NO_PLAN = 'NO_PLAN',
    EZVENDOR = 'EZVENDOR',
    PREMIUM = 'PREMIUM',
}

export interface StripeSubscription {
    plan: PlanStatus
    stripe_customer_id: string
}

export interface SimpleVendor extends IdentifiableNamedObject {
    primary_address: Address
    is_trained: boolean
    will_travel: boolean
    profile_picture: string
    is_skillcat: boolean
    complete_count?: number
    description?: string
    phone?: string
}

export interface BaseOrganization {
    id: number
    name: string
}

export interface Organization extends IdentifiableNamedObject {
    entrata_username: string
    entrata_password: string
    entrata_endpoint: string
}

export enum RegisterStep {
    DETAIL_STEP = 0,
    SERVICE_AREA_STEP = 1,
    SELECT_SERVICES_STEP = 2,
    AGREEMENTS_STEP = 3,
    COMPLETED_STEP = 4,
}

export interface BaseCompany {
    id: number
    name: string
    company_type: CompanyType
    primary_address: Address | null
    organization: IdentifiableNamedObject
}

export interface CompanyHours extends IdentifiableObject {
    monday_start: string | null
    monday_end: string | null
    monday_closed: boolean
    tuesday_start: string | null
    tuesday_end: string | null
    tuesday_closed: boolean
    wednesday_start: string | null
    wednesday_end: string | null
    wednesday_closed: boolean
    thursday_start: string | null
    thursday_end: string | null
    thursday_closed: boolean
    friday_start: string | null
    friday_end: string | null
    friday_closed: boolean
    saturday_start: string | null
    saturday_end: string | null
    saturday_closed: boolean
    sunday_start: string | null
    sunday_end: string | null
    sunday_closed: boolean
}

export interface ApartmentVendorRule extends IdentifiableObject {
    apartment: number
    service: number
    vendor: number
    rank: number
    buffer_days: number
    created_date: string
    modified_date: string
}

export interface OnCallBackups extends IdentifiableObject {
    backup_user_one: User | null
    backup_user_two: User | null
    backup_user_three: User | null
    created_date: string
    modified_date: string
}

export interface OnCallDay extends IdentifiableObject {
    user: User
    apartment: number
    start_time: string
    end_time: string
    on_call_date: string
    created_date: string
    modified_date: string
}
