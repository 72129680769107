import React from 'react'

import { Button, Theme, useTheme } from '@material-ui/core'
import { format } from 'date-fns'

import { Attachment, Container } from '../../components'
import { SupportTicket, SupportTicketStatus } from '../../models'
import { normalizeString } from '../../helpers'

interface SupportTicketRowProps {
    ticket: SupportTicket
    theme: Theme
    handleComplete: () => void
}

export const SupportTicketRow: React.FC<SupportTicketRowProps> = ({
    ticket,
    theme,
    handleComplete,
}) => {
    const LabelValuePair = ({
        label,
        value,
    }: {
        label: string
        value: React.ReactNode
    }) => (
        <Container style={{ justifyContent: 'space-between', width: '100%' }}>
            <span style={{ fontWeight: 'bold' }}>{label}:</span>
            <span style={{ marginLeft: theme.spacing(2) }}>{value}</span>
        </Container>
    )

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
                flexDirection: 'column',
                gap: theme.spacing(2),
            }}
        >
            {/* User Info and Ticket Info Section */}
            <Container style={{ gap: theme.spacing(4) }}>
                {/* User Info Section */}
                <Container
                    style={{
                        flexDirection: 'column',
                        gap: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 'bold',
                        }}
                    >
                        User Information
                    </span>
                    <Container
                        style={{
                            flexDirection: 'column',
                            gap: theme.spacing(1),
                        }}
                    >
                        <LabelValuePair label="Name" value={ticket.name} />
                        <LabelValuePair
                            label="Email"
                            value={ticket.email || 'N/A'}
                        />
                        <LabelValuePair
                            label="Phone"
                            value={ticket.phone_number || 'N/A'}
                        />
                        <LabelValuePair
                            label="Property Info"
                            value={ticket.property_info || 'N/A'}
                        />
                        <LabelValuePair
                            label="User Type"
                            value={
                                ticket.is_vendor ? 'Vendor' : 'Property User'
                            }
                        />
                    </Container>
                </Container>

                {/* Ticket Info Section */}
                <Container
                    style={{
                        flexDirection: 'column',
                        gap: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 'bold',
                        }}
                    >
                        Ticket Information
                    </span>
                    <Container
                        style={{
                            flexDirection: 'column',
                            gap: theme.spacing(1),
                        }}
                    >
                        <LabelValuePair
                            label="Created Date"
                            value={
                                ticket.created_date
                                    ? format(
                                          new Date(ticket.created_date),
                                          'MMM dd, yyyy',
                                      )
                                    : 'N/A'
                            }
                        />
                        <LabelValuePair
                            label="Status"
                            value={
                                <span
                                    style={{
                                        color:
                                            ticket.status === 'OPEN'
                                                ? theme.palette.error.main
                                                : theme.palette.success.main,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {normalizeString(ticket.status as string)}
                                </span>
                            }
                        />
                        <LabelValuePair
                            label="Source"
                            value={
                                ticket.twilio_call_event
                                    ? 'Phone Call'
                                    : 'Web Form'
                            }
                        />
                        <LabelValuePair
                            label="Category"
                            value={
                                ticket.category
                                    ? normalizeString(ticket.category as string)
                                    : 'N/A'
                            }
                        />
                        <LabelValuePair
                            label="Sub-category"
                            value={
                                ticket.sub_category
                                    ? normalizeString(
                                          ticket.sub_category as string,
                                      )
                                    : 'N/A'
                            }
                        />
                        <LabelValuePair
                            label="Completed By"
                            value={
                                ticket.completed_by
                                    ? ticket.completed_by.name
                                    : 'N/A'
                            }
                        />
                    </Container>
                </Container>
            </Container>

            {/* Bottom Section - Issue Description and Attachments */}
            <Container style={{ gap: theme.spacing(4) }}>
                {/* Issue Description Section */}
                <Container
                    style={{
                        flexDirection: 'column',
                        gap: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 'bold',
                        }}
                    >
                        Issue Description
                    </span>
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: theme.shape.borderRadius,
                            minHeight: '100px',
                            maxHeight: '200px',
                            overflowY: 'auto',
                        }}
                    >
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            {ticket.issue_description}
                        </span>
                    </Container>
                </Container>

                {/* Attachments Section */}
                <Container
                    style={{
                        flexDirection: 'column',
                        gap: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 'bold',
                        }}
                    >
                        Attachments{' '}
                        {ticket.attachments.length > 0 &&
                            `(${ticket.attachments.length})`}
                    </span>
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: theme.shape.borderRadius,
                            minHeight: '100px',
                            maxHeight: '200px',
                            overflowX: 'auto',
                        }}
                    >
                        <Container
                            style={{
                                gap: theme.spacing(2),
                                flexWrap: 'nowrap',
                                width: 'fit-content',
                            }}
                        >
                            {ticket.attachments.length === 0 ? (
                                <span
                                    style={{
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    No attachments
                                </span>
                            ) : (
                                ticket.attachments.map((attachment) => (
                                    <div
                                        key={`ATTACHMENT_${attachment.id}`}
                                        style={{}}
                                    >
                                        <Attachment
                                            attachment={attachment.file}
                                            uploaded={true}
                                            theme={theme}
                                            size={150}
                                        />
                                    </div>
                                ))
                            )}
                        </Container>
                    </Container>
                </Container>
            </Container>

            {/* Complete Button Section */}
            {ticket.status === SupportTicketStatus.OPEN && (
                <Container
                    style={{
                        justifyContent: 'flex-end',
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleComplete}
                        style={{
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.common.white,
                        }}
                    >
                        Complete Ticket
                    </Button>
                </Container>
            )}
        </Container>
    )
}
