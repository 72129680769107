import React from 'react'
import { Container } from './Container'
import { ToggleButtonGroup } from '@material-ui/lab'
import { Button, IconButton, Tooltip, useTheme } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Check } from '@material-ui/icons'
import { Range } from 'react-date-range'

export type DateFilter =
    | typeof ALL
    | typeof THIS_MONTH
    | typeof LAST_MONTH
    | typeof CUSTOM

interface Props {
    dateFilter: DateFilter
    dateRange: Range
    excludeAll?: boolean
    setStartDate: (date: Date) => void
    setEndDate: (date: Date) => void
    setDateFilter: (dateFilter: DateFilter) => void
    onApply: () => void
}

export const DateFilterButtons = (props: Props) => {
    const {
        dateFilter,
        dateRange,
        excludeAll,
        setStartDate,
        setEndDate,
        setDateFilter,
        onApply,
    } = props

    const theme = useTheme()

    const ButtonGroupStyle: React.CSSProperties = {
        textTransform: 'none',
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
    }

    return (
        <Container>
            <ToggleButtonGroup
                value={dateFilter}
                aria-label="Date Filter"
                exclusive
                style={{
                    border: `1px solid ${theme.palette.primary.dark}`,
                    borderRadius: '5px',
                }}
            >
                {!excludeAll && (
                    <Button
                        value={ALL}
                        aria-label="All"
                        style={{
                            ...ButtonGroupStyle,
                            borderRight: `1px solid ${theme.palette.primary.dark}`,
                            backgroundColor:
                                dateFilter === ALL
                                    ? theme.palette.primary.dark
                                    : '',
                            color: dateFilter === ALL ? 'white' : '',
                        }}
                        onClick={() => {
                            setDateFilter(ALL)
                        }}
                    >
                        All
                    </Button>
                )}
                <Button
                    value={THIS_MONTH}
                    aria-label="This Month"
                    style={{
                        ...ButtonGroupStyle,
                        borderRight: `1px solid ${theme.palette.primary.dark}`,
                        backgroundColor:
                            dateFilter === THIS_MONTH
                                ? theme.palette.primary.dark
                                : '',
                        color: dateFilter === THIS_MONTH ? 'white' : '',
                    }}
                    onClick={() => {
                        setDateFilter(THIS_MONTH)
                    }}
                >
                    This Month
                </Button>
                <Button
                    value={LAST_MONTH}
                    aria-label="Last Month"
                    style={{
                        ...ButtonGroupStyle,
                        backgroundColor:
                            dateFilter === LAST_MONTH
                                ? theme.palette.primary.dark
                                : '',
                        color: dateFilter === LAST_MONTH ? 'white' : '',
                        borderRight: `1px solid ${theme.palette.primary.dark}`,
                    }}
                    onClick={() => {
                        setDateFilter(LAST_MONTH)
                    }}
                >
                    Last Month
                </Button>
                <Button
                    value={CUSTOM}
                    aria-label="Custom"
                    style={{
                        ...ButtonGroupStyle,
                        backgroundColor:
                            dateFilter === CUSTOM
                                ? theme.palette.primary.dark
                                : '',
                        color: dateFilter === CUSTOM ? 'white' : '',
                    }}
                    onClick={() => setDateFilter(CUSTOM)}
                >
                    Custom
                </Button>
            </ToggleButtonGroup>
            <KeyboardDatePicker
                disabled={dateFilter !== CUSTOM}
                value={dateRange.startDate}
                onChange={(date) => {
                    if (date === null) {
                        return
                    }
                    setStartDate(date)
                }}
                format="MM/dd/yyyy"
                inputVariant="outlined"
                style={{
                    margin: theme.spacing(0, 2),
                    width: 180,
                    marginLeft: theme.spacing(2),
                }}
                label="Start Date"
                size="small"
            />
            <KeyboardDatePicker
                disabled={dateFilter !== CUSTOM}
                error={dateRange.endDate === null}
                value={dateRange.endDate}
                onChange={(date) => {
                    if (date === null) {
                        return
                    }
                    setEndDate(date)
                }}
                format="MM/dd/yyyy"
                inputVariant="outlined"
                label="End Date"
                style={{
                    margin: theme.spacing(0, 2),
                    width: 180,
                }}
                size="small"
            />
            {dateFilter === CUSTOM && (
                <Tooltip title="Apply">
                    <IconButton
                        component="span"
                        onClick={() => onApply()}
                        style={{ padding: '6px' }}
                    >
                        <Check />
                    </IconButton>
                </Tooltip>
            )}
        </Container>
    )
}

export const ALL = 0
export const THIS_MONTH = 1
export const LAST_MONTH = 2
export const CUSTOM = 3
