import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { usePropertyFolders } from '../hooks/usePropertyFolders'
import { usePropertyUnits } from '../hooks/usePropertyUnits'
import { Container } from '../../../../components'
import { useTheme } from '@material-ui/core'
import { TreeData } from '../types'
import { BulkUnitsModal, Tree } from './Components'
import { buildTree } from './utils'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import AddIcon from '@material-ui/icons/Add'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import CloseIcon from '@material-ui/icons/Close'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { DragProvider } from '../../../../components/DragDrop/DragContext'
import { DraggableItem } from '../../../../components/DragDrop/types'
import { FolderMinified } from '../../../../models/Infrastructure/types'
import { FolderUnitDialog } from './Components/RenameDialog'

export const PropertyDetail = () => {
    const { id } = useParams<{ id: string }>()
    const {
        units,
        isLoading: unitsLoading,
        moveUnit,
        addUnits,
        renameUnit,
    } = usePropertyUnits(id)

    const {
        folders,
        isLoading: foldersLoading,
        moveFolder,
        renameFolder,
        createFolder,
    } = usePropertyFolders(id)

    // Add state for modal
    const [
        bulkUnitsModalOpen,
        setBulkUnitsModalOpen,
    ] = useState<FolderMinified | null>(null)

    const isLoading = unitsLoading || foldersLoading

    const theme = useTheme()
    const [tree, setTree] = useState<TreeData | null>(null)

    const [openMap, setOpenMap] = useState(new Map<string, boolean>())

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
        folderId: string
        itemType: 'unit' | 'folder'
    } | null>(null)

    const [renameDialog, setRenameDialog] = useState<{
        open: boolean
        itemType: 'folder' | 'unit'
        mode: 'rename' | 'add'
        itemId: string
        parentId?: string | null
    } | null>(null)

    useEffect(() => {
        if (folders && units) {
            const treeData = buildTree(folders, units)
            setTree(treeData)
        }
    }, [folders, units])

    const handleDrop = (
        draggingItem: DraggableItem,
        droppingItem: DraggableItem,
    ) => {
        let newParentId = droppingItem.id
        if (droppingItem.id === null) {
            newParentId = 'root'
        }

        if (draggingItem.id && newParentId) {
            if (draggingItem.dragType === 'folder') {
                moveFolder.mutate({
                    folderId: draggingItem.id,
                    newParentId: newParentId,
                })
            } else if (
                draggingItem.dragType === 'unit' &&
                newParentId !== 'root'
            ) {
                console.log('moveUnit', draggingItem.id, newParentId)
                moveUnit.mutate({
                    unitId: draggingItem.id,
                    newFolderId: newParentId,
                })
            }
        }
    }

    const handleOpenBulkUnitModal = () => {
        const folderId = contextMenu?.folderId
        if (!folderId) return
        const numFolderId = Number(folderId)

        if (isNaN(numFolderId)) return

        const folder = folders?.find((f) => f.id === numFolderId)

        if (!folder) return

        setBulkUnitsModalOpen(folder)
        handleClose()
    }

    const handleContextMenu = (
        event: React.MouseEvent,
        id: string,
        itemType: 'unit' | 'folder',
    ) => {
        event.preventDefault()
        setContextMenu({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            folderId: id,
            itemType,
        })
    }

    const handleClose = () => {
        setContextMenu(null)
    }

    const handleRename = () => {
        if (!contextMenu?.folderId || !contextMenu?.itemType) return

        setRenameDialog({
            open: true,
            itemType: contextMenu.itemType,
            mode: 'rename',
            itemId: contextMenu.folderId,
        })
        handleClose()
    }

    const handleRenameSubmit = (newName: string) => {
        if (!renameDialog) return

        if (renameDialog.itemType === 'folder') {
            if (renameDialog.mode === 'add') {
                createFolder.mutate({
                    name: newName,
                    parentId: renameDialog.parentId || null,
                })
            } else {
                renameFolder.mutate({
                    folderId: renameDialog.itemId,
                    newName,
                })
            }
        } else {
            renameUnit.mutate({
                unitId: renameDialog.itemId,
                newName,
            })
        }
        setRenameDialog(null)
    }

    const getCurrentName = () => {
        if (!renameDialog) return ''
        if (renameDialog.mode === 'add') return ''

        if (renameDialog.itemType === 'folder') {
            return (
                folders?.find(
                    (folder) => folder.id.toString() === renameDialog.itemId,
                )?.name || ''
            )
        } else {
            return (
                units?.find(
                    (unit) => unit.id.toString() === renameDialog.itemId,
                )?.name || ''
            )
        }
    }

    const handleAddFolder = () => {
        if (!contextMenu?.folderId) return

        setRenameDialog({
            open: true,
            itemType: 'folder',
            mode: 'add',
            itemId: 'new',
            parentId:
                contextMenu.folderId === 'root' ? null : contextMenu.folderId,
        })
        handleClose()
    }

    const handleAddUnit = () => {
        console.log('Add unit to:', contextMenu?.folderId)
        handleClose()
    }

    if (isLoading) return <div>Loading...</div>
    if (!tree) return <div>No folders</div>

    return (
        <Container>
            <DragProvider>
                <Tree
                    treeData={tree}
                    depth={0}
                    openMap={openMap}
                    setOpenMap={setOpenMap}
                    theme={theme}
                    handleDrop={handleDrop}
                    onContextMenu={handleContextMenu}
                />
            </DragProvider>
            <Menu
                keepMounted
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                disableRestoreFocus
                disableAutoFocusItem
            >
                <MenuItem onClick={handleRename}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    Rename
                </MenuItem>
                {contextMenu?.itemType === 'folder' && (
                    <>
                        <MenuItem onClick={handleAddFolder}>
                            <ListItemIcon>
                                <CreateNewFolderIcon fontSize="small" />
                            </ListItemIcon>
                            Add Folder
                        </MenuItem>
                        <MenuItem onClick={handleAddUnit}>
                            <ListItemIcon>
                                <AddIcon fontSize="small" />
                            </ListItemIcon>
                            Add Unit
                        </MenuItem>
                        <MenuItem onClick={handleOpenBulkUnitModal}>
                            <ListItemIcon>
                                <PlaylistAddIcon fontSize="small" />
                            </ListItemIcon>
                            Bulk Units
                        </MenuItem>
                    </>
                )}
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <CloseIcon fontSize="small" />
                    </ListItemIcon>
                    Cancel
                </MenuItem>
            </Menu>
            <BulkUnitsModal
                selectedFolder={bulkUnitsModalOpen}
                onClose={() => setBulkUnitsModalOpen(null)}
                onSubmit={(units, useAlphabeticalNaming) => {
                    if (bulkUnitsModalOpen) {
                        addUnits.mutate({
                            units: units.map((unit) => ({
                                name: unit.name,
                                bed_count: unit.bedCount,
                                bath_count: unit.bathCount,
                                common_count: unit.commonCount,
                                unit_type: unit.type,
                            })),
                            folderId: bulkUnitsModalOpen.id.toString(),
                            useAlphabeticalNaming,
                        })
                    }

                    setBulkUnitsModalOpen(null)
                    handleClose()
                }}
            />
            <FolderUnitDialog
                open={!!renameDialog?.open}
                onClose={() => setRenameDialog(null)}
                onSubmit={handleRenameSubmit}
                currentName={getCurrentName()}
                itemType={renameDialog?.itemType || 'folder'}
                title={renameDialog?.mode === 'rename' ? 'Rename' : 'Add'}
            />
        </Container>
    )
}
