import { TreeData } from '../types'
import {
    UnitMinified,
    FolderMinified,
} from '../../../../models/Infrastructure/types'

export const buildTree = (
    folders: FolderMinified[],
    units: UnitMinified[],
): TreeData => {
    const treeData: TreeData = {
        id: null,
        name: 'Root',
        isLeaf: false,
        children: [],
    }

    // First, build the folder structure
    const folderStack: TreeData[] = [treeData]
    for (let folderIdx = 0; folderIdx < folders.length; folderIdx++) {
        const folder = folders[folderIdx]
        if (!folder) continue

        const lastParent = folderStack[folderStack.length - 1]

        if (folder.parent === lastParent.id) {
            const newNode: TreeData = {
                id: folder.id.toString(),
                name: folder.name,
                isLeaf: false,
                children: [],
            }
            lastParent.children.push(newNode)
            folderStack.push(newNode)
        } else {
            while (
                folderStack.length > 0 &&
                folderStack[folderStack.length - 1].id?.toString() !==
                    folder.parent?.toString()
            ) {
                folderStack.pop()
                if (folderStack.length === 0) {
                    folderStack.push(treeData)
                    break
                }
            }

            const newNode: TreeData = {
                id: folder.id.toString(),
                name: folder.name,
                isLeaf: false,
                children: [],
            }

            folderStack[folderStack.length - 1].children.push(newNode)
            folderStack.push(newNode)
        }
    }

    // Then, add units as leaf nodes to their respective folders
    const addUnitsToTree = (node: TreeData) => {
        // Add units that belong to this folder
        const folderUnits = units.filter(
            (unit) => unit.folder === Number(node.id),
        )

        // Add units as leaf nodes
        node.children.push(
            ...folderUnits.map((unit) => ({
                id: unit.id.toString(),
                name: unit.name,
                isLeaf: true,
                children: [],
                unitData: unit, // Optional: preserve the original unit data
            })),
        )

        // Recursively process child folders
        node.children
            .filter((child) => !child.isLeaf)
            .forEach((childFolder) => addUnitsToTree(childFolder))
    }

    // Start adding units from the root
    addUnitsToTree(treeData)

    return treeData
}
