import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Paper, Slide, useTheme } from '@material-ui/core'

import { Container, Selector } from '../../components'
import { useAppDispatch } from '../../hooks'
import { ListVendor, PortfolioSchedule } from '../../models'
import { getVendorAnalyticsRequest, RootState } from '../../store'
import { VendorAnalyticsBody } from '../Analytics/VendorAnalyticsBody'

interface Props {
    open: boolean
    onClose: () => void
    schedule: PortfolioSchedule
}

export const PortfolioVendorAnalytics = (props: Props) => {
    const { open, onClose, schedule } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()
    const vendorList = useSelector(
        (state: RootState) => state.company.portfolioVendorList,
    )
    const vendorAnalytics = useSelector(
        (state: RootState) => state.analytics.portfolioVendorAnalytics,
    )
    const [selectedVendorId, setSelectedVendorId] = useState<number>(0)
    useEffect(() => {
        if (selectedVendorId !== 0) {
            dispatch(
                getVendorAnalyticsRequest({
                    params: { vendor: selectedVendorId, schedule: schedule.id },
                }),
            )
        }
    }, [selectedVendorId])

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose()
                setSelectedVendorId(0)
            }}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        width: '70%',
                        height: '70%',
                        flexDirection: 'column',
                    }}
                >
                    {/* header */}
                    <Container style={{ margin: theme.spacing(2) }}>
                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {schedule.apartment.name}: {schedule.name}
                        </span>

                        <div style={{ flex: 1 }} />

                        <Selector
                            label="Vendor"
                            currentValue={selectedVendorId}
                            data={vendorList ?? []}
                            getDisplayString={(v: ListVendor) => v.name}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setSelectedVendorId(
                                    event.target.value as number,
                                )
                            }}
                            customStyle={{ formControl: { minWidth: 300 } }}
                        />
                    </Container>
                    <VendorAnalyticsBody analytics={vendorAnalytics} />
                </Paper>
            </Slide>
        </Modal>
    )
}
