import React, {
    createContext,
    useContext,
    useState,
    PropsWithChildren,
} from 'react'
import { DraggableItem } from './types'

// Define an interface for the selection data

interface DragContextType {
    isDragging: boolean
    setIsDragging: (isDragging: boolean) => void
    selectedMap: Map<string, DraggableItem>
    setSelectedMap: (map: Map<string, DraggableItem>) => void
    addSelectedItems: (items: DraggableItem | DraggableItem[]) => void
    removeSelectedItems: (items: DraggableItem | DraggableItem[]) => void
    clearSelectedItems: () => void
}

const DragContext = createContext<DragContextType>({
    isDragging: false,
    setIsDragging: () => {},
    selectedMap: new Map(),
    setSelectedMap: () => {},
    addSelectedItems: () => {},
    removeSelectedItems: () => {},
    clearSelectedItems: () => {},
})

export const useDragContext = () => useContext(DragContext)

export const DragProvider = ({ children }: PropsWithChildren<{}>) => {
    const [isDragging, setIsDragging] = useState(false)

    const [selectedMap, setSelectedMap] = useState<Map<string, DraggableItem>>(
        new Map(),
    )

    const addSelectedItems = (items: DraggableItem | DraggableItem[]) => {
        const itemsArray = Array.isArray(items) ? items : [items]
        setSelectedMap((prevMap) => {
            const newMap = new Map(prevMap)
            itemsArray.forEach((item) => newMap.set(String(item.id), item))
            return newMap
        })
    }

    const removeSelectedItems = (items: DraggableItem | DraggableItem[]) => {
        const itemsArray = Array.isArray(items) ? items : [items]
        setSelectedMap((prevMap) => {
            const newMap = new Map(prevMap)
            itemsArray.forEach((item) => newMap.delete(String(item.id)))
            return newMap
        })
    }

    const clearSelectedItems = () => {
        setSelectedMap(new Map())
    }

    return (
        <DragContext.Provider
            value={{
                isDragging,
                setIsDragging,
                selectedMap,
                setSelectedMap,
                addSelectedItems,
                removeSelectedItems,
                clearSelectedItems,
            }}
        >
            {children}
        </DragContext.Provider>
    )
}
