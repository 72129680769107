import { useState } from 'react'

import { toast } from 'react-toastify'

import { PortfolioSchedule, SimplePortfolioSchedule } from '../models'
import { axiosInstance } from '../helpers'
import { GetPortfolioScheduleListRequest } from '../store'

export const usePortfolioSchedule = () => {
    const [
        simplePortfolioScheduleList,
        setSimplePortfolioScheduleList,
    ] = useState<SimplePortfolioSchedule[]>([])

    const [
        portfolioScheduleDetail,
        setPortfolioScheduleDetail,
    ] = useState<PortfolioSchedule | null>(null)

    const [
        portfolioScheduleLoadingState,
        setPortfolioScheduleLoadingState,
    ] = useState<PortfolioScheduleLoadingState>(
        initialPortfolioScheduleLoadingState,
    )

    const getSimplePortfolioScheduleList = async (
        req: GetPortfolioScheduleListRequest,
    ) => {
        try {
            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleList: true,
            })

            const response = await axiosInstance.get(
                `workorder/simple-portfolio-schedule/`,
                {
                    params: req.params,
                },
            )
            const simplePortfolioScheduleList: SimplePortfolioSchedule[] =
                response.data
            setSimplePortfolioScheduleList(simplePortfolioScheduleList)

            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleList: false,
            })

            return simplePortfolioScheduleList
        } catch (e) {
            toast.error('Failed to get portfolio schedule list')
            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleList: false,
            })
            return Promise.reject(e)
        }
    }

    const getPortfolioScheduleDetail = async (
        id: number,
        params: {
            organization?: number
        },
    ) => {
        try {
            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleDetail: true,
            })
            const response = await axiosInstance.get(
                `workorder/portfolio-schedule/${id}/get_schedule_detail/`,
                { params: params },
            )
            const portfolioScheduleDetail: PortfolioSchedule = response.data
            setPortfolioScheduleDetail(portfolioScheduleDetail)
            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleDetail: false,
            })
            return portfolioScheduleDetail
        } catch (e) {
            toast.error('Failed to get portfolio schedule detail')
            setPortfolioScheduleLoadingState({
                ...portfolioScheduleLoadingState,
                portfolioScheduleDetail: false,
            })
            return Promise.reject(e)
        }
    }

    return {
        simplePortfolioScheduleList,
        portfolioScheduleDetail,
        portfolioScheduleLoadingState,
        getSimplePortfolioScheduleList,
        getPortfolioScheduleDetail,
    }
}

export interface PortfolioScheduleLoadingState {
    portfolioScheduleList: boolean
    portfolioScheduleDetail: boolean
}

export const initialPortfolioScheduleLoadingState: PortfolioScheduleLoadingState = {
    portfolioScheduleList: false,
    portfolioScheduleDetail: false,
}
