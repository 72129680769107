import React, { useState } from 'react'
import clsx from 'clsx'

import {
    AppBar,
    IconButton,
    Toolbar,
    MenuItem,
    Menu,
    useTheme,
    Typography,
} from '@material-ui/core'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
    Menu as MenuIcon,
    MoreVert as MoreIcon,
    Settings as SettingsIcon,
    ExitToApp as ExitToAppIcon,
    Assignment as AssignmentIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import { useUser } from '../../../hooks'

import WorkSpaceSelection from './WorkSpaceSelection'
import OrganizationSelection from './OrganizationSelection'

import { useHistory } from 'react-router-dom'
import { AppSelector, AvatarCircle } from '../../../components'
import { Routes } from '../../config/routes'

import { AGENT_HEADER_WIDTH, TOP_BAR_HEIGHT } from '../../constants'

export enum ProductView {
    EZFOLIO = 'EZFOLIO',
    EZTURN = 'EZTURN',
    EZMANAGE = 'EZMANAGE',
    ADMIN = 'ADMIN',
}
import { useDispatch } from 'react-redux'
import SwipeableSideBar from '../AuthLayout/sidebar/SwipeableSideBar'

interface Props {
    loriOpen: Boolean
    loriDrawerWidth: number
    productView: ProductView
    open: boolean
}
const TopNavBar = (props: Props) => {
    const { actions } = useUser()
    const { logout } = actions
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            menuToggleButton: {
                marginRight: 0,
                marginLeft: 0,
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            hide: {
                display: 'none',
            },
            appBar: {
                top: 0,
                left: 0,
                right: 0,
                position: 'fixed',
                height: `${TOP_BAR_HEIGHT}px !important`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarShift: {
                marginLeft: props.loriDrawerWidth,
                width: `calc(100% - ${props.loriDrawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginLeft: 0,
                },
            },
            appBarClosed: {
                marginLeft: AGENT_HEADER_WIDTH,
                width: `calc(100% - ${AGENT_HEADER_WIDTH}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginLeft: 0,
                },
            },
            toolbar: {
                left: `${props.loriDrawerWidth}px`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // padding: theme.spacing(0, 1),
                minHeight: '56px !important',
                '& .MuiIconButton-root': {
                    padding: theme.spacing(1),
                    marginLeft: theme.spacing(0.5),
                    color: 'inherit',
                },
                '& .MuiSvgIcon-root': {
                    color: 'inherit',
                },
                ...theme.mixins.toolbar,
            },
            grow: {
                flexGrow: 1,
            },

            sectionDesktop: {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                },
            },
            sectionMobile: {
                display: 'flex',
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            profilePic: {
                display: 'flex',
            },
            iconButton: {
                color: 'inherit',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
            },
            menuItem: {
                '&:hover': {
                    backgroundColor: theme.palette.grey[300],
                },
            },
        }),
    )

    const history = useHistory()
    const classes = useStyles()
    const { workspaceUser, rootUser } = useUser()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const [
        mobileMoreAnchorEl,
        setMobileMoreAnchorEl,
    ] = React.useState<null | HTMLElement>(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            style={{ marginTop: '40px' }}
        >
            <MenuItem
                onClick={() => {
                    history.push(Routes.shared.settings.root)
                }}
                className={classes.menuItem}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <SettingsIcon style={{ marginRight: '8px' }} />
                Workspace Settings
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.push(Routes.shared.reports)
                }}
                className={classes.menuItem}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <AssignmentIcon style={{ marginRight: '8px' }} />
                Reports
            </MenuItem>
            <MenuItem
                onClick={() => {
                    logout()
                    history.push(Routes.public.login)
                }}
                className={classes.menuItem}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <ExitToAppIcon style={{ marginRight: '8px' }} />
                Logout
            </MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AvatarCircle
                        text={rootUser?.name}
                        initials
                        image={rootUser?.profile_picture}
                        styles={{
                            container: {
                                maxHeight: '30px',
                                maxWidth: '30px',
                                minHeight: 'auto',
                                minWidth: 'auto',
                                fontSize: '1rem',
                                padding: '16px',
                            },
                        }}
                    />
                    {/* <AccountCircle /> */}
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    const [openSwipeDrawer, setOpenSwipeDrawer] = useState(false)

    const toggleSwipeDrawer = () => {
        setOpenSwipeDrawer(!openSwipeDrawer)
    }

    const theme = useTheme()

    const renderWorkspaceSelector = () => {
        switch (props.productView) {
            case ProductView.EZTURN:
                return <WorkSpaceSelection />
            case ProductView.EZFOLIO:
                if (rootUser && workspaceUser) {
                    return (
                        <OrganizationSelection
                            user={rootUser}
                            selectedOrganization={
                                workspaceUser?.active_workspace.organization
                            }
                        />
                    )
                }
                return null
            case ProductView.ADMIN:
                return null
            default:
                return <WorkSpaceSelection />
        }
    }

    return (
        <React.Fragment>
            <AppBar
                style={{
                    backgroundColor:
                        workspaceUser?.active_workspace.company_type ===
                        'VENDOR'
                            ? theme.palette.darkBlue.main
                            : '#008c85',
                    color: '#ffffff',
                }}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: true,
                })}
            >
                <Toolbar
                    style={{
                        minHeight: '56px',
                        maxHeight: '56px',
                        paddingLeft: 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSwipeDrawer}
                        edge="start"
                        className={clsx(classes.menuToggleButton, {
                            [classes.hide]: props.open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <SwipeableSideBar
                        openSwipeDrawer={openSwipeDrawer}
                        toggleSwipeDrawer={toggleSwipeDrawer}
                    />
                    {rootUser?.workspaces.length !== 0 && (
                        <WorkSpaceSelection />
                    )}

                    <div className={classes.grow} />

                    <AppSelector workspaceUser={workspaceUser} theme={theme} />

                    {/* profile icon */}
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        style={{ marginRight: theme.spacing(0.5) }}
                    >
                        <Typography style={{ marginRight: '5px' }}>
                            {rootUser?.name}
                        </Typography>
                        <AvatarCircle
                            text={rootUser?.name}
                            initials
                            image={rootUser?.profile_picture}
                            styles={{
                                container: {
                                    maxHeight: 45,
                                    maxWidth: 45,
                                    minHeight: 'auto',
                                    minWidth: 'auto',
                                    height: '35px',
                                    width: '35px',
                                    fontSize: '1rem',
                                },
                            }}
                        />
                        <ExpandMoreIcon style={{ marginLeft: '5px' }} />
                    </IconButton>

                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </React.Fragment>
    )
}

export default TopNavBar
