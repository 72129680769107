import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    createStyles,
    ListItem,
    ListItemIcon,
    Theme,
    makeStyles,
    Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'
import { NavigationItem } from '../types/navigation'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        highlightMenu: {
            borderLeft: '6px solid #008c85 !important',
            '& path': {
                stroke: '#FFF !important',
            },
            '& svg': {
                color: '#FFF !important',
            },
        },
        navMenu: {
            borderLeft: '6px solid transparent',
            '& path': {
                stroke: '#FFF',
            },
            '& svg': {
                color: '#FFF',
            },
        },
        sideBarItem: {
            maxHeight: 'calc(100vh - 114px) !important',
            overflowY: 'auto',
            '& .MuiListItem-root': {
                paddingTop: '16px',
                paddingBottom: '16px',
            },
            // '& path': {
            //     stroke:
            //         workspaceUser?.active_workspace.company_type ===
            //         'VENDOR'
            //             ? theme.palette.darkBlue.main
            //             : theme.palette.darkGreen.main,
            // },
        },
        footerText: {
            fontSize: '1.25rem',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#FFF',
            margin: 'auto',
            padding: '15px 0',
            textAlign: 'center',
        },
        toolTipMenuIcon: {
            background: '#FFF',
            color: '#000',
            fontSize: '0.813rem',
            fontWeight: 400,
            maxWidth: '104px',
            textAlign: 'center',
        },
        listItem: {
            padding: 0,
            minHeight: 48,
            display: 'flex',
            alignItems: 'center',
            minWidth: 'fit-content',
            width: 'auto',
        },
        listItemIcon: {
            color: '#FFF',
            justifyContent: 'center',
            minWidth: 48,
            width: 'auto',
            margin: 0,
        },
    }),
)

const NavItem = ({ item }: { item: NavigationItem }) => {
    const classes = useStyles()

    return (
        <ListItem
            component={NavLink}
            to={item.link}
            button
            className={clsx(classes.navMenu, classes.listItem)}
            activeClassName={classes.highlightMenu}
        >
            <Tooltip
                title={item.name}
                placement="top"
                classes={{ tooltip: classes.toolTipMenuIcon }}
            >
                <ListItemIcon className={classes.listItemIcon}>
                    {item.icon}
                </ListItemIcon>
            </Tooltip>
        </ListItem>
    )
}

export default NavItem
