import { Button, Modal, Paper, Slide, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import { SupportRequestForm } from '../../components/SupportRequestForm/SupportRequestForm'
import { Vidyard } from '../../components/VidyardEmbed'

export const Help = () => {
    const theme = useTheme()

    const [supportModalOpen, setSupportModalOpen] = useState(false)

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'scroll',
                flex: 1,
                height: '90%',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing(2),
                }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Help
                </span>
            </Container>

            {/* Videos */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <Vidyard
                    url={'https://play.vidyard.com/2D5ZbbjbY7fGVhnCnggeo4.jpg'}
                    uuid={'2D5ZbbjbY7fGVhnCnggeo4'}
                    height={screen.height / 4}
                    width={screen.width / 3}
                    title={
                        'Create a Schedule, Change Workspaces, and Add a Vendor'
                    }
                />
                <Vidyard
                    url={'https://play.vidyard.com/z8ozoYcoQ9mTTiZQRMxyvq.jpg'}
                    uuid={'z8ozoYcoQ9mTTiZQRMxyvq'}
                    height={screen.height / 4}
                    width={screen.width / 3}
                    title={'Scheduler Help'}
                />
                <Vidyard
                    url={'https://play.vidyard.com/qUbMWpBiiLRrrtXNQ41a9Q.jpg'}
                    uuid={'qUbMWpBiiLRrrtXNQ41a9Q'}
                    height={screen.height / 4}
                    width={screen.width / 3}
                    title={'Inspection & Damages Help'}
                />
                <Vidyard
                    url={'https://play.vidyard.com/V55f8eDzVNu3NmLT1oKgWF.jpg'}
                    uuid={'V55f8eDzVNu3NmLT1oKgWF'}
                    height={screen.height / 4}
                    width={screen.width / 3}
                    title={'Vendor Training'}
                />
            </Container>
            <Container style={{ justifyContent: 'flex-end' }}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setSupportModalOpen(true)}
                >
                    Request Help
                </Button>
            </Container>
            <Modal
                open={supportModalOpen}
                onClose={() => setSupportModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={supportModalOpen}>
                    <Paper style={{ width: '90%', height: '90%' }}>
                        <SupportRequestForm />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
