import React from 'react'
import { Routes } from '../config/routes'
import { WorkSpaceUser } from '../../models'
import { Redirect } from 'react-router-dom'
import { isAccountManager } from '../../helpers'

interface AdminGuardProps {
    children: React.ReactNode
    workspaceUser?: WorkSpaceUser
}

interface OrganizationGuardProps {
    children: React.ReactNode
    workspaceUser?: WorkSpaceUser
}

export const AdminGuard = ({
    children,
    workspaceUser,
}: OrganizationGuardProps) => {
    if (!workspaceUser) {
        return <Redirect to={Routes.error.permissionDenied} />
    }

    if (!isAccountManager(workspaceUser)) {
        return <Redirect to={Routes.error.permissionDenied} />
    }

    return <div>{children}</div>
}
