import React, { useState } from 'react'
import { VideoLibraryTab } from './VideoLibraryTab'

import { Tab, Tabs, Typography } from '@mui/material'
import { Container } from '../../Container'
import { useTheme } from '@material-ui/core/styles'
import { ProjectPlansTab } from './ProjectPlansTab'

export const LoriManager = () => {
    const theme = useTheme()

    const VIDEO_LIBRARY_TAB = 0
    const USER_ANALYSIS_TAB = 1
    const PROJECT_PLANS_TAB = 2

    const [selectedTab, setSelectedTab] = useState<number>(VIDEO_LIBRARY_TAB)

    return (
        <Container
            style={{
                borderRadius: theme.shape.borderRadius,
                maxHeight: 'calc(100vh - 100px)',
                minHeight: 'calc(100vh - 100px)',
                maxWidth: 'calc(100vw - 100px)',
                minWidth: 'calc(100vw - 100px)',
                overflow: 'hidden',
                flexDirection: 'column',
                padding: theme.spacing(1),
            }}
        >
            {/* Header */}

            <Container
                style={{
                    padding: theme.spacing(1),
                    alignItems: 'center',
                }}
            >
                <Container style={{ flex: 1, alignItems: 'center' }}>
                    <Typography variant="h6">Lori Manager</Typography>
                </Container>
            </Container>
            {/* Tabs */}
            <Container>
                <Tabs
                    value={selectedTab}
                    onChange={(_, newValue) => setSelectedTab(newValue)}
                >
                    <Tab label="Video Library" />
                    <Tab label="User Analysis" />
                    <Tab label="Project Plans" />
                </Tabs>
            </Container>

            {/* Body */}
            <Container
                style={{
                    flex: 1,
                }}
            >
                {selectedTab === VIDEO_LIBRARY_TAB && <VideoLibraryTab />}
                {selectedTab === PROJECT_PLANS_TAB && <ProjectPlansTab />}
            </Container>
        </Container>
    )
}
