import React, { useState } from 'react'

import clsx from 'clsx'
import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
} from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

import { ReactComponent as Logo } from '../../../../assets/EZ_Circle_Logo_Icon.svg'

import SideBarNavigationItems from './SideBarNavigationItems'
import { useUser } from '../../../../hooks'
import { ChannelSelector } from '../../Lori/ChannelSelector'
import { APARTMENT } from '../../../../helpers'

interface Props {
    drawerWidth: number
    open: boolean
    selectedChannel?: string
    setSelectedChannel?: React.Dispatch<
        React.SetStateAction<'lori' | 'notifications' | 'history' | 'none'>
    >
}
const SideBar = (props: Props) => {
    const { workspaceUser } = useUser()

    const theme = useTheme()

    const sideBarColor =
        workspaceUser?.active_workspace.company_type === 'VENDOR'
            ? theme.palette.lightGrey.main
            : theme.palette.darkBlue.main

    const useStyles = React.useMemo(
        () =>
            makeStyles((theme: Theme) =>
                createStyles({
                    menuButton: {
                        marginRight: 36,
                    },
                    hide: {
                        display: 'none',
                    },
                    drawer: {
                        width: props.drawerWidth,
                        flexShrink: 0,
                        whiteSpace: 'nowrap',
                        backgroundColor: sideBarColor,
                        color: '#737378',
                        zIndex: 0,
                        [theme.breakpoints.down('xs')]: {
                            display: 'none',
                        },
                    },
                    drawerOpen: {
                        width: props.drawerWidth,
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        backgroundColor: sideBarColor,
                        color: '#737378',
                    },
                    drawerClose: {
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        overflowX: 'hidden',
                        width: `${props.drawerWidth}px !important`,
                        // width: theme.spacing(7) + 1,
                        [theme.breakpoints.up('sm')]: {
                            width: `${props.drawerWidth}px !important`,
                        },
                        backgroundColor: sideBarColor,
                        color: '#737378',
                    },
                    toolbar: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: theme.spacing(0, 1),
                        // necessary for content to be below app bar
                        ...theme.mixins.toolbar,
                    },
                    logo: {
                        width: '50px',
                        height: '50px',
                        alignSelf: 'center',
                        marginBottom: '20px',
                        marginTop: theme.spacing(1),
                    },
                }),
            ),
        [sideBarColor],
    )
    const classes = useStyles()

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    }),
                }}
            >
                {/* <div className={classes.toolbar}></div> */}
                <Logo className={classes.logo} />

                {/* {workspaceUser?.active_workspace.company_type === APARTMENT &&
                    props.setSelectedChannel &&
                    props.selectedChannel && (
                        <ChannelSelector
                            selectedChannel={props.selectedChannel}
                            setSelectedChannel={props.setSelectedChannel}
                        />
                    )} */}

                <SideBarNavigationItems />
            </Drawer>
        </React.Fragment>
    )
}

export default SideBar
