export const Routes = {
    login: '/login',
    loginHome: '/login-home',
    requestPasswordReset: '/request-password-reset',
    checkEmailConfirmation: '/check-email-confirmation',
    resetPassword: '/forgot-password',
    register: '/register',
    vendorRegister: '/vendor-register',
    userRegister: '/user-register',
    skillcatRegister: '/skillcat-register',
    nationalVendorListFinal: '/national-vendor-list-final',
    home: '/home',
    apartmentSchedule: '/schedule',
    apartmentScheduleDetail: '/schedule/:id',
    apartmentScheduleArchivedDetail: '/schedule/:id/archive',
    workforce: '/workforce',
    nvl: '/national-vendor-list',
    inspect: '/inspect',
    inspectionDetail: '/inspect/:id',
    actionItem: '/action-item',
    ezchat: '/ez-chat',
    invoiceHome: '/invoice-home',
    invoiceList: '/invoice/company/:id',
    vendorAnalytics: '/analytics/:id/',
    analytics: '/analytics',
    infrastructureManager: '/infrastructure-manager',
    inventoryConf: '/inventory-config',
    areaConf: '/area-config',
    unitConf: '/unit-config',
    damageConf: '/damage-config',
    scheduleLocation: '/scheduler/:id/location',
    vendorSchedule: '/scheduler',
    vendorScheduleDetail: '/scheduler/:id/location/:locationId',
    permissionDenied: '/denied',
    statusGroups: '/status-groups',
    inspectionType: '/inspection-type',
    leases: '/leases',
    help: '/help',
    changeOrderConfig: '/change-order-config',
    infrastructureConfig: '/infrastructure-configure',
    reports: '/reports',
    //New Routes
    settingsFinal: '/settings',
    generalSetting: '/general-settings',
    vendorSettings: '/vendor-settings',
    ezChatFinal: '/ez-chat-final',
    nationalPropertyList: '/national-property-list',
    vendorHelp: '/vendor-help',
    ezpay: '/ez-pay',
    verify: '/verify',
    propertyDetails: '/property-details',
    serviceRequestSettings: '/service-request-settings',
    rfpDashboard: '/rfp-dashboard',
    ezwork: '/ezwork',
    portfolioAnalytics: '/portfolio-analytics',
    deleteAccount: '/delete-account',
    leaseAudit: '/lease-audit',
    leaseAuditDetail: '/lease-audit/:id',
    jobBoard: '/job-board',
    propertyAutomation: '/property-automation',
    serviceCategories: '/service-categories',
}

export const resolveRoute = (
    route: string,
    paramKey: string,
    paramValue: string | number,
) => {
    console.log('resolve route.....', `${paramKey}, ${paramValue.toString()}`)
    return route.replace(paramKey, paramValue.toString())
}
