import React from 'react'
import { Routes } from '../config/routes'
import { ApartmentGuard } from '../guards'
import { AuthGuard } from '../guards/AuthGuard'
import { AuthLayout } from '../layouts'
import type { RouteConfig } from '../types'

// Import grouping for containers
import Dashboard from '../../main/containers/dashboard'
import {
    AnalyticsHome,
    AreaConfig,
    ComingSoon,
    DamageHome,
    InfrastructureConfig,
    InfrastructureManager,
    InspectionDetail,
    InspectionRoot,
    InventoryConfigHome,
    InvoiceHome,
    InvoiceList,
    LeaseHome,
    PortfolioAnalyticsHome,
    ScheduleHomeRoot,
    StatusGroupConfig,
    Workforce,
} from '../../containers'
import { SchedulerRoot } from '../../containers/Scheduler_v2/SchedulerRoot'
import { ArchivedSchedule } from '../../containers/ArchivedSchedule'
import { ActionItemRoot } from '../../containers/ActionItem/ActionItemRoot'
import { LeaseAuditList } from '../../containers/LeaseAudit/LeaseAuditList'
import { EZPayRoot } from '../../containers/EZPay/Home/EZPayRoot'
import { LeaseAuditRoot } from '../../containers/LeaseAudit/LeaseAuditRoot'
import { ChangeOrderConfigList } from '../../containers/ChangeOrderConfig/ChangeOrderConfig'
import { EZChat } from '../../main/containers/EZChat'
import { ReportsHome } from '../../containers/Reports'
import { Settings } from '../../main/containers/settings'
import { GeneralSetting } from '../../main/containers/settings/GeneralSetting'
import { Help } from '../../containers/Help'
import { PropertyDetails } from '../../main/containers/settings/PropertyDetails'
import { InvoiceRoot } from '../../containers/Invoice/InvoiceRoot'

export const sharedRoutes: RouteConfig[] = [
    {
        path: Routes.shared.communication.ezchat,
        component: EZChat,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.financial.invoiceHome,
        component: InvoiceRoot,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.financial.invoiceList,
        component: InvoiceList,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.financial.ezpay,
        component: EZPayRoot,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.analytics.root,
        component: AnalyticsHome,
        layout: AuthLayout,
        guard: AuthGuard,
    },

    {
        path: Routes.shared.workforce,
        component: Workforce,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.reports,
        component: ReportsHome,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.settings.root,
        component: Settings,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.settings.general,
        component: GeneralSetting,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.help,
        component: Help,
        layout: AuthLayout,
        guard: AuthGuard,
    },
    {
        path: Routes.shared.propertyDetails,
        component: PropertyDetails,
        layout: AuthLayout,
        guard: AuthGuard,
    },
]
