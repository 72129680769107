import React from 'react'

import { APARTMENT, VENDOR } from '../../../../helpers'
import { useUser } from '../../../../hooks'
import { VendorItems } from '../../../components/SideBarItems/VendorItems'
import { ApartmentItems } from '../../../components/SideBarItems/ApartmentItems'
import { useHistory, useLocation } from 'react-router-dom'
import { navigationConfig } from '../../../config/navigationItems'
import { OrganizationItems } from '../../../components/SideBarItems/OrganizationItems'
import { ManageItems } from '../../../components/SideBarItems/ManageItems'

const SideBarNavigationItems = () => {
    const { workspaceUser } = useUser()

    const history = useHistory()
    const location = useLocation()

    const isEZTurnSelected = [
        ...navigationConfig.apartment.mainItems,
        ...navigationConfig.apartment.footerItems,
        ...navigationConfig.vendor.mainItems,
        ...navigationConfig.vendor.footerItems,
        ...navigationConfig.shared.mainItems,
        ...navigationConfig.shared.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isEZFolioSelected = [
        ...navigationConfig.organization.mainItems,
        ...navigationConfig.organization.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isAdminSelected = [
        ...navigationConfig.admin.mainItems,
        ...navigationConfig.admin.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))

    const isManageSelected = [
        ...navigationConfig.manage.mainItems,
        ...navigationConfig.manage.footerItems,
    ].some((item) => location.pathname.startsWith(item.link))


    console.log('isManageSelected', isManageSelected)



    if (!workspaceUser) return null

    if (
        workspaceUser.active_workspace.company_type === VENDOR &&
        isEZTurnSelected
    ) {
        return <VendorItems />
    }

    if (
        workspaceUser.active_workspace.company_type === APARTMENT &&
        isEZTurnSelected
    ) {
        return <ApartmentItems />
    }

    // if (isAdminSelected) {
    //     return <AdminItems />
        // }
    
    if (isManageSelected) {
        return <ManageItems />
    }

    if (isEZFolioSelected) {
        return <OrganizationItems />
    }

    return <ApartmentItems />
}

export default SideBarNavigationItems
