import React, { useState } from 'react'
import { Container, NumberInput } from '../../../../components'
import { Theme, Button } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { footerH, headerH, modalH } from './constants'

interface Props {
    theme: Theme
    disabled: boolean
    onClickBack: () => void
    onConfirm: (daysToComplete: number, selectedDate: Date) => void
}

export const CreateMoveInEventPage = (props: Props) => {
    const { theme, onClickBack, disabled } = props

    const [emailDate, setEmailDate] = useState(new Date())

    const [daysToComplete, setDaysToComplete] = useState<number | null>(null)

    return (
        <Container
            style={{
                flexDirection: 'column',
                padding: theme.spacing(1),
                flex: 1,
            }}
        >
            {/* Body */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    minHeight: modalH - headerH - footerH - theme.spacing(2),
                    padding: theme.spacing(2),
                    justifyContent: 'center',
                }}
            >
                <KeyboardDatePicker
                    value={emailDate}
                    onChange={(date) => {
                        if (date === null) {
                            return
                        }
                        setEmailDate(date)
                    }}
                    format="MM/dd/yyyy"
                    label="Email date"
                    minDate={new Date()}
                />

                <NumberInput
                    style={{ marginTop: theme.spacing(4) }}
                    label="Days to complete"
                    value={daysToComplete}
                    onChange={(e) => {
                        setDaysToComplete(Number(e.target.value))
                    }}
                />
            </Container>

            {/* Footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: footerH,
                    maxHeight: footerH,
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    color="secondary"
                    onClick={onClickBack}
                >
                    Cancel
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    color="primary"
                    disabled={disabled}
                    onClick={async () => {
                        if (daysToComplete !== null && daysToComplete > 0) {
                            props.onConfirm(daysToComplete, emailDate)
                        }
                    }}
                >
                    Confirm
                </Button>
            </Container>
        </Container>
    )
}
