import React, { useEffect, useState } from 'react'

import {
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'

import { Container } from '../../../../components'
import { useService, useUser } from '../../../../hooks'
import { ProjectTable } from './ProjectTable'
import { initialProjectModalState, ProjectModalState } from './types'
import { BurnDownModal } from '../../../Scheduler_v2/Modals/BurnDownModal'
import { workorderContext } from '../../../../contexts'
import { SearchField } from '../../../../components/SearchField'
import { ProjectDetail } from './ProjectDetail'
import { Routes } from '../../../../navigation/config/routes'
import { resolveRoute } from '../../../../helpers'
import { Close } from '@material-ui/icons'
import { usePortfolioSchedule } from '../../../../hooks/usePortfolioSchedule'

export const OrganizationProjectPage = () => {
    const {
        simplePortfolioScheduleList,
        portfolioScheduleDetail,
        portfolioScheduleLoadingState,
        getSimplePortfolioScheduleList,
        getPortfolioScheduleDetail,
    } = usePortfolioSchedule()

    const [modalState, setModalState] = useState<ProjectModalState>(
        initialProjectModalState,
    )

    const [searchText, setSearchText] = useState('')

    const { workorderList, loadingState } = workorderContext()

    const { organization } = useUser()

    const theme = useTheme()

    const { serviceList, getServiceList } = useService()

    useEffect(() => {
        getSimplePortfolioScheduleList({
            params: { organization: organization ?? undefined },
        }),
            getServiceList({})
    }, [organization])

    const navigateToScheduleDetail = (scheduleId: number) => {
        const project = simplePortfolioScheduleList?.find(
            (project) => project.id === scheduleId,
        )

        let route = Routes.apartment.schedule.detail as string
        if (project && !project.active) {
            route = Routes.apartment.schedule.archived as string
        }
        const params = new URLSearchParams({
            apartment: project?.apartment_id.toString() ?? '',
            setup: 'false',
        }).toString()

        const url = `${resolveRoute(route, ':id', scheduleId)}?${params}`

        window.open(url, '_blank')
    }

    return (
        <Container style={{ flexDirection: 'column', flex: 1, height: '100%' }}>
            <Container
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightBold,
                        marginRight: theme.spacing(2),
                    }}
                >
                    Projects
                </span>

                <SearchField
                    placeholder="Search Projects"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Container>
            {portfolioScheduleLoadingState.portfolioScheduleList ? (
                <Container
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        height: '100%',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <ProjectTable
                    scheduleList={simplePortfolioScheduleList ?? []}
                    theme={theme}
                    searchText={searchText}
                    modalState={modalState}
                    setModalState={setModalState}
                    getPortfolioScheduleDetail={getPortfolioScheduleDetail}
                />
            )}

            <BurnDownModal
                open={modalState.burnDownModalOpen}
                theme={theme}
                workorders={workorderList ?? []}
                loading={loadingState.getWorkorderList}
                isWorkorderInFilter={() => true}
                onClose={() => {
                    setModalState(initialProjectModalState)
                }}
            />

            <Modal
                open={modalState.summaryReportModalOpen}
                onClose={() => {
                    setModalState(initialProjectModalState)
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={modalState.summaryReportModalOpen}>
                    <Paper
                        style={{
                            width: 'calc(100vw - 300px)',
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.background.paper,
                            height: 'calc(100vh - 200px)',
                        }}
                    >
                        <iframe
                            src={
                                modalState.selectedSchedule?.summary_report ??
                                ''
                            }
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                            }}
                            title="Summary Report"
                        />
                    </Paper>
                </Slide>
            </Modal>

            {modalState.selectedSchedule && (
                <Modal
                    open={modalState.detailModalOpen}
                    onClose={() => {
                        setModalState(initialProjectModalState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={modalState.detailModalOpen}>
                        <Paper
                            style={{
                                width: 'calc(100vw - 250px)',
                                backgroundColor: theme.palette.background.paper,
                                height: 'calc(100vh - 150px)',
                            }}
                        >
                            <Container
                                style={{
                                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                    padding: theme.spacing(1, 2),
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                    }}
                                >
                                    Project Detail
                                </span>
                                <Container style={{ flex: 1 }} />
                                <IconButton
                                    onClick={() => {
                                        setModalState(initialProjectModalState)
                                    }}
                                    size="small"
                                >
                                    <Close />
                                </IconButton>
                            </Container>
                            <ProjectDetail
                                scheduleDetail={portfolioScheduleDetail}
                                scheduleLoading={
                                    portfolioScheduleLoadingState.portfolioScheduleDetail
                                }
                                workorderList={workorderList ?? []}
                                workorderLoading={loadingState.getWorkorderList}
                                serviceList={serviceList ?? []}
                                navigateToScheduleDetail={
                                    navigateToScheduleDetail
                                }
                            />
                        </Paper>
                    </Slide>
                </Modal>
            )}
        </Container>
    )
}
