import React, { useEffect, useState } from 'react'

import { Tab, Tabs, useTheme } from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { useAppDispatch, useAptConfig, useSchedule, useUser } from '../../hooks'
import {
    INCIDENT_REPORTS,
    INSPECTION_PREVIEW_EDITOR,
    InspectionType,
    TenantNegligibleFilter,
} from '../../models'
import { getInspectionList } from '../../store'
import { InspectionConfigurationModal } from './InspectionConfiguration/InspectionConfigurationModal'
import { hasPermission } from '../../models/Users/services'
import { IncidentReportRoot } from './IncidentReportRoot'
import { useInspectionFrequency } from '../../hooks/useInspectionFrequency'
import { InspectionPage } from './InspectionPage'
import { InspectionTypePage } from './InspectionTypePage'
import { InspectionFrequencyPage } from './InspectionFrequencyPage'
import { ActionItemTriggerPage } from './ActionItemTriggerPage'
import { StatusGroupConfig } from '../StatusGroupConfig'
import { InventoryConfigPage } from '../InfrastructureConfig/InventoryConfigPage'

export const InspectionRoot = () => {
    const dispatch = useAppDispatch()

    const {
        inspectionTypeList,
        inventoryConfigList,
        customStatusList,
        areaConfigList,
        statusGroupList,
        damageConfigList,
        getAreaConfigMap,
    } = useAptConfig({
        inspectionTypeList: true,
        inventoryConfigList: true,
        areaConfigList: true,
        customStatusList: true,
        damageConfigList: true,
        statusGroupList: true,
        cleanUp: true,
    })

    const {
        frequencyList,
        setFrequencyList,
        getFrequencyList,
        selectedFrequency,
        setSelectedFrequency,
        toggleFrequencyActive,
    } = useInspectionFrequency()

    const areaConfigMap = getAreaConfigMap()

    const { scheduleList, getScheduleList } = useSchedule()

    const theme = useTheme()

    const [selectedTab, setSelectedTab] = useState<number>(INSPECTION_TAB)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const [selectedInspectionType, setSelectedInspectionType] = useState<
        InspectionType | undefined
    >()

    const [
        openInspectionConfiguration,
        setOpenInspectionConfiguration,
    ] = useState(false)

    const [incidentReportFilters, setIncidentReportFilters] = useState({
        status: -1,
        incidentType: -1,
        tenantNegligible: TenantNegligibleFilter.NO_FILTER,
    })

    useEffect(() => {
        dispatch(getInspectionList({ params: {} }))
        getScheduleList({ params: { no_inspection: true } })
        getFrequencyList()
    }, [])

    const { workspaceUser } = useUser()

    const [moveOutInspection, setMoveOutInspection] = useState<
        number | undefined
    >()

    useEffect(() => {
        if (workspaceUser?.active_workspace.move_out_inspection_config) {
            setMoveOutInspection(
                workspaceUser.active_workspace.move_out_inspection_config,
            )
        }
    }, [])

    const hasEditPermission = hasPermission(
        workspaceUser,
        INSPECTION_PREVIEW_EDITOR,
    )

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    height: 'calc(100vh - 104px)',
                    overflow: 'hidd/en',
                }}
            >
                <Tabs
                    style={{
                        width: '100%',
                        backgroundColor: theme.palette.grey[300],
                    }}
                    value={selectedTab}
                    onChange={(_, v) => {
                        if (v !== selectedTab) {
                            setSelectedTab(v)
                        }
                    }}
                >
                    <Tab
                        label="Inspections"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Inspection Types"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Inspection Frequencies"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Inventory Types"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Status Groups"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Action Item Triggers"
                        style={{ textTransform: 'none' }}
                    />
                    {hasPermission(workspaceUser, INCIDENT_REPORTS) && (
                        <Tab
                            label="Incident Reports"
                            style={{ textTransform: 'none' }}
                        />
                    )}
                </Tabs>

                {selectedTab === INSPECTION_TAB && (
                    <InspectionPage
                        scheduleList={scheduleList}
                        inspectionTypeList={inspectionTypeList ?? []}
                        setOpenInspectionConfiguration={
                            setOpenInspectionConfiguration
                        }
                    />
                )}

                {selectedTab === INSPECTION_TYPE_TAB && (
                    <InspectionTypePage
                        inspectionTypeList={inspectionTypeList ?? []}
                        moveOutInspection={moveOutInspection}
                        selectedInspectionType={selectedInspectionType}
                        setSelectedInspectionType={setSelectedInspectionType}
                        setOpenInspectionConfiguration={
                            setOpenInspectionConfiguration
                        }
                    />
                )}

                {selectedTab === INSPECTION_FREQUENCIES && (
                    <InspectionFrequencyPage
                        frequencyList={frequencyList}
                        areaConfigMap={areaConfigMap}
                        selectedFrequency={selectedFrequency}
                        setSelectedFrequency={setSelectedFrequency}
                        toggleFrequencyActive={toggleFrequencyActive}
                        setOpenInspectionConfiguration={
                            setOpenInspectionConfiguration
                        }
                        setFrequencyList={setFrequencyList}
                    />
                )}

                {selectedTab === ACTION_ITEM_TRIGGER_TAB && (
                    <ActionItemTriggerPage
                        inventoryConfigList={inventoryConfigList}
                        customStatusList={customStatusList}
                    />
                )}
                {selectedTab === INCIDENT_REPORT_TAB && (
                    <IncidentReportRoot
                        incidentReportFilters={incidentReportFilters}
                        onIncidentSelect={() => {
                            setDrawerOpen(true)
                        }}
                        setFilters={setIncidentReportFilters}
                    />
                )}

                {selectedTab === STATUS_GROUPS && (
                    <StatusGroupConfig
                        statusGroupList={statusGroupList ?? []}
                    />
                )}

                {selectedTab === INVENTORY_TYPES && (
                    <InventoryConfigPage
                        inventoryConfigList={inventoryConfigList ?? []}
                        areaConfigList={areaConfigList ?? []}
                        statusGroupList={statusGroupList ?? []}
                        damageConfigList={damageConfigList ?? []}
                    />
                )}
            </Container>

            <InspectionConfigurationModal
                open={openInspectionConfiguration}
                inspectionTypeList={inspectionTypeList ?? []}
                scheduleList={scheduleList}
                frequencyList={frequencyList}
                inspectionType={selectedInspectionType}
                inventoryConfigList={inventoryConfigList ?? []}
                areaConfigList={areaConfigList ?? []}
                statusGroupList={statusGroupList ?? []}
                hasEditPermission={hasEditPermission}
                setMoveOutInspection={setMoveOutInspection}
                setFrequencyList={setFrequencyList}
                getScheduleList={getScheduleList}
                handleClose={() => {
                    setOpenInspectionConfiguration(false)
                    setSelectedInspectionType(undefined)
                }}
            />
        </SideDrawerContainer>
    )
}

const INSPECTION_TAB = 0
const INSPECTION_TYPE_TAB = 1
const INSPECTION_FREQUENCIES = 2
const INVENTORY_TYPES = 3
const STATUS_GROUPS = 4
const ACTION_ITEM_TRIGGER_TAB = 5
const INCIDENT_REPORT_TAB = 6
