import React, { useState } from 'react'
import { Container } from '../../components'
import { Tab, Tabs, useTheme } from '@material-ui/core'
import { PagePropertyGrade } from '../PortfolioAnalytics/components'
import { WorkorderTab } from './WorkorderTab'
import { ReactComponent as ListSBIcon } from '../../assets/icons/action-item.svg'
import FormatPaintIcon from '@material-ui/icons/FormatPaint'
import { AssignmentIndOutlined } from '@material-ui/icons'
import BuildIcon from '@material-ui/icons/Build'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { ServiceOrderPage } from './ServiceOrderPage'
import { ActionItemPage } from './ActionItemPage'
import { WorkorderType } from '../../models'

export const OrganizationFacilitiesHome = () => {
    const [selectedTab, setSelectedTab] = useState<number>(BIPS_TAB)

    const theme = useTheme()

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                height: 'calc(100vh - 104px)',
                overflow: 'hidden',
            }}
        >
            <Tabs
                style={{
                    width: '100%',
                    backgroundColor: theme.palette.grey[300],
                }}
                value={selectedTab}
                onChange={(_, v) => {
                    if (v !== selectedTab) {
                        setSelectedTab(v)
                    }
                }}
            >
                <Tab label="BIPS" style={{ textTransform: 'none' }} />
                {/* <Tab
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            Service Orders
                            <FormatPaintIcon />
                        </div>
                    }
                    style={{ textTransform: 'none' }}
                />
                <Tab
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            Action Items
                            <ListSBIcon style={{ height: 20, width: 20 }} />
                        </div>
                    }
                    style={{ textTransform: 'none' }}
                />
                <Tab
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            Service Requests
                            <AssignmentIndOutlined />
                        </div>
                    }
                    style={{ textTransform: 'none' }}
                /> */}
            </Tabs>

            {selectedTab === BIPS_TAB && (
                <PagePropertyGrade theme={theme} ezFolio />
            )}

            {/* {selectedTab === SERVICE_ORDERS_TAB && <ServiceOrderPage />}
            {selectedTab === ACTION_ITEMS_TAB && (
                <ActionItemPage workorderType={WorkorderType.ACTION_ITEM} />
            )}
            {selectedTab === SERVICE_REQUESTS_TAB && (
                <ActionItemPage workorderType={WorkorderType.SERVICE_REQUEST} />
            )} */}
        </Container>
    )
}

const BIPS_TAB = 0
const SERVICE_ORDERS_TAB = 1
const ACTION_ITEMS_TAB = 2
const SERVICE_REQUESTS_TAB = 3
