import React, { useEffect, useState } from 'react'

import { Container } from '../../Container'
import { useTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import {
    IconButton,
    Modal,
    Paper,
    Slide,
    Typography,
    TextField,
} from '@material-ui/core'
import { HelpVideo } from '../../../navigation/layouts/Lori/types'
import { useLori } from '../../../hooks/useLori'

interface Props {
    open: boolean
    onClose: () => void
    video: HelpVideo | null
    setSelectedVideo: (video: HelpVideo | null) => void
}

const MODAL_HEADER_H = 40

export const VideoDetailsModal = (props: Props) => {
    const { open, onClose, video, setSelectedVideo } = props
    if (!video) return null

    const theme = useTheme()

    const formatDuration = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            disableEnforceFocus
            disableAutoFocus
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            maxHeight: 'calc(100vh - 100px)',
                            minHeight: 'calc(100vh - 100px)',
                            maxWidth: 'calc(100vw - 100px)',
                            minWidth: 'calc(100vw - 100px)',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                        }}
                    >
                        {/* Header */}

                        <Container
                            style={{
                                height: MODAL_HEADER_H,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <Container
                                style={{ flex: 1, alignItems: 'center' }}
                            >
                                <Typography variant="h6">
                                    Video Details - {video.title} -{' '}
                                    {formatDuration(video.duration)}
                                </Typography>
                            </Container>
                            <Container
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Container>
                        </Container>

                        {/* Body */}
                        <Container
                            style={{
                                overflowY: 'scroll',
                                flexDirection: 'column',
                            }}
                        >
                            <Details
                                video={video}
                                fieldKey={'key_topics'}
                                theme={theme}
                                setSelectedVideo={setSelectedVideo}
                            />
                            <Details
                                video={video}
                                fieldKey={'summary'}
                                theme={theme}
                                setSelectedVideo={setSelectedVideo}
                            />
                            <Details
                                video={video}
                                fieldKey={'transcript'}
                                theme={theme}
                                setSelectedVideo={setSelectedVideo}
                            />
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const formatTitle = (str: string): string => {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

const Details = (props: {
    video: HelpVideo
    fieldKey: 'key_topics' | 'summary' | 'transcript'
    theme: Theme
    setSelectedVideo: (video: HelpVideo | null) => void
}) => {
    const { video, fieldKey, theme, setSelectedVideo } = props

    const title = formatTitle(fieldKey)
    const value = video[fieldKey]
    if (value === undefined) return null

    const [isEditing, setIsEditing] = useState(false)
    const [editedValue, setEditedValue] = useState(
        Array.isArray(value) ? value.join('\n') : String(value),
    )
    const [isDiff, setIsDiff] = useState(false)

    const LoriController = useLori()

    const formatValue = (value: string | string[] | number) => {
        return Array.isArray(value) ? value.join('\n') : value
    }

    useEffect(() => {
        if (formatValue(value) !== editedValue) {
            setIsDiff(true)
        } else {
            setIsDiff(false)
        }
    }, [value, editedValue])

    const handleSaveEdit = (video: HelpVideo, editedValue: string | number) => {
        const finalValue =
            fieldKey === 'key_topics'
                ? editedValue
                      .toString()
                      .split('\n')
                      .filter((topic) => topic.trim() !== '')
                : editedValue

        LoriController.updateVideo({ ...video, [fieldKey]: finalValue })
        setIsEditing(false)
        setSelectedVideo({ ...video, [fieldKey]: finalValue })
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                marginBottom: theme.spacing(1),
            }}
        >
            <Container
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {isEditing && isDiff ? (
                    <IconButton
                        onClick={() => handleSaveEdit(video, editedValue)}
                        style={{
                            color: theme.palette.primary.main,
                        }}
                    >
                        <SaveIcon />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => setIsEditing(!isEditing)}>
                        <EditIcon />
                    </IconButton>
                )}
                <Typography
                    style={{
                        ...theme.typography.h6,
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                    }}
                >
                    {title}
                </Typography>
            </Container>
            <Container
                style={{
                    flex: 1,
                    marginLeft: theme.spacing(2),
                }}
            >
                <Typography style={{ whiteSpace: 'pre-line' }}>
                    {formatValue(value)}
                </Typography>
            </Container>
            {isEditing && (
                <Container>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                        InputProps={{
                            style: {
                                backgroundColor: 'white',
                                cursor: 'text',
                            },
                        }}
                        style={{
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                        }}
                    />
                </Container>
            )}
        </Container>
    )
}
