import React, { useState } from 'react'

import {
    IconButton,
    Switch,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { RemoveCircle } from '@material-ui/icons'

import { Container, EditSpan } from '../../../components'
import { InventoryConfig, StatusGroup } from '../../../models'
import { StatusGroupDisplay } from './StatusGroupDisplay'

interface Props {
    inventoryConfig: InventoryConfig
    theme: Theme
    statusGroupList: StatusGroup[]
    hasEditPermission: boolean
    onRemoveInvType: () => void
    updateInventoryConfig: (
        inventoryConfig: InventoryConfig,
        keyValuePairs: { key: string; value: string }[],
    ) => void
}

export const InventoryConfigRow = (props: Props) => {
    const {
        inventoryConfig,
        theme,
        statusGroupList,
        hasEditPermission,
        onRemoveInvType,
        updateInventoryConfig,
    } = props

    const [inventoryConfigName, setInventoryConfigName] = useState(
        inventoryConfig.name,
    )
    const [sku, setSku] = useState(inventoryConfig.sku)
    const [description, setDescription] = useState(inventoryConfig.description)

    return (
        <Container style={{ display: 'flex' }}>
            <Container
                style={{
                    padding: theme.spacing(1, 2, 1, 2),
                    border: `1px solid ${theme.palette.grey[400]}`,
                    flexDirection: 'column',
                    marginBottom: `-1px`, // Adjust margin to prevent double borders
                    flex: 1,
                }}
            >
                <EditSpan
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        color:
                            inventoryConfig.description.length > 0
                                ? theme.palette.blue.main
                                : theme.palette.grey[900],
                    }}
                    width={`${Math.max(
                        200,
                        inventoryConfig.name.length * 10,
                    )}px`}
                    onSave={() => {
                        updateInventoryConfig(inventoryConfig, [
                            { key: 'name', value: inventoryConfigName },
                            { key: 'sku', value: sku },
                            { key: 'description', value: description },
                        ])
                    }}
                    customPopoverContent={
                        <Container
                            style={{ flexDirection: 'column', width: '400px' }}
                        >
                            <TextField
                                label="Name"
                                value={inventoryConfigName}
                                onChange={(e) =>
                                    setInventoryConfigName(e.target.value)
                                }
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                variant="outlined"
                                size="small"
                                style={{
                                    margin: theme.spacing(1, 0),
                                }}
                            />
                            <TextField
                                label="SKU"
                                value={sku}
                                onChange={(e) => setSku(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Container>
                    }
                    disabled={!hasEditPermission}
                >
                    {inventoryConfig.name}
                </EditSpan>

                <Container style={{ alignItems: 'center' }}>
                    <Switch
                        checked={inventoryConfig.ins_has_note}
                        color="primary"
                        onChange={() => {
                            updateInventoryConfig(inventoryConfig, [
                                {
                                    key: 'ins_has_note',
                                    value: String(
                                        !inventoryConfig.ins_has_note,
                                    ),
                                },
                            ])
                        }}
                        disabled={!hasEditPermission}
                    />
                    <span style={{ ...theme.typography.subtitle2 }}>
                        Short Answer Question
                    </span>
                </Container>

                {inventoryConfig.ins_has_note ? (
                    <Container
                        style={{
                            border: `1px solid ${theme.palette.grey[400]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(1, 2),
                            margin: theme.spacing(2, 0),
                        }}
                    >
                        <span style={{ color: theme.palette.grey[600] }}>
                            Short Answer
                        </span>
                    </Container>
                ) : (
                    <StatusGroupDisplay
                        inventoryConfig={inventoryConfig}
                        theme={theme}
                        statusGroupList={statusGroupList}
                        hasEditPermission={hasEditPermission}
                        updateInventoryConfig={updateInventoryConfig}
                    />
                )}
            </Container>
            <Container
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Tooltip title="Remove Inventory Type">
                    <IconButton
                        onClick={onRemoveInvType}
                        style={{ marginLeft: theme.spacing(1) }}
                        size="small"
                    >
                        <RemoveCircle htmlColor={theme.palette.grey[500]} />
                    </IconButton>
                </Tooltip>
            </Container>
        </Container>
    )
}
