import React from 'react'
import { Container, Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    dot: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.text.secondary,
        margin: theme.spacing(0, 0.5),
        animation: '$bounce 1.4s infinite ease-in-out both',
    },
    '@keyframes bounce': {
        '0%, 80%, 100%': {
            transform: 'scale(0)',
        },
        '40%': {
            transform: 'scale(1)',
        },
    },
}))

interface Props {
    theme: Theme
}

export const TypingIndicator: React.FC<Props> = ({ theme }) => {
    const classes = useStyles(theme)

    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: theme.spacing(1),
            }}
        >
            <div className={classes.dot} style={{ animationDelay: '0s' }} />
            <div className={classes.dot} style={{ animationDelay: '0.2s' }} />
            <div className={classes.dot} style={{ animationDelay: '0.4s' }} />
        </Container>
    )
}
