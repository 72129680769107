import React, { useEffect, useState } from 'react'

import { useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { Container } from '../../components'
import { useSupportTicket } from '../../hooks/useSupportTicket'
import { usePagination } from '../../hooks'
import { SupportTicketRow } from './SupportTicketRow'
import { SupportTicket, SupportTicketStatus } from '../../models'

export const SupportTicketHome = () => {
    const {
        supportTicketList,
        getSupportTicketList,
        updateSupportTicket,
    } = useSupportTicket()

    const [statusFilter, setStatusFilter] = useState<string>('all')

    const filteredTickets = React.useMemo(() => {
        if (!supportTicketList) return []
        if (statusFilter === 'all') return supportTicketList
        return supportTicketList.filter(ticket =>
            statusFilter === 'open'
                ? ticket.status !== SupportTicketStatus.COMPLETE
                : ticket.status === SupportTicketStatus.COMPLETE
        )
    }, [supportTicketList, statusFilter])

    useEffect(() => {
        getSupportTicketList()
    }, [])

    const theme = useTheme()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        filteredTickets,
    )

    const handleComplete = async (ticket: SupportTicket) => {
        await updateSupportTicket(ticket, SupportTicketStatus.COMPLETE)
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                padding: theme.spacing(1),
            }}
        >
            {/* HEADER */}
            <Container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.darkGreen.main,
                    }}
                >
                    Support Tickets
                </span>
                <FormControl variant="outlined" style={{ minWidth: 120 }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={statusFilter}
                        onChange={(e) => {
                            setStatusFilter(e.target.value as string)
                            setPage(1)
                        }}
                        label="Status"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="complete">Complete</MenuItem>
                    </Select>
                </FormControl>
            </Container>
            {/* TABLE */}
            <Container
                style={{
                    flexDirection: 'column',
                    marginTop: theme.spacing(2),
                    maxHeight: 'calc(100vh - 200px',
                    minHeight: 'calc(100vh - 200px)',
                    overflowY: 'scroll',
                }}
            >
                {pageData.map((ticket) => (
                    <SupportTicketRow
                        key={ticket.id}
                        ticket={ticket}
                        theme={theme}
                        handleComplete={() => handleComplete(ticket)}
                    />
                ))}
            </Container>
            {/* PAGINATION */}
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {filteredTickets.length}
                </span>
            </Container>
        </Container>
    )
}
