import { useState } from 'react'
import { axiosInstance } from '../helpers'
import { Lease, User } from '../models'
import { toast } from 'react-toastify'

export const useMoveInInspection = () => {
    const [loading, setLoading] = useState(false)

    const [moveInEvents, setMoveInEvents] = useState<
        MoveInInspectionQueueEvent[]
    >([])

    const [selectedMoveInEventId, setSelectedMoveInEventId] = useState<
        number | null
    >(null)

    const getMoveInInspectionEvents = async (params?: { date: string }) => {
        setLoading(true)

        axiosInstance
            .get('inspection/move-in-event/', { params: params })
            .then((res) => {
                setMoveInEvents(res.data)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const queueLeaseMoveIns = async (
        leases: Lease[],
        daysToComplete: number,
        emailDate: Date,
    ) => {
        setLoading(true)

        const res = await axiosInstance.post('inspection/move-in-event/', {
            leases: leases.map((lease) => lease.id),
            days_to_complete: daysToComplete,
            send_date: emailDate.toISOString(),
        })

        setLoading(false)

        if (res instanceof Error) {
            return res
        }

        const event: MoveInInspectionQueueEvent = res.data

        setMoveInEvents([...moveInEvents, event])

        return event
    }

    const deleteQueuedEvent = async (event: MoveInInspectionQueueEvent) => {
        const res = await axiosInstance.delete(
            `inspection/move-in-event/${event.id}/`,
        )

        setLoading(false)

        if (res instanceof Error) {
            return res
        }

        setMoveInEvents(moveInEvents.filter((e) => e.id !== event.id))

        return event
    }

    const removeLeaseFromEvent = async (
        event: MoveInInspectionQueueEvent,
        lease: Lease,
    ) => {
        setLoading(true)

        const res = await axiosInstance
            .delete(`inspection/move-in-event/${event.id}/remove_lease/`, {
                params: { lease: lease.id },
            })
            .catch((e) => {
                return e
            })

        setLoading(false)

        if (res instanceof Error) {
            return res
        }

        const updatedEvent = {
            ...event,
            leases: event.leases.filter((l) => l.id !== lease.id),
        }

        setMoveInEvents(
            moveInEvents.map((e) => {
                if (e.id === event.id) {
                    return updatedEvent
                }
                return e
            }),
        )

        return updatedEvent
    }

    const startMoveInNow = async (event: MoveInInspectionQueueEvent) => {
        setLoading(true)
        const res = await axiosInstance
            .post(`inspection/move-in-event/${event.id}/start_now/`)
            .catch((e) => {
                return e
            })
        setLoading(false)

        if (res instanceof Error) {
            toast.error('Failed to start move in now')
            return null
        }

        setMoveInEvents(
            moveInEvents.map((e) => {
                if (e.id === event.id) {
                    return res.data
                }
                return e
            }),
        )

        toast.success('Move in started')

        const moveInEvent: MoveInInspectionQueueEvent = res.data

        return moveInEvent
    }

    const selectedMoveInEvent =
        moveInEvents.find((e) => e.id === selectedMoveInEventId) || null

    return {
        loading,
        moveInEvents,
        getMoveInInspectionEvents,
        queueLeaseMoveIns,
        selectedMoveInEvent,
        setSelectedMoveInEventId,
        deleteQueuedEvent,
        removeLeaseFromEvent,
        startMoveInNow,
    }
}

export type MoveInInspectionController = ReturnType<typeof useMoveInInspection>

export interface MoveInInspectionQueueEvent {
    id: number
    created_by: User | null
    handled: boolean
    apartment: number
    send_date: string
    created_date: string
    modified_date: string
    leases: Lease[]
}
