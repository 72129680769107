import React, { useState } from 'react'
import { Pagination } from '@material-ui/lab'
import { useTheme, TableSortLabel, Tooltip } from '@material-ui/core'
import { PropertyGradeRow } from './PropertyGradeRow'
import { Container } from '../../../components'
import {
    ChartData,
    PropertyGradeResponseData,
} from '../../../hooks/useAnalyticData'
import { usePagination } from '../../../hooks'
import { Range } from 'react-date-range'
import { DateFilter } from '../../../components/DateFilterButtons'

interface Props {
    propertyGradeList: PropertyGradeResponseData[] | null
    timeline: Range
    dateFilter: DateFilter
    chartData: ChartData | null
    selectedPropertyGrade: PropertyGradeResponseData | null
    getChartData: (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    getInspectionTypeGrades: (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    setPropertyGradeList: (
        propertyGradeList: PropertyGradeResponseData[],
    ) => void
    setOpenInspectionTypeModal: (open: boolean) => void
    setSelectedPropertyGrade: (propertyGrade: PropertyGradeResponseData) => void
}

export const PropertyGradeTable = (props: Props) => {
    const {
        propertyGradeList,
        timeline,
        dateFilter,
        chartData,
        selectedPropertyGrade,
        setPropertyGradeList,
        setSelectedPropertyGrade,
        getChartData,
        getInspectionTypeGrades,
        setOpenInspectionTypeModal,
    } = props
    const { page, setPage, pageData, numPages, start, end } = usePagination(
        propertyGradeList ?? [],
    )
    const [orderBy, setOrderBy] = useState('')

    const theme = useTheme()
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        justifyContent: 'center',
        flex: 1,
    }

    return (
        <Container direction="column" style={{ marginTop: theme.spacing(3) }}>
            {/* HEADERS */}
            <Container
                style={{
                    padding: theme.spacing(0, 1),
                }}
            >
                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== LocationASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.name.localeCompare(RowB.name),
                                )
                                setPropertyGradeList(newRows)
                                setOrderBy(LocationASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(LocationDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === LocationASC || orderBy === LocationDESC
                        }
                        direction={orderBy === LocationASC ? 'asc' : 'desc'}
                    >
                        Location
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== BipsASC) {
                                newRows.sort(
                                    (RowA, RowB) => RowA.bips - RowB.bips,
                                )
                                setPropertyGradeList(newRows)
                                setOrderBy(BipsASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(BipsDESC)
                            }
                        }
                    }}
                >
                    <Tooltip title="Building and Inspector Performance Score">
                        <TableSortLabel
                            active={orderBy === BipsASC || orderBy === BipsDESC}
                            direction={orderBy === BipsASC ? 'asc' : 'desc'}
                        >
                            BIPS
                        </TableSortLabel>
                    </Tooltip>
                </Container>
                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== BuildingASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.building_score -
                                        RowB.building_score,
                                )
                                setPropertyGradeList(newRows)
                                setOrderBy(BuildingASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(BuildingDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === BuildingASC || orderBy === BuildingDESC
                        }
                        direction={orderBy === BuildingASC ? 'asc' : 'desc'}
                    >
                        Building Health
                    </TableSortLabel>
                </Container>
                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== InspectorASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.inspector_score -
                                        RowB.inspector_score,
                                )
                                setPropertyGradeList(newRows)
                                setOrderBy(InspectorASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(InspectorDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === InspectorASC ||
                            orderBy === InspectorDESC
                        }
                        direction={orderBy === InspectorASC ? 'asc' : 'desc'}
                    >
                        Inspector Performance
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== LastUnitCompletedDateASC) {
                                newRows.sort((RowA, RowB) => {
                                    if (
                                        !RowA.last_unit_completed_date &&
                                        !RowB.last_unit_completed_date
                                    )
                                        return 0
                                    if (!RowA.last_unit_completed_date)
                                        return -1
                                    if (!RowB.last_unit_completed_date) return 1

                                    const dateA = new Date(
                                        RowA.last_unit_completed_date,
                                    )
                                    const dateB = new Date(
                                        RowB.last_unit_completed_date,
                                    )

                                    return dateA.getTime() - dateB.getTime()
                                })
                                setPropertyGradeList(newRows)
                                setOrderBy(LastUnitCompletedDateASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(LastUnitCompletedDateDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === LastUnitCompletedDateASC ||
                            orderBy === LastUnitCompletedDateDESC
                        }
                        direction={
                            orderBy === LastUnitCompletedDateASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        Last Unit Completed Date
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (propertyGradeList) {
                            const newRows = [...propertyGradeList]
                            if (orderBy !== TrendASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.trend_value - RowB.trend_value,
                                )
                                setPropertyGradeList(newRows)
                                setOrderBy(TrendASC)
                            } else {
                                setPropertyGradeList(newRows.reverse())
                                setOrderBy(TrendDESC)
                            }
                        }
                    }}
                >
                    <TableSortLabel
                        active={orderBy === TrendASC || orderBy === TrendDESC}
                        direction={orderBy === TrendASC ? 'asc' : 'desc'}
                    >
                        Trend
                    </TableSortLabel>
                </Container>
            </Container>
            {/* body */}
            <Container
                style={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 350px)',
                    minHeight: 'calc(100vh - 350px)',
                }}
                direction="column"
            >
                {pageData.map((propertyGrade, idx) => {
                    return (
                        <PropertyGradeRow
                            propertyGrade={propertyGrade}
                            chartData={chartData}
                            getChartData={getChartData}
                            getInspectionTypeGrades={getInspectionTypeGrades}
                            timeline={timeline}
                            dateFilter={dateFilter}
                            setOpenInspectionTypeModal={
                                setOpenInspectionTypeModal
                            }
                            setSelectedPropertyGrade={setSelectedPropertyGrade}
                            isSelected={
                                propertyGrade.id === selectedPropertyGrade?.id
                            }
                            key={`PROPERTY_GRADE_${propertyGrade.id}`}
                        />
                    )
                })}
            </Container>
            {/* footer */}
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {propertyGradeList?.length ?? 0}
                </span>
            </Container>
        </Container>
    )
}

const LocationASC = 'LocationASC'
const LocationDESC = 'LocationDESC'
const BuildingASC = 'BuildingASC'
const BuildingDESC = 'BuildingDESC'
const InspectorASC = 'InspectorASC'
const InspectorDESC = 'InspectorDESC'
const BipsASC = 'BipsASC'
const BipsDESC = 'BipsDESC'
const TrendASC = 'TrendASC'
const TrendDESC = 'TrendDESC'
const LastUnitCompletedDateASC = 'LastUnitCompletedDateASC'
const LastUnitCompletedDateDESC = 'LastUnitCompletedDateDESC'
