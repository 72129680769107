import React from 'react'

import { Modal, Paper, Slide } from '@material-ui/core'
import { useTheme } from '@material-ui/core'

import { Schedule, WorkorderAnalyticDict } from '../../models'
import { PortfolioScheduleAnalytics } from './PortfolioScheduleAnalytics'

interface Props {
    open: boolean
    schedule?: Schedule
    analyticDict: WorkorderAnalyticDict
    onClose: () => void
}

export const PortfolioScheduleAnalyticsModal = (props: Props) => {
    const { open, schedule, onClose, analyticDict } = props

    const theme = useTheme()

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h5,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        {schedule?.name} Statistics
                    </span>
                    <PortfolioScheduleAnalytics
                        schedule={schedule}
                        analyticDict={analyticDict}
                    />
                </Paper>
            </Slide>
        </Modal>
    )
}
