import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { axiosInstance } from '../../../../helpers'
import { AxiosResponse } from 'axios'
import { UnitMinified } from '../../../../models'

export const usePropertyUnits = (id: string) => {
    const queryKey = ['property', id, 'units']
    const queryClient = useQueryClient()

    const { data, isLoading, error } = useQuery<
        AxiosResponse<UnitMinified[]>,
        Error
    >({
        queryKey,
        queryFn: () =>
            axiosInstance.get(`/account-manager/properties/${id}/units/`),
    })

    const moveUnit = useMutation({
        mutationFn: ({
            unitId,
            newFolderId,
        }: {
            unitId: string
            newFolderId: string
        }) => {
            return axiosInstance.post(
                `/account-manager/properties/${id}/units/${unitId}/move/`,
                { new_folder_id: newFolderId },
            )
        },
        onMutate: async ({ unitId, newFolderId }) => {
            // Cancel any outgoing refetches to avoid overwriting our optimistic update
            await queryClient.cancelQueries({
                queryKey,
            })

            // Snapshot the previous value
            const previousUnits = queryClient.getQueryData(queryKey)

            // Optimistically update to the new value
            queryClient.setQueryData<AxiosResponse<UnitMinified[]>>(
                queryKey,
                (old) => {
                    if (!old?.data) return old
                    return {
                        ...old,
                        data: old.data.map((unit) =>
                            unit.id.toString() === unitId
                                ? { ...unit, folder: Number(newFolderId) }
                                : unit,
                        ),
                    }
                },
            )

            // Return context with the snapshotted value
            return { previousUnits }
        },
        onError: (err, variables, context) => {
            const safeContext = context as { previousUnits?: UnitMinified[] }
            const previousUnits = safeContext?.previousUnits
            // If the mutation fails, use the context we returned above to roll back
            if (previousUnits) {
                queryClient.setQueryData(queryKey, previousUnits)
            }
        },
        onSettled: () => {
            // Always refetch after error or success to ensure data consistency
            queryClient.invalidateQueries({
                queryKey,
            })
        },
    })

    const addUnits = useMutation({
        mutationFn: ({
            units,
            folderId,
            useAlphabeticalNaming,
        }: {
            units: {
                name: string
                bed_count: number
                bath_count: number
                common_count: number
                unit_type: string
            }[]
            folderId: string
            useAlphabeticalNaming: boolean
        }) => {
            return axiosInstance.post(
                `/account-manager/properties/${id}/units/bulk-create/`,
                {
                    folder_id: folderId,
                    units,
                    use_alphabetical_naming: useAlphabeticalNaming,
                },
            )
        },
        onError: (err, variables, context) => {
            console.log(err)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey,
            })
        },
    })

    const renameUnit = useMutation({
        mutationFn: async ({
            unitId,
            newName,
        }: {
            unitId: string
            newName: string
        }) => {
            const response = await axiosInstance.post(
                `/account-manager/properties/${id}/units/${unitId}/rename/`,
                {
                    name: newName,
                },
            )
            return response.data
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey })
        },
    })

    return {
        units: data?.data,
        isLoading,
        error,
        moveUnit,
        addUnits,
        renameUnit,
    }
}
