import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_ENDPOINT

export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 100000,
    // TODO: reimplement when we implement a more permanent timezone bugfix
    // params: { tz_offset: -(new Date().getTimezoneOffset()) },
})

export const APPLICATION_VERSION = 'V5.4.25'
