import React, { useState } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    TextField,
    IconButton,
    TableSortLabel,
    Switch,
    FormControlLabel,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { FolderMinified } from '../../../../../models/Infrastructure/types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

interface BulkUnit {
    name: string
    bedCount: number
    bathCount: number
    commonCount: number
    type: string
}

interface BulkUnitsModalProps {
    selectedFolder: FolderMinified | null
    onClose: () => void
    onSubmit: (units: BulkUnit[], useAlphabeticalNaming: boolean) => void
}

type Order = 'asc' | 'desc'
type OrderBy = keyof BulkUnit

export const BulkUnitsModal: React.FC<BulkUnitsModalProps> = ({
    selectedFolder,
    onClose,
    onSubmit,
}) => {
    const [units, setUnits] = useState<BulkUnit[]>([])
    const [newUnit, setNewUnit] = useState<BulkUnit>({
        name: '',
        bedCount: 0,
        bathCount: 0,
        commonCount: 0,
        type: '',
    })
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<OrderBy>('name')
    const [useAlphabeticalNaming, setUseAlphabeticalNaming] = useState(false)

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault()
        const pastedData = e.clipboardData.getData('text')
        const rows = pastedData.split('\n').filter((row) => row.trim())

        const parsedUnits = rows.map((row) => {
            const [name, bedCount, bathCount, commonCount, type] = row.split(
                '\t',
            )
            return {
                name: name?.trim() || '',
                bedCount: Number(bedCount) || 0,
                bathCount: Number(bathCount) || 0,
                commonCount: Number(commonCount) || 0,
                type: type?.trim() || '',
            }
        })

        setUnits([...units, ...parsedUnits])
    }

    const handleClose = () => {
        setUnits([])
        setNewUnit({
            name: '',
            bedCount: 0,
            bathCount: 0,
            commonCount: 0,
            type: '',
        })
        onClose()
    }

    const handleSubmit = () => {
        onSubmit(units, useAlphabeticalNaming)
        handleClose()
    }

    const handleAddRow = () => {
        if (newUnit.name.trim() || newUnit.type.trim()) {
            setUnits([...units, newUnit])
            setNewUnit({
                name: '',
                bedCount: 0,
                bathCount: 0,
                commonCount: 0,
                type: '',
            })
        }
    }

    const handleDeleteRow = (index: number) => {
        const updatedUnits = units.filter((_, i) => i !== index)
        setUnits(updatedUnits)
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleAddRow()
        }
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleRequestSort = (property: OrderBy) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const sortedUnits = React.useMemo(() => {
        const comparator = (a: BulkUnit, b: BulkUnit) => {
            if (orderBy === 'name' || orderBy === 'type') {
                return order === 'asc'
                    ? a[orderBy].localeCompare(b[orderBy])
                    : b[orderBy].localeCompare(a[orderBy])
            } else {
                return order === 'asc'
                    ? a[orderBy] - b[orderBy]
                    : b[orderBy] - a[orderBy]
            }
        }

        return [...units].sort(comparator)
    }, [units, order, orderBy])

    const displayedUnits = sortedUnits.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    )

    return (
        <Dialog
            open={!!selectedFolder}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    height: '80vh',
                },
            }}
        >
            <DialogTitle>Bulk Add Units</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px',
                        gap: '16px',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Copy and paste data from Excel/Spreadsheet or add units
                        manually
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Typography variant="body2">Naming scheme:</Typography>
                        <ToggleButtonGroup
                            value={useAlphabeticalNaming}
                            exclusive
                            onChange={(_, value) =>
                                setUseAlphabeticalNaming(value)
                            }
                            size="small"
                        >
                            <ToggleButton value={false}>Numeric</ToggleButton>
                            <ToggleButton value={true}>
                                Alphabetical
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <TableContainer
                    component={Paper}
                    onPaste={handlePaste}
                    tabIndex={0}
                    style={{
                        height: 'calc(100% - 100px)',
                        marginTop: '16px',
                        cursor: 'text',
                        outline: 'none',
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        direction={
                                            orderBy === 'name' ? order : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort('name')
                                        }
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'bedCount'}
                                        direction={
                                            orderBy === 'bedCount'
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort('bedCount')
                                        }
                                    >
                                        Bed Count
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'bathCount'}
                                        direction={
                                            orderBy === 'bathCount'
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort('bathCount')
                                        }
                                    >
                                        Bath Count
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'commonCount'}
                                        direction={
                                            orderBy === 'commonCount'
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort('commonCount')
                                        }
                                    >
                                        Common Count
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'type'}
                                        direction={
                                            orderBy === 'type' ? order : 'asc'
                                        }
                                        onClick={() =>
                                            handleRequestSort('type')
                                        }
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width="50px"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedUnits.map((unit, index) => (
                                <TableRow key={index}>
                                    <TableCell>{unit.name}</TableCell>
                                    <TableCell>{unit.bedCount}</TableCell>
                                    <TableCell>{unit.bathCount}</TableCell>
                                    <TableCell>{unit.commonCount}</TableCell>
                                    <TableCell>{unit.type}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleDeleteRow(
                                                    page * rowsPerPage + index,
                                                )
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Unit name"
                                        value={newUnit.name}
                                        onChange={(e) =>
                                            setNewUnit({
                                                ...newUnit,
                                                name: e.target.value,
                                            })
                                        }
                                        onKeyDown={handleKeyPress}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="Bed count"
                                        value={newUnit.bedCount}
                                        onChange={(e) =>
                                            setNewUnit({
                                                ...newUnit,
                                                bedCount: Number(
                                                    e.target.value,
                                                ),
                                            })
                                        }
                                        onKeyDown={handleKeyPress}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="Bath count"
                                        value={newUnit.bathCount}
                                        onChange={(e) =>
                                            setNewUnit({
                                                ...newUnit,
                                                bathCount: Number(
                                                    e.target.value,
                                                ),
                                            })
                                        }
                                        onKeyDown={handleKeyPress}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        placeholder="Common count"
                                        value={newUnit.commonCount}
                                        onChange={(e) =>
                                            setNewUnit({
                                                ...newUnit,
                                                commonCount: Number(
                                                    e.target.value,
                                                ),
                                            })
                                        }
                                        onKeyDown={handleKeyPress}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Unit type"
                                        value={newUnit.type}
                                        onChange={(e) =>
                                            setNewUnit({
                                                ...newUnit,
                                                type: e.target.value,
                                            })
                                        }
                                        onKeyDown={handleKeyPress}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={handleAddRow}
                                        disabled={
                                            !newUnit.name.trim() &&
                                            !newUnit.type.trim()
                                        }
                                    >
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            {units.length === 0 && (
                                <TableRow style={{ padding: 16 }}>
                                    <TableCell colSpan={6} align="center">
                                        Click here and paste your data (Ctrl+V)
                                        or add units manually
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={units.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </DialogContent>
            <DialogActions>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginRight: 'auto' }}
                >
                    Total Units: {units.length}
                </Typography>
                <Button
                    onClick={() => setUnits([])}
                    color="secondary"
                    disabled={units.length === 0}
                >
                    Clear All
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => onSubmit(units, useAlphabeticalNaming)}
                    color="primary"
                    variant="contained"
                    disabled={units.length === 0}
                >
                    Add Units
                </Button>
            </DialogActions>
        </Dialog>
    )
}
