import React, { useEffect, useMemo, useState, useCallback } from 'react'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    CircularProgress,
    Checkbox,
    IconButton,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core'

import { Routes } from '../../../../navigation/config/routes'

import { BulkAddUserForm } from './BulkAddUserForm'

import { toast } from 'react-toastify'

import InfoIcon from '@material-ui/icons/Info'

import { RowData, HeadCell } from '../../../../components'
import { Address, BaseCompany, IdBoolMap } from '../../../../models'
import { axiosInstance, resolveRoute } from '../../../../helpers'

import { usePropertyTable } from '../hooks/usePropertyTable'
import { SearchHeader } from './SearchHeader'
import { GLOBAL_TOP_BAR_HEIGHT } from '../../../../constants'
import { FOOTER_HEIGHT, SEARCH_HEADER_HEIGHT } from './constants'
import { useHistory } from 'react-router-dom'

const TABLE_HEAD_CELLS: HeadCell<RowData>[] = [
    { id: 'check', label: '', align: 'left', disablePadding: false, width: 50 },
    { id: 'id', label: 'ID', align: 'left', disablePadding: false, width: 70 },
    {
        id: 'type',
        label: 'Type',
        align: 'left',
        disablePadding: false,
        width: 100,
    },
    {
        id: 'organization',
        label: 'Organization',
        align: 'left',
        disablePadding: false,
        width: 200,
    },
    {
        id: 'name',
        label: 'Name',
        align: 'left',
        disablePadding: false,
        width: 200,
    },
    {
        id: 'invoice_code',
        label: 'Invoice Code',
        align: 'left',
        disablePadding: false,
        width: 120,
    },
    {
        id: 'address',
        label: 'Address',
        align: 'left',
        disablePadding: false,
        width: 250,
    },
    {
        id: 'actions',
        label: '',
        align: 'left',
        disablePadding: false,
        width: 50,
    },
]

const useLocalStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${GLOBAL_TOP_BAR_HEIGHT}px)`,
        maxHeight: `calc(100vh - ${GLOBAL_TOP_BAR_HEIGHT}px)`,
        padding: theme.spacing(2),
    },
    searchContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        flex: 1,
        minHeight: SEARCH_HEADER_HEIGHT,
        maxHeight: SEARCH_HEADER_HEIGHT,
    },
    tableWrapper: {
        flex: 1,
        display: 'flex',
        overflow: 'scroll',
        flexDirection: 'column',
    },

    loadingOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    },
    stickyHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 750,
    },
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        minHeight: FOOTER_HEIGHT,
        maxHeight: FOOTER_HEIGHT,
    },
}))

export const PropertyList = () => {
    const localClasses = useLocalStyles()

    const history = useHistory()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const [searchState, setSearchState] = useState({
        text: '',
        userEmail: '',
    })

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        selectedWorkspace: null as BaseCompany | null,
    })

    const [
        selectedWorkspacesMap,
        setSelectedWorkspacesMap,
    ] = useState<IdBoolMap>({})

    const {
        propertyList,
        getFilteredRows,
        isLoading,
        isError,
        error,
        refetch,
    } = usePropertyTable()

    const filteredRows = useMemo(() => getFilteredRows(searchState.text), [
        getFilteredRows,
        searchState.text,
    ])

    const selectedWorkspaceIds = useMemo(
        () =>
            Object.entries(selectedWorkspacesMap)
                .filter(([_, isSelected]) => isSelected)
                .map(([id]) => Number(id)),
        [selectedWorkspacesMap],
    )

    const handleSearchChange = useCallback(
        (field: keyof typeof searchState) => (
            e: React.ChangeEvent<HTMLInputElement>,
        ) => {
            setSearchState((prev) => ({
                ...prev,
                [field]: e.target.value,
            }))
        },
        [],
    )

    const handleUserSearch = useCallback(() => {
        refetch()
    }, [refetch])

    const handleClearUserSearch = useCallback(() => {
        setSearchState((prev) => ({ ...prev, userEmail: '' }))
        refetch()
    }, [refetch])

    const handleWorkspaceSelection = useCallback(
        (id: number, isSelected: boolean) => {
            setSelectedWorkspacesMap((prev) => ({
                ...prev,
                [id]: isSelected,
            }))
        },
        [],
    )

    const handleBulkAddUser = useCallback(
        async (email: string) => {
            try {
                await axiosInstance.post('user/_admin/', {
                    email,
                    workspaces: selectedWorkspaceIds,
                })
                toast.success(
                    `${email} added to ${selectedWorkspaceIds.length} workspaces`,
                )
            } catch (e: any) {
                toast.error(`Error: ${e.response?.data || 'Unknown error'}`, {
                    autoClose: 5000,
                })
            }
        },
        [selectedWorkspaceIds],
    )

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const paginatedRows = useMemo(() => {
        const startIndex = page * rowsPerPage
        return filteredRows.slice(startIndex, startIndex + rowsPerPage)
    }, [filteredRows, page, rowsPerPage])

    return (
        <div className={localClasses.contentWrapper}>
            {/* Search Header */}
            <div className={localClasses.searchContainer}>
                <SearchHeader
                    searchState={searchState}
                    onSearchChange={handleSearchChange}
                    onUserSearch={handleUserSearch}
                    onClearUserSearch={handleClearUserSearch}
                    selectedCount={selectedWorkspaceIds.length}
                    onBulkAdd={() =>
                        setDialogState((prev) => ({ ...prev, isOpen: true }))
                    }
                    onDeselectAll={() => setSelectedWorkspacesMap({})}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        refetch()
                    }}
                >
                    Reload
                </Button>
            </div>

            <TableContainer
                component={Paper}
                className={localClasses.tableWrapper}
            >
                <Table className={localClasses.table} size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {TABLE_HEAD_CELLS.map((cell) => (
                                <TableCell
                                    key={cell.id}
                                    align={cell.align}
                                    padding={
                                        cell.disablePadding ? 'none' : 'default'
                                    }
                                    className={localClasses.stickyHeader}
                                    style={{ width: cell.width }}
                                >
                                    {cell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedRows.map((row) => (
                            <TableRow
                                key={`ROW_${row.id.value}`}
                                hover
                                className={localClasses.tableRow}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={
                                            selectedWorkspacesMap[
                                                row.id.value
                                            ] === true
                                        }
                                        onChange={(e) =>
                                            handleWorkspaceSelection(
                                                row.id.value,
                                                e.target.checked,
                                            )
                                        }
                                        color="primary"
                                    />
                                </TableCell>
                                <TableCell>{row.id.value}</TableCell>
                                <TableCell>{row.type.value}</TableCell>
                                <TableCell>{row.organization.value}</TableCell>
                                <TableCell>{row.name.value}</TableCell>
                                <TableCell>{row.invoice_code.value}</TableCell>
                                <TableCell>
                                    <AddressCell address={row.address.value} />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            history.push(
                                                resolveRoute(
                                                    Routes.admin.properties
                                                        .detail,
                                                    ':id',
                                                    row.id.value,
                                                ),
                                            )
                                        }}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Footer */}
            <div className={localClasses.footer}>
                <TablePagination
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </div>

            {isLoading && (
                <div className={localClasses.loadingOverlay}>
                    <CircularProgress />
                </div>
            )}

            <BulkAddUserForm
                open={dialogState.isOpen}
                workspaceCount={selectedWorkspaceIds.length}
                onClose={() =>
                    setDialogState((prev) => ({ ...prev, isOpen: false }))
                }
                onConfirm={handleBulkAddUser}
            />
        </div>
    )
}

const AddressCell = ({ address }: { address: Address | null }) => {
    if (!address) {
        return <Typography variant="subtitle2">N/A</Typography>
    }

    return (
        <div>
            <Typography variant="subtitle2">{address.address1}</Typography>
            <Typography variant="subtitle2">
                {address.city}, {address.state} {address.zip_code}
            </Typography>
        </div>
    )
}
