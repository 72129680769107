import React, { PropsWithChildren, useState } from 'react'
import { useDragContext } from './DragContext'
import { DraggableItem } from './types'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    handleDrop: (
        event: React.DragEvent<HTMLDivElement>,
        items: DraggableItem[],
    ) => void
    dragOverStyle?: React.CSSProperties
    acceptedTypes?: Array<string>
}

export const Droppable = (props: PropsWithChildren<Props>) => {
    const [isDragOver, setIsDragOver] = useState(false)

    const { selectedMap, clearSelectedItems, setIsDragging } = useDragContext()

    let droppableStyle = { ...props.style }
    if (isDragOver) {
        droppableStyle = { ...droppableStyle, ...props.dragOverStyle }
    }

    return (
        <div
            {...props}
            style={droppableStyle}
            onDragOver={(e) => {
                if (!props.acceptedTypes) {
                    e.preventDefault()
                    setIsDragOver(true)
                } else {
                    // Check if any of the accepted types are present in the dataTransfer
                    const isAccepted = props.acceptedTypes.some((type) => {
                        let isAccepted = false
                        selectedMap.forEach((value) => {
                            if (value.dragType === type) {
                                isAccepted = true
                            }
                        })
                        return isAccepted
                    })

                    if (isAccepted) {
                        e.preventDefault()
                        setIsDragOver(true)
                    }
                }
            }}
            onDragEnter={(e) => {
                e.preventDefault()
            }}
            onDragLeave={(e) => {
                setIsDragOver(false)
            }}
            onDrop={(e) => {
                setIsDragOver(false)
                const items = Array.from(selectedMap.values())
                props.handleDrop(e, items)

                clearSelectedItems()
                setIsDragging(false)
            }}
        >
            {props.children}
        </div>
    )
}
