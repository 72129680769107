import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    Schedule,
    AreaStatusTag,
    ScheduleBudgetItem,
    PortfolioSchedule,
    PortfolioBudget,
} from '../../../models/'

// Requests

export interface CreateAreaStatusTagRequest {
    body: {
        schedule: number
        area: number
        area_status_config?: number
        locked?: boolean
    }
}

export interface EditAreaStatusTagRequest {
    id: number
    body: {
        area_status_config?: number
        locked?: boolean
        key?: boolean
    }
}

export interface GetScheduleListRequest {
    params?: {
        // only true cause it does nothing on false
        no_inspection?: true
        active?: boolean
    }
}

export interface GetAreaStatusTagListRequest {
    params: {
        schedule: number
    }
}

export interface GetScheduleDetailRequest {
    scheduleId: number | string
    params?: {
        portfolio?: boolean
    }
    scheduleCallbacks?: ((schedule: Schedule) => ScheduleActionTypes)[]
    scheduleBudgetItemsCallbacks?: ((
        budgetItems: ScheduleBudgetItem[],
    ) => ScheduleActionTypes)[]
}

export interface CreateScheduleRequest {
    body: {
        name: string
        schedule_id: string
        start_date: string
        baseline_budget: number
        end_date?: string
        inspection?: number
        color?: string
        new_inspection?: {
            inspection_type?: number
        }
    }
}

export interface UpdateScheduleRequest {
    scheduleId: number
    body: {
        name?: string
        start_date?: string
        end_date?: string
        active?: boolean
        baseline_budget?: number
        inspection?: number
        color?: string
        new_inspection?: {
            inspection_type?: number
        }
    }
    params?: {
        portfolio?: boolean
    }
}

export interface UploadScheduleSheetRequest {
    scheduleId: number
    sheet: File
}

export interface ResetScheduleRequest {
    scheduleId: number
    body: {
        super_password: string
    }
}

export interface CreateScheduleBudgetItemRequet {
    body: {
        schedule: number
        description: string
        cost: number
    }
    dispatchCallbacks?: ((
        budgetItem: ScheduleBudgetItem,
    ) => ScheduleActionTypes)[]
}

export interface DeleteScheduleBudgetItemRequest {
    scheduleBudgetItem: number
    params?: {
        portfolio?: boolean
    }
}

export interface GetPortfolioBudgetRequest {
    params?: GetPortfolioScheduleListParams
}

export type filterOperator = 'gte' | 'lte' | 'lt' | 'gt'

export const APARTMENT_PARAM = 'apartment'
export const APARTMENT_EXCLUDE_PARAM = 'apartment_exclude'
export const ZIP_CODE_PARAM = 'zip_code'
export const ZIP_CODE_EXCLUDE_PARAM = 'zip_code_exclude'
export const NAME_PARAM = 'name'
export const HAS_INSPECTION_PARAM = 'has_inspection'
export const STATE_PARAM = 'state'
export const STATE_EXCLUDE_PARAM = 'state_exclude'
export const DAYS_REMAINING_PARAM = 'days_remaining'
export const DAYS_REMAINING_OPERATOR_PARAM = 'days_remaining_operator'
export const SCHEDULE_COMPLETE_PARAM = 'schedule_complete'
export const SCHEDULE_COMPLETE_OPERATOR_PARAM = 'schedule_complete_operator'
export const SCHEDULE_APPROVED_PARAM = 'schedule_approved'
export const SCHEDULE_APPROVED_OPERATOR_PARAM = 'schedule_approved_operator'
export const SCHEDULE_INVOICED_PARAM = 'schedule_invoiced'
export const SCHEDULE_INVOICED_OPERATOR_PARAM = 'schedule_invoiced_operator'
export const INSPECTION_ASSIGNED_PARAM = 'inspection_assigned'
export const INSPECTION_ASSIGNED_OPERATOR_PARAM = 'inspection_assigned_operator'
export const INSPECTION_COMPLETE_PARAM = 'inspection_complete'
export const INSPECTION_COMPLETE_OPERATOR_PARAM = 'inspection_complete_operator'
export const INSPECTION_HANDLED_PARAM = 'inspection_handled'
export const INSPECTION_HANDLED_OPERATOR_PARAM = 'inspection_handled_operator'
export const SET_BUDGET_PARAM = 'set_budget'
export const SET_BUDGET_OPERATOR_PARAM = 'set_budget_operator'
export const FORECASTED_BUDGET_PARAM = 'forecasted_budget'
export const FORECASTED_BUDGET_OPERATOR_PARAM = 'forecasted_budget_operator'
export const SET_OVER_FORECASTED_PARAM = 'set_over_forecasted'
export const CHECKLIST_ITEM_PARAM = 'checklist_item'
export const CHECKLIST_ITEM_EXCLUDE_PARAM = 'checklist_item_exclude'

export interface GetPortfolioScheduleListParams {
    [APARTMENT_PARAM]?: number
    [APARTMENT_EXCLUDE_PARAM]?: boolean
    [ZIP_CODE_PARAM]?: string
    [ZIP_CODE_EXCLUDE_PARAM]?: string
    [NAME_PARAM]?: string
    [HAS_INSPECTION_PARAM]?: boolean
    [STATE_PARAM]?: string
    [STATE_EXCLUDE_PARAM]?: boolean
    [DAYS_REMAINING_PARAM]?: number
    [DAYS_REMAINING_OPERATOR_PARAM]?: filterOperator
    [SCHEDULE_COMPLETE_PARAM]?: number
    [SCHEDULE_COMPLETE_OPERATOR_PARAM]?: filterOperator
    [SCHEDULE_APPROVED_PARAM]?: number
    [SCHEDULE_APPROVED_OPERATOR_PARAM]?: filterOperator
    [SCHEDULE_INVOICED_PARAM]?: number
    [SCHEDULE_INVOICED_OPERATOR_PARAM]?: filterOperator
    [INSPECTION_ASSIGNED_PARAM]?: number
    [INSPECTION_ASSIGNED_OPERATOR_PARAM]?: filterOperator
    [INSPECTION_COMPLETE_PARAM]?: number
    [INSPECTION_COMPLETE_OPERATOR_PARAM]?: filterOperator
    [INSPECTION_HANDLED_PARAM]?: number
    [INSPECTION_HANDLED_OPERATOR_PARAM]?: filterOperator
    [SET_BUDGET_PARAM]?: number
    [SET_BUDGET_OPERATOR_PARAM]?: filterOperator
    [FORECASTED_BUDGET_PARAM]?: number
    [FORECASTED_BUDGET_OPERATOR_PARAM]?: filterOperator
    [SET_OVER_FORECASTED_PARAM]?: boolean
    [CHECKLIST_ITEM_PARAM]?: number
    [CHECKLIST_ITEM_EXCLUDE_PARAM]?: boolean
    organization?: number
}

interface PortfolioParamsWithPagination extends GetPortfolioScheduleListParams {
    page_size?: number
}

export interface GetPortfolioScheduleListRequest {
    url?: string
    params?: PortfolioParamsWithPagination
}

// Responses

// actions
export const GET_SCHEDULE_LIST_REQUEST = 'GET_SCHEDULE_LIST_REQUEST'
export const GET_SCHEDULE_LIST_RESPONSE = 'GET_SCHEDULE_LIST_RESPONSE'
export const GET_SCHEDULE_DETAIL_REQUEST = 'GET_SCHEDULE_DETAIL_REQUEST'
export const GET_SCHEDULE_DETAIL_RESPONSE = 'GET_SCHEDULE_DETAIL_RESPONSE'
export const CREATE_SCHEDULE_REQUEST = 'CREATE_SCHEDULE_REQUEST'
export const CREATE_SCHEDULE_RESPONSE = 'CREATE_SCHEDULE_RESPONSE'
export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST'
export const UPDATE_SCHEDULE_RESPONSE = 'UPDATE_SCHEDULE_RESPONSE'
export const GET_SCHEDULE_SHEET_REQUEST = 'GET_SCHEDULE_SHEET_REQUEST'
export const UPLOAD_TURN_SHEET_REQUEST = 'UPLOAD_TURN_SHEET_REQUEST'
export const RESET_SCHEDULE_REQUEST = 'RESET_SCHEDULE_REQUEST'
export const CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST =
    'CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST'
export const DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST =
    'DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST'
export const SET_SCHEDULE_LOADING = 'SET_SCHEDULE_LOADING'
export const SET_SCHEDULE_DETAIL = 'SET_SCHEDULE_DETAIL'
export const GET_AREA_STATUS_TAG_LIST_REQUEST =
    'GET_AREA_STATUS_TAG_LIST_REQUEST'
export const CREATE_AREA_STATUS_TAG_REQUEST = 'CREATE_AREA_STATUS_TAG_REQUEST'
export const EDIT_AREA_STATUS_TAG_REQUEST = 'EDIT_AREA_STATUS_TAG_REQUEST'
export const GET_PORTFOLIO_SCHEDULE_LIST_REQUEST =
    'GET_PORTFOLIO_SCHEDULE_LIST_REQUEST'
export const SET_PORTFOLIO_SCHEDULE_LIST = 'SET_PORTFOLIO_SCHEDULE_LIST'
export const SET_PORTFOLIO_SCHEDULE_DETAIL = 'SET_PORTFOLIO_SCHEDULE_DETAIL'
export const SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST =
    'SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST'
export const SET_PORTFOLIO_APARTMENT_COUNT = 'SET_PORTFOLIO_APARTMENT_COUNT'
export const SET_PORTFOLIO_INSPECTION_COUNT = 'SET_PORTFOLIO_INSPECTION_COUNT'
export const ADD_TO_PORTFOLIO_SCHEDULE_LIST = 'ADD_TO_PORTFOLIO_SCHEDULE_LIST'
export const SET_AREA_STATUS_TAG_LIST = 'SET_AREA_STATUS_TAG_LIST'
export const ADD_AREA_STATUS_TAG = 'ADD_AREA_STATUS_TAG'
export const UPDATE_AREA_STATUS_TAG = 'UPDATE_AREA_STATUS_TAG'
export const ADD_SCHEUDLE_BUDGET_ITEM = 'ADD_SCHEUDLE_BUDGET_ITEM'
export const ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM =
    'ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM'
export const REMOVE_SCHEUDLE_BUDGET_ITEM = 'REMOVE_SCHEUDLE_BUDGET_ITEM'
export const SET_SCHEDULE_BUDGET_ITEM_LIST = 'SET_SCHEDULE_BUDGET_ITEM_LIST'
export const GET_PORTFOLIO_BUDGET = 'GET_PORTFOLIO_BUDGET'
export const SET_PORTFOLIO_BUDGET = 'SET_PORTFOLIO_BUDGET'

interface GetScheduleListRequestAction extends Action {
    type: typeof GET_SCHEDULE_LIST_REQUEST
}

interface GetScheduleListResponseAction extends Action {
    type: typeof GET_SCHEDULE_LIST_RESPONSE
    payload: Schedule[]
}

interface GetScheduleDetailRequestAction extends Action {
    type: typeof GET_SCHEDULE_DETAIL_REQUEST
}

interface GetScheduleDetailResponseAction extends Action {
    type: typeof GET_SCHEDULE_DETAIL_RESPONSE
    payload: Schedule
}

interface SetPortfolioScheduleDetailAction extends Action {
    type: typeof SET_PORTFOLIO_SCHEDULE_DETAIL
    schedule?: Schedule
}

interface SetPortfolioScheduleBudgetItemList extends Action {
    type: typeof SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST
    scheduleBudgetItemList?: ScheduleBudgetItem[]
}

interface CreateScheduleRequestAction extends Action {
    type: typeof CREATE_SCHEDULE_REQUEST
}

interface CreateScheduleResponseAction extends Action {
    type: typeof CREATE_SCHEDULE_RESPONSE
    payload: Schedule
}

interface UpdateScheduleRequestAction extends Action {
    type: typeof UPDATE_SCHEDULE_REQUEST
}

interface UpdateScheduleResponseAction extends Action {
    type: typeof UPDATE_SCHEDULE_RESPONSE
    payload: Schedule
    params?: {
        portfolio?: boolean
    }
}

interface SetScheduleLoadingRequest extends Action {
    type: typeof SET_SCHEDULE_LOADING
    request: ScheduleRequest
    isLoading: boolean
}

interface UploadTurnSheetRequestAction extends Action {
    type: typeof UPLOAD_TURN_SHEET_REQUEST
}

interface RestScheduleRequestAction extends Action {
    type: typeof UPLOAD_TURN_SHEET_REQUEST
}

interface SetScheduleDetailAction extends Action {
    type: typeof SET_SCHEDULE_DETAIL
    schedule?: Schedule
}

interface SetAreaStatusTagList extends Action {
    type: typeof SET_AREA_STATUS_TAG_LIST
    areaStatusTagList?: AreaStatusTag[]
}

interface AddAreaStatusTagAction extends Action {
    type: typeof ADD_AREA_STATUS_TAG
    areaStatusTag: AreaStatusTag
}

interface UpdateAreaStatusTagAction extends Action {
    type: typeof UPDATE_AREA_STATUS_TAG
    areaStatusTag: AreaStatusTag
}

interface AddScheudleBudgetItem extends Action {
    type: typeof ADD_SCHEUDLE_BUDGET_ITEM
    scheduleBudgetItem: ScheduleBudgetItem
}

interface RemoveScheduleBudgetItem extends Action {
    type: typeof REMOVE_SCHEUDLE_BUDGET_ITEM
    id: number
}

interface AddPortfolioScheduleBudgetItem extends Action {
    type: typeof ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM
    scheduleBudgetItem: ScheduleBudgetItem
}

interface SetScheduleBudgetItemList extends Action {
    type: typeof SET_SCHEDULE_BUDGET_ITEM_LIST
    scheduleBudgetItemList?: ScheduleBudgetItem[]
}

interface SetPortfolioScheduleList extends Action {
    type: typeof SET_PORTFOLIO_SCHEDULE_LIST
    portfolioScheduleList?: PortfolioSchedule[]
}
interface SetPortfolioInspectionCount extends Action {
    type: typeof SET_PORTFOLIO_INSPECTION_COUNT
    count?: number
}
interface SetPortfolioApartmentCount extends Action {
    type: typeof SET_PORTFOLIO_APARTMENT_COUNT
    count?: number
}
interface AddToPortfolioScheduleList extends Action {
    type: typeof ADD_TO_PORTFOLIO_SCHEDULE_LIST
    portfolioScheduleList: PortfolioSchedule[]
}

interface SetPortfolioBudget extends Action {
    type: typeof SET_PORTFOLIO_BUDGET
    budget?: PortfolioBudget
}

export type ScheduleRequest =
    | typeof GET_SCHEDULE_LIST_REQUEST
    | typeof GET_SCHEDULE_DETAIL_REQUEST
    | typeof CREATE_SCHEDULE_REQUEST
    | typeof UPDATE_SCHEDULE_REQUEST
    | typeof GET_SCHEDULE_SHEET_REQUEST
    | typeof UPLOAD_TURN_SHEET_REQUEST
    | typeof RESET_SCHEDULE_REQUEST
    | typeof GET_AREA_STATUS_TAG_LIST_REQUEST
    | typeof CREATE_AREA_STATUS_TAG_REQUEST
    | typeof EDIT_AREA_STATUS_TAG_REQUEST
    | typeof CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST
    | typeof DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST
    | typeof GET_PORTFOLIO_SCHEDULE_LIST_REQUEST
    | typeof GET_PORTFOLIO_BUDGET

export type ScheduleActionTypes =
    | GetScheduleListRequestAction
    | GetScheduleListResponseAction
    | GetScheduleDetailRequestAction
    | GetScheduleDetailResponseAction
    | CreateScheduleRequestAction
    | CreateScheduleResponseAction
    | UpdateScheduleRequestAction
    | UpdateScheduleResponseAction
    | UploadTurnSheetRequestAction
    | SetScheduleLoadingRequest
    | RestScheduleRequestAction
    | SetScheduleDetailAction
    | SetAreaStatusTagList
    | AddAreaStatusTagAction
    | UpdateAreaStatusTagAction
    | AddScheudleBudgetItem
    | RemoveScheduleBudgetItem
    | SetScheduleBudgetItemList
    | SetPortfolioScheduleList
    | AddToPortfolioScheduleList
    | SetPortfolioApartmentCount
    | SetPortfolioInspectionCount
    | SetPortfolioBudget
    | SetPortfolioScheduleBudgetItemList
    | SetPortfolioScheduleDetailAction
    | AddPortfolioScheduleBudgetItem

// Thunks
export type GetScheduleListActionThunk = Promise<AxiosResponse<Schedule[]>>
export type ScheduleDetailActionThunk = Promise<AxiosResponse<Schedule>>
export type AreaStatusTagListThunk = Promise<AxiosResponse<AreaStatusTag[]>>
export type AreaStatusTagThunk = Promise<AxiosResponse<AreaStatusTag>>
export type ScheduleBudgetItemThunk = Promise<AxiosResponse<ScheduleBudgetItem>>
export type PortfolioBudgetThunk = Promise<AxiosResponse<PortfolioBudget>>

// reducers
export interface ScheduleState {
    scheduleList: Schedule[]
    portfolioScheduleList?: PortfolioSchedule[]
    portfolioInspectionCount?: number
    portfolioApartmentCount?: number
    scheduleDetail?: Schedule
    portfolioScheudleDetail?: Schedule
    scheduleBudgetItems?: ScheduleBudgetItem[]
    portfolioScheduleBudgetItems?: ScheduleBudgetItem[]
    areaStatusTagList?: AreaStatusTag[]
    portfolioBudget?: PortfolioBudget
    isLoading: {
        [GET_SCHEDULE_LIST_REQUEST]: boolean
        [GET_SCHEDULE_DETAIL_REQUEST]: boolean
        [CREATE_SCHEDULE_REQUEST]: boolean
        [UPDATE_SCHEDULE_REQUEST]: boolean
        [GET_SCHEDULE_SHEET_REQUEST]: boolean
        [UPLOAD_TURN_SHEET_REQUEST]: boolean
        [RESET_SCHEDULE_REQUEST]: boolean
        [GET_AREA_STATUS_TAG_LIST_REQUEST]: boolean
        [CREATE_AREA_STATUS_TAG_REQUEST]: boolean
        [EDIT_AREA_STATUS_TAG_REQUEST]: boolean
        [CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST]: boolean
        [DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST]: boolean
        [GET_PORTFOLIO_SCHEDULE_LIST_REQUEST]: boolean
        [GET_PORTFOLIO_BUDGET]: boolean
    }
}

export interface ServicePrice {
    service_id: number
    service_name: string
    budget: number
    forecasted: number
    variance: number
    invoiced: number
}

export interface ServicePriceMap {
    [service_id: number]: ServicePrice
}
