import React from 'react'

import { useTheme } from '@material-ui/core'

import { Container } from '../../components'
import { currencyFormatter } from '../../helpers'
import { useUser } from '../../hooks'
import {
    Schedule,
    SEE_VENDOR_FINANCES,
    WorkorderAnalyticDict,
} from '../../models'
import { hasPermission } from '../../models/Users/services'

interface Props {
    schedule?: Schedule
    analyticDict: WorkorderAnalyticDict
}

export const PortfolioScheduleAnalytics = (props: Props) => {
    const { schedule, analyticDict } = props

    const theme = useTheme()
    const { workspaceUser } = useUser()
    return (
        <Container
            style={{
                flex: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
            }}
        >
            {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        marginRight: theme.spacing(4),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Set Budget:{' '}
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(
                                schedule?.baseline_budget ?? 0,
                            )}
                        </span>
                    </span>

                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Forecasted:{' '}
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(
                                analyticDict.forecastedBudget,
                            )}
                        </span>
                    </span>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Invoiced Budget:{' '}
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(
                                analyticDict.invoicedBudget,
                            )}
                        </span>
                    </span>
                </Container>
            )}

            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(4),
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Total Units:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.unitCount}
                    </span>
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Total Work Orders:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.workorderCount}
                    </span>
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Not Started:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.notStarted}
                    </span>
                </span>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(4),
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    In Progress:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.IN_PROGRESS +
                            analyticDict.PAUSE +
                            analyticDict.GO_BACK}
                    </span>
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Complete:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.COMPLETE}
                    </span>
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Approved:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.APPROVED}
                    </span>
                </span>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(4),
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Invoiced:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.INVOICED}
                    </span>
                </span>

                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Behind Count:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.behindTrack}
                    </span>
                </span>

                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Ghost count:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.ghostCount}
                    </span>
                </span>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(4),
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Services Areas:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.serviceAreaCount}
                    </span>
                </span>

                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Ghost Service Areas:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.ghostServiceAreaCount}
                    </span>
                </span>

                <div style={{ flex: 1 }} />
            </Container>
        </Container>
    )
}
